





























import { defineComponent } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { MultipleSelectType } from 'src/types/enum';

import { appStoreMapper } from 'src/store/appstore';

library.add(faCheck, faTimes);

export default defineComponent({
  name: 'ListInputMultiplePopup',
  data: () => ({
    MultipleSelectType,
  }),
  computed: {
    ...appStoreMapper.mapState(['multipleSelect']),
  },
  methods: {
    onChange(e: InputEvent, index: number) {
      const target = e.target as HTMLInputElement;

      this.setMultipleSelectItem({
        index,
        checked: target.checked,
        radio: this.multipleSelect?.type === MultipleSelectType.radio,
      });
    },
    reset() {
      this.resetMultipleSelect();

      this.$nextTick(() => {
        this.$f7?.popup.close();
      });
    },
    ...appStoreMapper.mapMutations(['resetMultipleSelect', 'setMultipleSelectItem']),
  },
});
