import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import GetWalletState from './state';

export default class GetWalletGetters extends Getters<GetWalletState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): Nullable<IModuleSettings> {
    return this.app.state.appData.settings?.modules?.getwallet;
  }
}
