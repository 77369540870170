

























































import Vue, { PropType } from 'vue';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';
import { appStoreMapper } from '@/store/appstore';

import TheBarWrapper from '@/components/TheBarWrapper.vue';

export default Vue.extend({
  name: 'ThePopup',
  components: { TheBarWrapper },
  props: {
    show: Boolean as PropType<boolean>,
    bar: Boolean as PropType<boolean>,
    error: Boolean as PropType<boolean>,
    errorText: String as PropType<string>,
    images: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: false,
    },
    loading: Boolean as PropType<boolean>,
  },
  data: () => ({
    showPopup: false,
    scrollTop: 0,
    pageContentElement: null as Nullable<HTMLElement>,
  }),
  mounted() {
    if (this.show) {
      this.showPopup = true;
    }
  },
  computed: {
    showHandler(): boolean {
      return this.scrollTop <= 5;
    },
    ...appStoreMapper.mapGetters(['fullImagePath', 'deviceWidth']),
  },
  methods: {
    openPopup() {
      this.$f7.popup.open('.popup-universal');
    },
    onPopupClosed() {
      this.$emit('update:show', false);
    },
    onRetryClick() {
      this.$emit('reload');
    },
    getImageById(id: number): string {
      const path = getImageThumbnailPath(id, {
        size: this.deviceWidth,
      });
      return this.fullImagePath(path);
    },
  },
});
