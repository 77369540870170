














import Vue from 'vue';
import { defineComponent, PropType } from 'vue';

import { phone, required } from 'src/validators/validators';
import i18n from 'src/translate/lang';

import ListInput from './list-input.vue';

export default defineComponent({
  name: 'ListInputUsername',
  components: { ListInput },
  props: {
    required: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showError: Boolean as PropType<boolean>,
    readonly: Boolean as PropType<boolean>,
    value: {
      type: null,
    },
    label: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.username.label'),
    },
    placeholder: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.username.placeholder'),
    },
  },
  data: () => ({
    validationParams: {
      phone,
      $autoDirty: true,
    } as any,
    defaultMask: '+##############',
    russianMask: '+7 (###) ###-##-##',
    belorussianMask: '+375 ## ###-##-##',
    mask: '',
  }),
  mounted() {
    this.updateMask();
  },
  methods: {
    updateMask() {
      let mask = '';

      if (/^\+?7/.test(this.$props.value)) {
        mask = this.russianMask;
      } else if (/^\+?375/.test(this.$props.value)) {
        mask = this.belorussianMask;
      }

      if (!mask) {
        mask = this.defaultMask;
      }

      this.mask = mask;
    },
  },
  watch: {
    required: {
      handler(value: boolean) {
        if (value) {
          this.validationParams.required = required;
        } else {
          Vue.delete(this.validationParams, 'required');
        }
      },
      immediate: true,
    },
    '$props.value'() {
      this.updateMask();

      const inputComponent = this.$refs.input as any;

      if (inputComponent.field.startsWith('+8')) {
        const changedValue = '+7' + inputComponent.field.substring(2);
        inputComponent.$emit('input', changedValue);
      }
    },
  },
});
