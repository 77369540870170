
















import { defineComponent } from 'vue';

import bus from 'src/bus';
import { appStoreMapper } from 'src/store/appstore';

export default defineComponent({
  name: 'Panel',
  computed: {
    inTabbar(): boolean {
      return this.appData.settings?.tabbar.includes('panelmenu');
    },
    showMenu(): boolean {
      return (
        !this.appSettings.hideMenu &&
        (!this.miniapp || (this.miniapp && !this.appSettings.hideMenuMiniapp))
      );
    },
    useSwipe(): boolean {
      return this.showMenu;
    },
    ...appStoreMapper.mapGetters(['appSettings', 'miniapp']),
    ...appStoreMapper.mapState(['appData', 'swipePanel']),
  },
  methods: {
    onPanelClosed() {
      (this.$refs.view as any).f7View.router.back(
        (this.$refs.view as any).$f7router.history[0],
        {
          animate: false,
          force: true,
        },
      );

      this.setPanelOpened(false);

      bus.$emit('panelClosed');
    },
    onPanelOpen() {
      this.setPanelOpened(true);
    },
    ...appStoreMapper.mapMutations(['setPanelOpened']),
  },
  watch: {
    swipePanel() {
      const panel: any = this.$refs.panel;

      if (this.swipePanel) {
        panel?.f7Panel?.enableSwipe();
      } else {
        panel?.f7Panel?.disableSwipe();
      }
    },
  },
});
