
import { defineComponent, h } from 'vue';
import { f7 } from 'framework7-vue';

import { getModule } from 'src/misc';
import { useAppStore } from '@/store/appstore';
import i18n from '@/translate/lang';

export default defineComponent({
  name: 'PanelmenuTabbarItem',
  render() {
    const appStore = useAppStore();

    const m = getModule('panelmenu');
    const tabbarTitle =
      appStore.state.appData.settings.modules.panelmenu?.tabbarTitle ||
      i18n.t(`modules.panelmenu.tabbarTitle`);

    return h('f7-link', {
      key: 'panelmenu',
      class: {
        ['tab-link-' + m.tabName]: true,
      },
      props: {
        href: '#',
        icon: m.tabbarIcon,
        text: tabbarTitle,
        'tab-link': true,
      },
      on: {
        click: () => {
          f7.panel.open('left');
        },
      },
    });
  },
});
