import { createComposable, createMapper, Module } from 'vuex-smart-module';

import GiftsActions from './actions';
import GiftsGetters from './getters';
import GiftsMutations from './mutations';
import GiftsState from './state';

const module = new Module({
  actions: GiftsActions,
  getters: GiftsGetters,
  mutations: GiftsMutations,
  namespaced: true,
  state: GiftsState,
});

export default module;

export const giftsStoreMapper = createMapper(module);
export const useGiftsStore = createComposable(module);
