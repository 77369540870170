








































































































































































import Vue, { PropType } from 'vue';
import { f7 } from 'framework7-vue';

import { Bonus, Operation, OperationId } from '../types';

import * as API from '../api';
import { showErrorDialog } from '@/misc';
import { timeZone, formatNumber, getLocalizedPrice } from '@/utils';
import { appStoreMapper } from '@/store/appstore';

import BonusIcon from '@/components/bonus-icon.vue';

export default Vue.extend({
  name: 'HistoryBody',
  components: {
    BonusIcon,
  },
  props: {
    id: String as PropType<string>,
  },
  data: () => ({
    showPreloader: false,
    operation: null as Nullable<Operation>,
  }),
  mounted() {
    if (!this.feedbackWidgetComponentExists) {
      console.warn('feedback-widget component does not exists');
    }

    this.getOperation(this.operationId);
  },
  computed: {
    feedbackWidgetComponentExists(): boolean {
      return !!this.$options.components?.['feedback-widget'];
    },
    showFeedbackWidget(): boolean {
      return this.feedbackWidgetComponentExists && !!this.operation?.feedback;
    },
    operationId(): OperationId {
      return Number(this.id) as OperationId;
    },
    totalPrice(): number {
      return this.operation!.receipt.paidByCash + this.operation!.receipt.paidByBonus;
    },
    bonusesIn(): Bonus[] {
      const bonuses: Bonus[] = [];
      this.operation!.bonuses.forEach((bonus) => {
        if (bonus.type === 'in') {
          bonuses.push(bonus);
        }
      });
      return bonuses;
    },
    bonusesOut(): Bonus[] {
      const bonuses: Bonus[] = [];
      this.operation!.bonuses.forEach((bonus) => {
        if (bonus.type === 'out') {
          bonuses.push(bonus);
        }
      });
      return bonuses;
    },
    ...appStoreMapper.mapGetters(['timeZoneOptions', 'decimalOptions', 'currency']),
    ...appStoreMapper.mapState(['locale']),
  },
  methods: {
    formatNumber,
    async getOperation(id: OperationId) {
      this.showPreloader = true;

      try {
        this.operation = await API.getOperation(id);
        f7.navbar.size(f7.views.current.$el[0].querySelector('.navbar') as HTMLElement);
      } catch (error) {
        showErrorDialog({ error });
      } finally {
        this.showPreloader = false;
      }
    },
    getFormattedDate(date: DateISO, full?: boolean): string {
      if (!isNaN(new Date(date).getTime())) {
        return timeZone(date, {
          locale: this.locale,
          type: full ? 'datetimeShortClear' : undefined,
          timezone: this.timeZoneOptions,
        });
      }

      return '';
    },
    getFormattedPrice(value: number) {
      return getLocalizedPrice(value, this.currency);
    },
  },
});
