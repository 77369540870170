import { IModule } from '@/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './actions.conf.json';

import store from 'src/store';
import ActionsStore from './store';

import Actions from './components/actions.vue';
import ActionsMenuItem from './components/actions-menu-item.vue';
import ActionsTabbarItem from './components/actions-tabbar-item.vue';
import ActionsPush from './components/actions-push.vue';
import ActionPopup from './components/action-popup.vue';

library.add(faTimes);

export function init() {
  Vue.component('actions', Actions);
  Vue.component('actions-menu-item', ActionsMenuItem);
  Vue.component('actions-tabbar-item', ActionsTabbarItem);
  Vue.component('actions-push', ActionsPush);
  Vue.component('action-uni-popup', ActionPopup);

  const routes = [
    {
      component: Vue.component('actions'),
      path: config.url,
    },
  ];

  registerModule(store, ['actions'], 'actions/', ActionsStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
