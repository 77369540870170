import { formatDate } from '@/utils';
import { FormatDateOptions } from '@/utils/formatDate';

type TimeZoneOptions = FormatDateOptions & {
  timezone?: ITimeZoneOptions;
};

function _timeZone(date: string | Date, options?: TimeZoneOptions): string {
  if (options?.timezone && options.timezone.useClientTimeZone == null) {
    options.timezone.useClientTimeZone = false;
  }

  if (typeof date === 'object') {
    date = formatDate(date, { type: 'ISO' });
  }

  const [timeWithoutTimezone, timezone] = date.split('+');
  const diffSeconds =
    options?.timezone?.sourceTimeZone && timezone
      ? parseInt(timezone.split(':')[0]) * 60 * 60
      : 10800;

  if (options?.timezone) {
    if (!options.timezone.useClientTimeZone) {
      const dateWithDefaultTimezone = new Date(timeWithoutTimezone);
      dateWithDefaultTimezone.setSeconds(
        options.timezone.displayTimeZone -
          options.timezone.sourceTimeZone +
          (options.timezone.sourceTimeZone - diffSeconds),
      );
      date = formatDate(dateWithDefaultTimezone, { type: 'ISO' });
    }
  } else {
    date = timeWithoutTimezone;
  }

  return formatDate(new Date(date), options);
}

export const timeZone = _timeZone;
