












import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import { formatNumber } from 'src/misc';

import { appStoreMapper } from 'src/store/appstore';
import { bonusStoreMapper } from '../store';
import bus from '@/bus';

import BonusIcon from 'src/components/bonus-icon.vue';

export default defineComponent({
  components: { BonusIcon },
  destroyed() {
    bus.$off('appDataDidLoad', this.resetSpentBonus);
    bus.$off('bonus:spent:add', this.addSpentBonus);
    bus.$off('bonus:spent:get', this.getSpentBonus);
  },
  mounted() {
    bus.$on('appDataDidLoad', this.resetSpentBonus);
    bus.$on('bonus:spent:add', this.addSpentBonus);
    bus.$on('bonus:spent:get', this.getSpentBonus);
  },
  computed: {
    bonus(): number {
      return (this.user?.bonus || 0) - this.spentBonus;
    },
    showBonus(): boolean {
      return !this.miniappHideElements;
    },
    formattedBonus(): string {
      return formatNumber(
        this.bonus,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'miniappHideElements', 'user']),
    ...appStoreMapper.mapState(['appData']),
    ...bonusStoreMapper.mapGetters(['moduleEnabled']),
    ...bonusStoreMapper.mapState(['spentBonus']),
  },
  methods: {
    goToHistory() {
      f7.views.current.router.navigate({ path: '/history/' });
    },
    resetSpentBonus() {
      this.setSpentBonus(0);
    },
    addSpentBonus(value: number) {
      this.setSpentBonus(this.spentBonus + value);
    },
    getSpentBonus() {
      bus.$emit('bonus:spent:set', this.spentBonus);
    },
    ...bonusStoreMapper.mapMutations(['setSpentBonus']),
  },
});
