
import { VNode } from 'vue';
import { defineComponent, useCssModule, h, ref, onMounted } from 'vue';

import config from '@/app.config.js';
import bus from '@/bus';
import { slotSeeker } from '@/utils';
import { useAppStore } from '@/store/appstore';

export default defineComponent({
  name: 'TabbarSlot',
  setup() {
    const styles = useCssModule();

    const appStore = useAppStore();

    const noTabbar = ref<boolean>(false);

    onMounted(() => {
      bus.$on('tabbar:show', () => (noTabbar.value = false));
      bus.$on('tabbar:hide', () => (noTabbar.value = true));
    });

    function getSlots() {
      let components: VNode[] = [];

      if (config.type === 'client') {
        const slots = appStore.state.appData.settings?.slots;

        if (slots) {
          slots?.['app']?.['tabbar-slot']?.forEach((component: string) =>
            components.push(h(component)),
          );
        }
      } else {
        components = slotSeeker('tabbar-slot');
      }

      return components;
    }

    return () =>
      h('div', { class: [styles.tabbarBefore, { [styles.noTabbar]: noTabbar.value }] }, [
        getSlots(),
      ]);
  },
});
