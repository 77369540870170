











































import { SpecialOfferListType } from '../types';

import Vue from 'vue';

import { appStoreMapper } from 'src/store/appstore';
import { specialOfferStoreMapper } from '../store';

import SpecialOffersListEntryItem from './SpecialOffersListEntryItem.vue';
import SpecialOffersListEntrySlideItem from './SpecialOffersListEntrySlideItem.vue';

export default Vue.extend({
  name: 'SpecialOfferHomeSlot',
  components: { SpecialOffersListEntryItem, SpecialOffersListEntrySlideItem },
  data() {
    return {
      listTypes: SpecialOfferListType,
      offersFrozen: [],
      swiperParams: {
        slidesPerView: 'auto',
        spaceBetween: 17,
      },
      updateCount: 0,
    };
  },
  mounted() {
    this.offersFrozen = JSON.parse(JSON.stringify(this.offers));

    setTimeout(() => {
      this.updateSwiper();
    }, 100);
  },
  computed: {
    ...appStoreMapper.mapState(['appDataLoading', 'currentTab', 'domain', 'appData']),
    ...specialOfferStoreMapper.mapGetters(['offers', 'settings']),
  },
  methods: {
    updateSwiper() {
      this.$nextTick(() => {
        this.updateCount++;
        (this.$refs.swiper as any)?.swiper?.update();
      });
    },
  },
  watch: {
    domain() {
      // kostyl swiper re-initializer
      const appDataLoadingWatcher = this.$watch(
        () => this.appDataLoading,
        () => {
          if (!this.appDataLoading) {
            appDataLoadingWatcher();

            this.offersFrozen = [];

            setTimeout(() => {
              this.offersFrozen = JSON.parse(JSON.stringify(this.offers));
            });
          }
        },
      );
    },
    'offersFrozen.length'() {
      this.updateSwiper();
    },
    offers: {
      handler() {
        this.offersFrozen = JSON.parse(JSON.stringify(this.offers));
      },
      deep: true,
    },
    appData() {
      this.$nextTick(() => {
        this.updateSwiper();
      });
    },
    currentTab() {
      if (this.currentTab === 'home') {
        this.$nextTick(() => {
          this.updateSwiper();
        });
      }
    }
  },
});
