



























































import Vue from 'vue';
import { ref, onMounted, watch } from 'vue';
import { f7 } from 'framework7-vue';

import { mixin } from '../composition';
import bus from '@/bus';
import logoTr from '@/assets/images/logo_fullscreen_tr.gif';
import logoExtraTr from '@/assets/images/logo_fullscreen_extra_tr.gif';
import { useAppStore } from '@/store/appstore';
import { useRelatedStore } from '@/modules/related/dist/store';

import CatalogItem from '@/components/CatalogItem.vue';

export default Vue.extend({
  name: 'RelatedAppRoot',
  components: {
    CatalogItem,
  },
  setup() {
    const appStore = useAppStore();
    const relatedStore = useRelatedStore();

    const popupVisible = ref<boolean>(false);
    const selectedDomain = ref<relatedDomain>(null as unknown as relatedDomain);

    const pageRef = ref<Vue>(null as unknown as Vue);
    const redrawCt = ref<number>(1);

    const { selectDomain } = mixin();

    function onClick() {
      const onFinal = () => {
        appStore.commit('setDomainFirstSelect', true);
        popupVisible.value = false;
      };

      selectDomain(selectedDomain.value, onFinal);
    }

    function onRelatedClicked(domain: relatedDomain) {
      const onFinal = () => {
        appStore.commit('setDomainFirstSelect', true);
        popupVisible.value = false;
      };

      selectedDomain.value = domain;
      selectDomain(selectedDomain.value, onFinal);
    }

    function onSelect(domain: relatedDomain) {
      selectedDomain.value = domain;
    }

    function lazyLoadInit() {
      const el = pageRef.value?.$el as HTMLElement;

      if (el) {
        redrawCt.value++;

        setTimeout(() => {
          f7.lazy.create(el);
        }, 100);
      }
    }

    onMounted(() => {
      popupVisible.value =
        relatedStore.getters.list.length > 1 && (!appStore.state.domainFirstSelect || !!relatedStore.getters.settings.showSelection);

      bus.$on('relatedPopupShow', () => {
        selectedDomain.value = appStore.state.domain;
        popupVisible.value = true;
      });

      bus.$on(
        'selectDomain',
        ({
          domain,
          callback,
          confirm,
        }: {
          domain: string;
          callback: () => void;
          confirm?: boolean;
        }) => {
          selectDomain(domain, callback, confirm);
        },
      );

      // Скрываем выбор региона если пришел пуш с конкретным доменом
      bus.$on('push-with-domain', () => {
        popupVisible.value = false;
      });

      lazyLoadInit();

      watch(
        () => appStore.state.appData,
        () => {
          lazyLoadInit();
        },
      );
    });

    watch(
      () => appStore.state.appDataDidLoad,
      (value: boolean) => {
        if (value) lazyLoadInit();
      },
    );

    return {
      list: relatedStore.getters.list,
      onClick,
      onRelatedClicked,
      onSelect,
      popupVisible,
      selectedDomain,
      selectDomain,
      settings: relatedStore.getters.settings,
      pageRef,
      redrawCt,
      extraLogo: appStore.getters.extraLogo,
      logoTr,
      logoExtraTr,
    };
  },
});
