




































































































import { defineComponent, PropType } from 'vue';
import { IMaskComponent } from 'vue-imask';
import { f7 } from 'framework7-vue';

import { IUser } from '@/types';
import { AuthType } from '@/types/enum';

import i18n from '@/translate/lang';
import config from '@/modules/auth/dist/auth.conf.json';
import bus from 'src/bus';
import { showErrorDialog } from 'src/misc';
import { authStoreMapper } from '../store';
import { getCode } from '../api';

export default defineComponent({
  components: { IMaskComponent },
  props: {
    phone: {
      type: String as PropType<string>,
      required: true,
    },
    fromRegistration: Boolean as PropType<boolean>,
    openProfile: Boolean as PropType<boolean>,
  },
  data: () => ({
    pincode: '',
    error: '',
    remindPasswordDelay: 45,
    remindPasswordTimer: 0,
    remindPasswordTimerInterval: null as any,
    nextAttemptSMSActivated: false,
    nextAttemptSMS: false,
    success: false,
    pageShown: false,
  }),
  mounted() {
    if (this.settings.smsTimeout != null) {
      this.remindPasswordDelay = this.settings.smsTimeout;
    }

    if (this.resetPasswordDelay) {
      const delay =
        this.remindPasswordDelay -
        Math.floor((+new Date() - +this.resetPasswordDelay) / 1000);
      this.remindPasswordTimer = delay < this.remindPasswordDelay ? delay : 0;
    }
  },
  computed: {
    pincodeLength(): number {
      return this.settings.pincodeLength || 5;
    },
    pincodeMask() {
      let mask = '';

      for (let i = 0; i < this.pincodeLength; i++) {
        mask += '0';
      }

      return mask;
    },
    pincodeFormatted(): string {
      return this.pincode.replace(/\D/g, '');
    },
    authTypeSMS(): boolean {
      return this.settings.type === AuthType.sms || this.nextAttemptSMSActivated;
    },
    ...authStoreMapper.mapGetters(['settings', 'user']),
    ...authStoreMapper.mapState(['resetPasswordDelay', 'afterLoginCallback']),
  },
  methods: {
    back() {
      if (this.fromRegistration) {
        f7.views.current.router.back(f7.views.current.router.history[0], {
          force: true,
        });
      } else {
        f7.views.current.router.back();
      }
    },
    focusInput() {
      const input = (this.$refs.pincode as unknown as Vue).$el as HTMLInputElement;

      input.focus();

      setTimeout(() => {
        input.setSelectionRange(0, 0);
      }, 100);
    },
    getCode(ask?: boolean) {
      f7.preloader.show();

      (this.$refs.pincode as any).$el.blur();

      if (ask) {
        this.nextAttemptSMSActivated = this.nextAttemptSMS;

        f7.dialog.alert(
          this.authTypeSMS
            ? i18n.t('modules.auth.signin.smsalert').toString()
            : i18n.t('modules.auth.signin.callalert').toString(),
          i18n.t('modules.auth.signin.smsheader').toString(),
          () => {
            this.focusInput();
          },
        );
      }

      getCode({ username: this.phone })
        .then(({ nextAttemptSMS }) => {
          this.nextAttemptSMS = !!nextAttemptSMS;
          this.setResetPasswordDelay(new Date());
        })
        .catch((error) => {
          showErrorDialog.call(this, { error });
        })
        .finally(() => {
          this.pageShown = true;
          f7.preloader.hide();
        });
    },
    signIn() {
      if (this.pincodeFormatted.length < this.pincodeLength) {
        this.error = this.authTypeSMS
          ? i18n.t('modules.auth.signin.badpincode').toString()
          : i18n.t('modules.auth.signin.badcallcode').toString();

        return;
      }

      this.$refs.pincode &&
        ((this.$refs.pincode as unknown as Vue).$el as HTMLInputElement).blur();

      f7.preloader.show();

      this.auth({ username: this.phone, password: this.pincode })
        .then((user) => {
          this.pincode = '';

          this.success = true;

          if (document.body.querySelector('.auth-popup')) {
            f7.popup.close('.auth-popup');
          } else {
            setTimeout(() => {
              if (this.openProfile) {
                if (this.settings.tab) {
                  f7.views.current.router.back(f7.views.current.router.history[0], {
                    force: true,
                    animate: false,
                  });

                  f7.tab.show(`#${config.tabName}`);
                } else {
                  f7.views.current.router.navigate('/profile/');
                }
              } else {
                f7.views.current.router.back(f7.views.current.router.history[0], {
                  force: true,
                });
              }

              if (this.fromRegistration) {
                bus.$emit('getMessages', { interval: 1500, tries: 15 });
              }
            }, this.settings.successTimeout ?? 1500);
          }

          bus.$emit('authAfterLoginSuccess');

          if (this.afterLoginCallback) {
            this.afterLoginCallback(user as IUser);
            this.setAfterRegisterCallback(null);
            this.setAfterLoginCallback(null);
          }
        })
        .catch((error) => {
          showErrorDialog.call(this, {
            error,
            callbacks: {
              onClose: () => {
                this.focusInput();
              },
            },
          });

          this.pincode = '';

          bus.$emit('authAfterLoginFailed');
        })
        .finally(() => {
          f7.preloader.hide();

          bus.$emit('authAfterLoginFinally');
        });
    },
    pageAfterIn() {
      this.$nextTick(() => {
        this.focusInput();
      });

      if (this.remindPasswordTimer <= 0) {
        this.getCode();
      } else {
        this.pageShown = true;
      }
    },
    ...authStoreMapper.mapActions(['auth', 'getSmsCode']),
    ...authStoreMapper.mapMutations([
      'setResetPasswordDelay',
      'setAfterLoginCallback',
      'setAfterRegisterCallback',
    ]),
  },
  watch: {
    pincode() {
      if (this.pincode) {
        this.error = '';
      }

      if (this.pincodeFormatted.length >= this.pincodeLength) {
        this.signIn();
      }
    },
    resetPasswordDelay() {
      this.remindPasswordTimer = this.remindPasswordDelay;
    },
    remindPasswordTimer() {
      if (!this.remindPasswordTimerInterval && this.remindPasswordTimer > 0) {
        this.remindPasswordTimerInterval = setInterval(() => {
          if (this.remindPasswordTimer <= 0) {
            this.remindPasswordTimer = 0;
            clearInterval(this.remindPasswordTimerInterval);
            this.remindPasswordTimerInterval = null;
          } else {
            this.remindPasswordTimer--;
          }
        }, 1000);
      }
    },
  },
});
