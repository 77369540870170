










import Vue from 'vue';

import { refStoreMapper } from '../store';

import ReferralSlot from './referral-slot.vue';

export default Vue.extend({
  name: 'Referral',
  components: { ReferralSlot },
  computed: {
    ...refStoreMapper.mapGetters(['referral']),
  },
});
