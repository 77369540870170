
































































import { ReceiveType } from '../types';

import Vue from 'vue';

import { catalogStoreMapper } from '../store';
import bus from '@/bus';

export default Vue.extend({
  name: 'DeliverySelect',
  data: () => ({
    ReceiveType,
  }),
  computed: {
    ...catalogStoreMapper.mapState([
      'receive',
      'pickupPoint',
      'deliveryAddress',
      'basket',
    ]),
    ...catalogStoreMapper.mapGetters([
      'singleReceiveTypeAvailable',
      'settings',
      'receiveAvailable',
    ]),
  },
  methods: {
    onPickupClick() {
      this.setReceive(ReceiveType.pickup);

      this.updateBasketAfterChange();
    },
    onDeliveryClick() {
      this.setReceive(ReceiveType.delivery);

      this.updateBasketAfterChange();
    },
    onAddressClick() {
      bus.$emit('delivery:open');
      bus.$once('delivery:done', () => {
        this.updateBasketAfterChange();
      });
    },
    updateBasketAfterChange() {
      if (
        (this.receive === ReceiveType.delivery && this.deliveryAddress) ||
        (this.receive === ReceiveType.pickup && this.pickupPoint)
      ) {
        this.$f7.preloader.show();

        this.updateBasket().finally(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    ...catalogStoreMapper.mapActions(['updateBasket']),
    ...catalogStoreMapper.mapMutations(['setReceive', 'resetProductsByCategoryId']),
  },
  watch: {
    pickupPoint() {
      this.resetProductsByCategoryId();
    },
    deliveryAddress() {
      this.resetProductsByCategoryId();
    },
    receive() {
      this.resetProductsByCategoryId();
    },
  },
});
