import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';

import { IBranch } from '@/types';
import {
  AddressDetails,
  CatalogSearch,
  DeliveryAddress,
  IBasketEntry,
  ICatalogHistoryItem,
  ICategory,
  IProduct,
  ReceiveType,
} from '../types';

import {
  LOCALSTORAGE_ADDRESS,
  LOCALSTORAGE_ADDRESS_BRANCH,
  LOCALSTORAGE_ADDRESS_DETAILS,
  LOCALSTORAGE_BASKET,
  LOCALSTORAGE_ORDER_STATUS,
  LOCALSTORAGE_PICKUP,
  LOCALSTORAGE_RECEIVE,
} from '../const';

import Storage from '@/storage';

import CatalogState from './state';

export default class CatalogMutations extends Mutations<CatalogState> {
  addProduct({ amount, product }: { amount: number; product: IProduct }) {
    this.state.basket.push({
      amount,
      product,
    });

    Storage.setSync(LOCALSTORAGE_BASKET, this.state.basket);
  }

  removeEntry(product: IProduct) {
    const index = this.state.basket.findIndex((entry) => entry.product.id === product.id);

    if (index >= 0) {
      Vue.delete(this.state.basket, index);
    }

    Storage.setSync(LOCALSTORAGE_BASKET, this.state.basket);
  }

  setBasket(basket: IBasketEntry[]) {
    this.state.basket = basket;

    Storage.setSync(LOCALSTORAGE_BASKET, this.state.basket);
  }

  updateProduct({ amount, product }: { amount: number; product: IProduct }) {
    const index = this.state.basket.findIndex((entry) => entry.product.id == product.id);

    if (index >= 0) {
      const newAmount = this.state.basket[index].amount + amount;

      if (newAmount > 0) {
        this.state.basket[index].amount = newAmount;
        Vue.set(this.state.basket[index], 'product', product);
      } else {
        Vue.delete(this.state.basket, index);
      }

      Storage.setSync(LOCALSTORAGE_BASKET, this.state.basket);
    }
  }

  setPaidByBonus(products: IProduct[]) {
    this.state.paidByBonus = products;
  }

  setReceive(type: Nullable<ReceiveType>) {
    this.state.receive = type;

    Storage.setSync(LOCALSTORAGE_RECEIVE, this.state.receive);
  }

  setPickupPoint({
    branch,
    domain,
  }: {
    branch: Nullable<IBranch>;
    domain: relatedDomain;
  }) {
    this.state.pickupPoint = branch;

    Storage.setSync(`${LOCALSTORAGE_PICKUP}-${domain}`, this.state.pickupPoint);
  }

  setShowCatalogHistoryBar(value: boolean) {
    this.state.showCatalogHistoryBar = value;
  }

  setOrderStatus(item: Nullable<ICatalogHistoryItem>) {
    this.state.orderStatus = item ? JSON.parse(JSON.stringify(item)) : item;

    Storage.setSync(LOCALSTORAGE_ORDER_STATUS, this.state.orderStatus);
  }

  setCategories(categories: ICategory[]) {
    this.state.categories = categories;
  }

  setProductsByCategoryId({
    categoryId,
    products,
    reset,
  }: {
    categoryId: number;
    products: IProduct[];
    reset?: boolean;
  }) {
    if (!this.state.productsByCategoryId[categoryId]) {
      Vue.set(this.state.productsByCategoryId, categoryId, []);
    }

    if (reset) {
      this.state.productsByCategoryId[categoryId] = products;
    } else {
      this.state.productsByCategoryId[categoryId] =
        this.state.productsByCategoryId[categoryId].concat(products);
    }
  }

  resetProductsByCategoryId() {
    this.state.productsByCategoryId = {};
  }

  setCategoriesScrollTop({ id, value }: { id: number; value: number }) {
    Vue.set(this.state.categoriesScrollTop, id, value);
  }

  setInBasket(value: boolean) {
    this.state.inBasket = value;
  }

  setInForm(value: boolean) {
    this.state.inForm = value;
  }

  setInBonus(value: boolean) {
    this.state.inBonus = value;
  }

  pushLazyLoadedImagesIds(id: number) {
    if (!this.state.lazyLoadedImagesIds.includes(id)) {
      this.state.lazyLoadedImagesIds.push(id);
    }
  }

  setDeliveryAddress({
    suggestion,
    details,
    branch,
    domain,
  }: {
    suggestion: Nullable<DeliveryAddress>;
    details: Nullable<AddressDetails>;
    branch: Nullable<number | string>;
    domain: relatedDomain;
  }) {
    this.state.deliveryAddress = suggestion;
    this.state.deliveryAddressDetails = details;
    this.state.deliveryAddressBranch = branch;

    Storage.setSync(`${LOCALSTORAGE_ADDRESS}-${domain}`, suggestion);
    Storage.setSync(`${LOCALSTORAGE_ADDRESS_DETAILS}-${domain}`, details);
    Storage.setSync(`${LOCALSTORAGE_ADDRESS_BRANCH}-${domain}`, branch);
  }

  removeNonActiveProductsFromBasket() {
    this.state.basket = this.state.basket.filter(
      (entry) => entry.product.active !== false,
    );
  }

  setSelectedHistoryItem(item: ICatalogHistoryItem) {
    this.state.selectedHistoryItem = item;
  }

  clearSearchCache(level: number) {
    this.state.searchCache.splice(level, 1);
  }

  updateSearchCache({
    cache,
    scrollTop,
    query,
    level,
  }: {
    cache: CatalogSearch;
    scrollTop: number;
    query: string;
    level: number;
  }) {
    if (!this.state.searchCache[level]) {
      this.state.searchCache.push({ cache, query, scrollTop });
    } else {
      this.state.searchCache[level] = { cache, query, scrollTop };
    }
  }

  setPayByBonusOrderAmount(value: number) {
    this.state.payByBonusOrderAmount = value;
  }
}
