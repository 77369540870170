
























































































































































import Vue from 'vue';

import { IBranch } from '@/types';
import { ICatalogHistoryItem, OrderStatus, ReceiveType } from '../types';

import config from 'src/app.config.js';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '@/modules/catalog/dist/store';
import { timeZone, getLocalizedPrice, formatNumber } from '@/utils';

import BonusIcon from 'src/components/bonus-icon.vue';

export default Vue.extend({
  name: 'HistoryBody',
  components: { BonusIcon },
  data: () => ({
    OrderStatus,
    ReceiveType,
  }),
  computed: {
    item(): ICatalogHistoryItem {
      return this.selectedHistoryItem!;
    },
    address(): Nullable<string> {
      if (this.item.receiveType === ReceiveType.pickup) {
        return this.pickupPoints.find(
          (branch) => branch.idBranch == this.item.idPickupBranch,
        )?.address;
      } else {
        return this.item.deliveryAddress;
      }
    },
    date(): string {
      if (Number.isNaN(new Date(this.item.orderDate).getDate())) {
        return '—';
      }

      return timeZone(this.item.orderDate, {
        locale: this.locale,
        type: 'datetimeLong',
        timezone: this.timeZoneOptions,
      });
    },
    orderStatusLabel(): string {
      return this.$t('modules.catalog.history.status.' + this.item.status).toString();
    },
    repeatBranch(): Nullable<IBranch> {
      const branchId = this.item.idPickupBranch || this.item.delivery?.toBranch;
      return this.pickupPoints.find((branch) => branch.idBranch === branchId);
    },
    ...appStoreMapper.mapGetters([
      'pickupPoints',
      'clientName',
      'decimalOptions',
      'timeZoneOptions',
    ]),
    ...appStoreMapper.mapState(['locale']),
    ...appStoreMapper.mapGetters(['currency']),
    ...catalogStoreMapper.mapGetters(['settings']),
    ...catalogStoreMapper.mapState(['basket', 'selectedHistoryItem']),
  },
  methods: {
    getProductImage(id: string): string {
      return `${config.api.protocol}://${this.clientName}.${config.api.host}/rest/image/${id}/150/150/crop.jpg`;
    },
    getFormattedCurrency(value: number): string {
      return value
        ? getLocalizedPrice(
            value,
            this.currency,
            this.decimalOptions.decimalRoundingRule,
            this.decimalOptions.decimal,
          )
        : '';
    },
    getFormattedNumber(value: number): string {
      return value
        ? formatNumber(
            value,
            this.decimalOptions.decimalRoundingRule,
            this.decimalOptions.decimal,
          )
        : '';
    },
    onRepeatClick() {
      const branch = this.pickupPoints.find(
        (branch) => branch.idBranch === this.repeatBranch?.idBranch,
      );
      if (!branch) {
        this.$f7.dialog.alert(
          'К сожалению, мы не можем повторить этот заказ, так как пункт самовывоза, использованный в заказе, в настоящее время не обслуживает клиентов.',
          'Повтор заказа',
        );

        return;
      }

      if (this.basket.length) {
        const dialog = this.$f7?.dialog.confirm(
          'Чтобы продолжить необходимо очистить корзину',
          'Товары в корзине',
          () => {
            this.clearBasket();
            this.repeatOrder();
          },
        );

        dialog.open();
      } else {
        this.repeatOrder();
      }
    },
    repeatOrder() {
      if (this.item.receiveType === ReceiveType.delivery) {
        this.setReceive(ReceiveType.delivery);
        this.setDeliveryAddress({
          suggestion: {
            id: 1,
            type: 'address',
            value: this.item.delivery?.address || '',
            data: {
              lat: this.item.delivery?.lat || '',
              lng: this.item.delivery?.lng || '',
            },
          },
          details: {
            room: this.item.delivery?.room || '',
            domofon: this.item.delivery?.domofon || '',
            entrance: this.item.delivery?.entrance || '',
            floor: this.item.delivery?.floor || '',
            comment: this.item.comment || '',
          },
          branch: this.repeatBranch!.idBranch,
        });
      } else {
        this.setReceive(ReceiveType.pickup);
        this.setPickupPoint(this.repeatBranch);
      }

      this.item.products?.forEach((product) => {
        this.addProduct({
          amount: product.qty,
          product: {
            id: product.id,
            name: product.name,
            price: product.cost,
            images: product.images as number[],
            description: '',
            category_id: 0,
            active: true,
            maxPayBonus: 100,
          },
        });
      });

      this.$f7?.views.current.router.navigate('/catalog/basket');
    },
    ...catalogStoreMapper.mapActions([
      'clearBasket',
      'setPickupPoint',
      'setDeliveryAddress',
    ]),
    ...catalogStoreMapper.mapMutations(['addProduct', 'setReceive']),
  },
});
