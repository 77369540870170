import { render, staticRenderFns } from "./NotificationsDaemon.vue?vue&type=template&id=76a5ae83&"
import script from "./NotificationsDaemon.vue?vue&type=script&lang=ts&"
export * from "./NotificationsDaemon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.8_wzvh6gi4tchptuexh7cjhim74e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports