import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarDay as faCalendarDayLight } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './booking.conf.json';

import store from 'src/store';
import MapsStore from './store';

import Booking from './components/booking.vue';
import BookingHomeSlotButton from './components/booking-home-slot-button.vue';

library.add(faCalendarDay, faCalendarDayLight);

export function init() {
  Vue.component('booking-home-slot-button', BookingHomeSlotButton);

  const routes = [
    {
      component: Booking,
      path: config.url,
    },
  ];

  registerModule(store, ['booking'], 'booking/', MapsStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
