import Vue from 'vue';
import { Ref, onMounted, onUnmounted, ref, nextTick } from 'vue';
import { f7, f7ready, theme } from 'framework7-vue';

import config from 'src/app.config.js';
import bus from 'src/bus';
import * as misc from 'src/misc';
import Storage from 'src/storage';
import { sendStatQueue } from '@/misc';
import i18n from '@/translate/lang';
import { useAppStore } from '@/store/appstore';

const objections: { [key: string]: Nullable<() => void> } = {};

export const backButton = () => {
  const appStore = useAppStore();

  const showSysMsg = ref(false);

  let backButtonTimeout: any = null;
  let backButtonCount = 0;
  let backButtonCancelRequestCount = 0;

  onMounted(() => {
    window.backButton = backButton;

    document.addEventListener('deviceready', () => {
      document.addEventListener('backbutton', backButton, false);
    });

    document.addEventListener(
      'keyup',
      (e) => {
        if (e.key === 'Escape') {
          backButton();
        }
      },
      false,
    );
  });

  onUnmounted(() => {
    document.removeEventListener('backbutton', backButton);
  });

  function backButton() {
    if (document.querySelector('html.with-modal-preloader')) {
      if (window.cancelRequestHandle) {
        if (!backButtonCancelRequestCount) {
          backButtonCancelRequestCount++;
        } else {
          window.cancelRequestHandle.cancel(
            i18n.t('global.misc.cancelRequest').toString(),
          );
          backButtonCancelRequestCount = 0;
        }
      }

      return false;
    }

    (bus as unknown as Vue).$emit('backButton');

    if (
      (bus as any)._events &&
      (bus as any)._events.backButtonOverride &&
      (bus as any)._events.backButtonOverride.length
    ) {
      (bus as unknown as Vue).$emit('backButtonOverride');
    } else {
      if (!f7.dialog.close()) {
        const panelMenuModule = misc.getModule('panelmenu');
        const defaultMainModule = misc.getModule(appStore.getters.defaultTab);

        if (document.documentElement.classList.contains('with-modal-sheet')) {
          f7.sheet.close();
        } else if (document.documentElement.classList.contains('with-modal-popup')) {
          f7.popup.close();
        } else if ((f7.views.current.router.history.length ?? 1) > 1) {
          f7.views.current.router.back();
          backButtonCount = 0;
        } else if (
          panelMenuModule &&
          (f7.views.current.router as any).url === panelMenuModule.url
        ) {
          f7.panel.close();
          backButtonCount = 0;
        } else if (
          appStore.state.currentTab &&
          appStore.state.currentTab !== defaultMainModule.name
        ) {
          f7.tab.show(`#${defaultMainModule.tabName}`);
        } else if (!backButtonCount) {
          backButtonCount++;
        } else if (backButtonTimeout) {
          const nav = navigator;
          if (nav.app && nav.app.exitApp) {
            nav.app.exitApp();
          }
        } else {
          showSysMsg.value = true;

          backButtonTimeout = setTimeout(() => {
            showSysMsg.value = false;
            backButtonTimeout = null;
            backButtonCount = 0;
          }, 3000);
        }
      }
    }
  }

  return { showSysMsg };
};

export const splashScreen = () => {
  const appStore = useAppStore();

  let splashHideInterval: any = null;
  let splashHideTimeout: any = null;

  const tabs: Ref<Vue> = ref(null) as unknown as Ref<Vue>;

  onMounted(() => {
    bus.$on('appDataDidFail', onAppDataDidFail);
    bus.$on('appDataDidLoad', onAppDataDidLoad);
    bus.$on('regDeviceDidFail', onRegDeviceDidFail);
  });

  onUnmounted(() => {
    bus.$off('appDataDidFail', onAppDataDidFail);
    bus.$off('appDataDidLoad', onAppDataDidLoad);
    bus.$off('regDeviceDidFail', onRegDeviceDidFail);
  });

  function hideSplash(): void {
    splashHideInterval = setInterval(() => {
      if (tabs.value.$el) {
        nextTick(() => {
          if (appStore.state.appDataDidLoad) {
            misc.hideSplash();
          } else {
            // Скрывать сплеш по таймауту в зависимости от того есть ли данные в кеше
            splashHideTimeout = setTimeout(
              onAppDataDidLoad,
              Object.keys(appStore.state.appData).length > 0
                ? config.app.miniapp
                  ? 0
                  : 2000
                : 10000,
            );
          }

          setTimeout(() => {
            document.documentElement.classList.add('animate');
          }, 3000);
        });

        clearInterval(splashHideInterval);
        splashHideInterval = undefined;
      }
    }, 100);
  }

  function onAppDataDidFail() {
    if (!navigator.onLine && !Object.keys(appStore.state.appData).length) {
      misc.hideSplash();
    }
  }

  function onAppDataDidLoad() {
    nextTick(() => {
      misc.hideSplash();
    });

    if (splashHideTimeout) {
      clearTimeout(splashHideTimeout);
      splashHideTimeout = null;
    }

    bus.$off('appDataDidLoad', onAppDataDidLoad);
  }

  function onRegDeviceDidFail() {
    if (!navigator.onLine && !Object.keys(appStore.state.appData).length) {
      misc.hideSplash();
    }
  }

  return {
    hideSplash,
    tabs,
  };
};

export const swipeWatcher = () => {
  const appStore = useAppStore();

  let swipeWatcher: any = null;

  onMounted(() => {
    f7ready(() => {
      nextTick(() => {
        swipeWatcher = setInterval(() => {
          let swipe = (f7.views.current?.history.length || 1) <= 1;

          // Maps Module Hack
          if (appStore.state.currentTab === 'maps') {
            swipe = false;
          }

          if (swipe !== appStore.state.swipePanel) {
            appStore.commit('setSwipePanel', swipe);
          }
        }, 500);
      });
    });
  });

  onUnmounted(() => {
    if (swipeWatcher) {
      clearInterval(swipeWatcher);
    }
  });
};

export const appDataUpdater = () => {
  const appStore = useAppStore();

  let pauseTime: Date = new Date();
  let offlineTime: Date = new Date();
  let offlineOnStart = !navigator.onLine;

  const updateIntervalTimeout = 10000;
  let updateInterval: any = null;

  onMounted(() => {
    document.addEventListener('pause', onDevicePause, false);
    document.addEventListener('resume', onDeviceResume, false);

    window.addEventListener('online', onDeviceOnline);
    window.addEventListener('offline', onDeviceOffline);

    freshCheck();

    updateInterval = setInterval(freshCheck, updateIntervalTimeout);
  });

  onUnmounted(() => {
    document.removeEventListener('pause', onDevicePause);
    document.removeEventListener('resume', onDeviceResume);

    window.removeEventListener('online', onDeviceOnline);
    window.removeEventListener('offline', onDeviceOffline);
  });

  function onDeviceOffline() {
    offlineTime = new Date();
  }

  function onDeviceOnline() {
    const now = new Date();
    const timeDiff = (now.getTime() - offlineTime.getTime()) / 1000;

    if (
      appStore.state.idDevice &&
      appStore.state.authToken &&
      (offlineOnStart || timeDiff > 5 * 60)
    ) {
      offlineOnStart = false;

      appStore.dispatch('getAppData', bus);

      sendStatQueue(appStore);
    }
  }

  function onDevicePause() {
    pauseTime = new Date();
  }

  function onDeviceResume() {
    setTimeout(() => {
      const now = new Date();
      const timeDiff = (now.getTime() - pauseTime.getTime()) / 1000;

      if (appStore.state.idDevice && appStore.state.authToken && timeDiff > 5 * 60) {
        appStore.dispatch('getAppData', bus).then(() => {
          clearInterval(updateInterval);
          updateInterval = setInterval(freshCheck, updateIntervalTimeout);
        });
      } else {
        clearInterval(updateInterval);
        updateInterval = setInterval(freshCheck, updateIntervalTimeout);
      }

      sendStatQueue(appStore);
    }, 0);
  }

  function freshCheck() {
    if (
      appStore.state.authToken &&
      !appStore.state.appDataDidFail &&
      !appStore.state.regDeviceDidFail
    ) {
      const timestamp: number = appStore.state.appData.timestamp || 0;
      const updateInterval: number =
        appStore.state.appData.settings.modules.app.updateInterval ?? 60 * 60 * 24;

      if (!timestamp || +new Date() - timestamp > updateInterval * 1000) {
        appStore.dispatch('getAppData', bus);
      }
    }
  }
};

export const statusbarAdjuster = () => {
  f7ready(() => {
    if (theme.md) {
      const cachedStatusbarHeight = parseInt(Storage.getSync('statusbar-height'), 10);

      if (cachedStatusbarHeight != null && !Number.isNaN(cachedStatusbarHeight)) {
        setStatusbarHeight(cachedStatusbarHeight);
      } else {
        const heightWithStatusbar = window.innerHeight;

        f7.statusbar.overlaysWebView(true);

        const screenHeightChangeWatcher = setInterval(() => {
          if (window.innerHeight - heightWithStatusbar > 0) {
            clearInterval(screenHeightChangeWatcher);
            clearTimeout(screenHeightChangeWatcherTimeout);

            setStatusbarHeight(window.innerHeight - heightWithStatusbar);
          }
        }, 100);

        const screenHeightChangeWatcherTimeout = setTimeout(() => {
          clearInterval(screenHeightChangeWatcher);
        }, 2000);
      }
    }
  });

  function setStatusbarHeight(statusbarHeight: number) {
    if (statusbarHeight && !Number.isNaN(statusbarHeight) && statusbarHeight > 0) {
      Storage.setSync('statusbar-height', statusbarHeight);

      document.documentElement.style.setProperty(
        '--f7-safe-area-top',
        `${statusbarHeight}px`,
      );
    }
  }
};

export const useBrightness = () => {
  const brightness = ref(0);
  let plugin: ICordovaPluginBrightness | undefined = undefined;

  document.addEventListener('deviceready', () => {
    plugin = window.cordova?.plugins?.brightness;
  });

  function brightnessTurnOn() {
    plugin?.getBrightness((value: number) => {
      brightness.value = value;

      plugin?.setBrightness(1);
      plugin?.setKeepScreenOn(true);
    });
  }

  function brightnessTurnOff() {
    plugin?.setBrightness(brightness.value);
    plugin?.setKeepScreenOn(false);
  }

  return {
    brightnessTurnOn,
    brightnessTurnOff,
  };
};

export const useObjections = () => {
  function addObjection(objectionId: string, callback: () => void) {
    objections[objectionId] = callback;
  }

  function removeObjection(objectionId: string) {
    delete objections[objectionId];
  }

  function checkObjection(objectionId: string): Promise<boolean> {
    return new Promise((resolve) => {
      const objection = objections[objectionId];
      if (objection) {
        bus.$once('objection', (result: boolean) => {
          resolve(result);
        });

        objection();
      } else {
        resolve(false);
      }
    });
  }

  return { addObjection, removeObjection, checkObjection };
};
