





























































import { defineComponent, PropType } from 'vue';
import { IMaskComponent } from 'vue-imask';
import { f7 } from 'framework7-vue';

import { IUser } from '@/types';

import i18n from '@/translate/lang';
import config from '../auth.conf.json';
import bus from 'src/bus';
import { showErrorDialog } from 'src/misc';
import { authStoreMapper } from '../store';

export default defineComponent({
  name: 'Password',
  components: { IMaskComponent },
  props: {
    phone: {
      type: String as PropType<string>,
      required: true,
    },
    openProfile: Boolean as PropType<boolean>,
  },
  data: () => ({
    password: '',
    error: '',
    remindPasswordDelay: 45,
    remindPasswordTimer: 0,
    remindPasswordTimerInterval: null as any,
    success: false,
  }),
  mounted() {
    if (this.settings.smsTimeout != null) {
      this.remindPasswordDelay = this.settings.smsTimeout;
    }

    if (this.resetPasswordDelay) {
      const delay =
        this.remindPasswordDelay -
        Math.floor((+new Date() - +this.resetPasswordDelay) / 1000);
      this.remindPasswordTimer = delay < this.remindPasswordDelay ? delay : 0;
    }
  },
  computed: {
    ...authStoreMapper.mapGetters(['settings', 'user']),
    ...authStoreMapper.mapState(['resetPasswordDelay', 'afterLoginCallback']),
  },
  methods: {
    signIn() {
      if (!this.password) {
        this.error = i18n.t('modules.auth.signin.badpassword') as string;

        return;
      }

      this.$refs.password &&
        (this.$refs.password as unknown as Vue).$el.querySelector('input')?.blur();

      f7.preloader.show();

      this.auth({ username: this.phone, password: this.password })
        .then((user) => {
          this.password = '';

          this.success = true;

          if (document.body.querySelector('.auth-popup')) {
            f7.popup.close('.auth-popup');
          } else {
            setTimeout(() => {
              if (this.openProfile) {
                if (this.settings.tab) {
                  f7.views.current.router.back(f7.views.current.router.history[0], {
                    force: true,
                    animate: false,
                  });

                  f7.tab.show(`#${config.tabName}`);
                } else {
                  f7.views.current.router.navigate('/profile/');
                }
              } else {
                f7.views.current.router.back(f7.views.current.router.history[0], {
                  force: true,
                });
              }
            }, this.settings.successTimeout ?? 1500);
          }

          bus.$emit('authAfterLoginSuccess');

          if (this.afterLoginCallback) {
            this.afterLoginCallback(user as IUser);
            this.setAfterRegisterCallback(null);
            this.setAfterLoginCallback(null);
          }
        })
        .catch((error) => {
          showErrorDialog.call(this, {
            error,
            callbacks: {
              onClose: () => {
                this.password = '';

                ((this.$refs.password as unknown as Vue).$el as HTMLInputElement)
                  .querySelector('input')
                  ?.focus();
              },
            },
          });

          bus.$emit('authAfterLoginFailed');
        })
        .finally(() => {
          f7.preloader.hide();

          bus.$emit('authAfterLoginFinally');
        });
    },
    pageAfterIn() {
      ((this.$refs.password as unknown as Vue)?.$el as HTMLInputElement)
        .querySelector('input')
        ?.focus();
    },
    resetPassword() {
      if (this.remindPasswordTimer) {
        return showErrorDialog.call(this, {
          error: {
            message: i18n.t('modules.auth.droppass.delay'),
          },
        });
      }

      f7.dialog.confirm(
        i18n.t('modules.auth.droppass.confirm').toString(),
        i18n.t('modules.auth.droppass.title').toString(),
        () => {
          f7.preloader.show();

          this.dropPassword({ username: this.phone })
            .then(() => {
              f7.dialog.alert(
                i18n.t('modules.auth.droppass.done') as string,
                i18n.t('modules.auth.droppass.title') as string,
              );
            })
            .catch((error) => {
              showErrorDialog.call(this, { error });
            })
            .finally(() => {
              this.setResetPasswordDelay(new Date());

              f7.preloader.hide();
            });
        },
      );
    },
    ...authStoreMapper.mapActions(['auth', 'dropPassword']),
    ...authStoreMapper.mapMutations([
      'setResetPasswordDelay',
      'setAfterLoginCallback',
      'setAfterRegisterCallback',
    ]),
  },
  watch: {
    password() {
      if (this.password) {
        this.error = '';
      }
    },
    resetPasswordDelay() {
      this.remindPasswordTimer = this.remindPasswordDelay;
    },
    remindPasswordTimer() {
      if (!this.remindPasswordTimerInterval && this.remindPasswordTimer > 0) {
        this.remindPasswordTimerInterval = setInterval(() => {
          if (this.remindPasswordTimer <= 0) {
            this.remindPasswordTimer = 0;
            clearInterval(this.remindPasswordTimerInterval);
            this.remindPasswordTimerInterval = null;
          } else {
            this.remindPasswordTimer--;
          }
        }, 1000);
      }
    },
  },
});
