

















import { defineComponent, PropType } from 'vue';

import { IProduct } from '../types';

import { getImageThumbnailPath } from '@/utils';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '../store';

import CatalogItem from '@/components/CatalogItem.vue';
import { getProductsByIds } from '@/modules/catalog/dist/api';

export default defineComponent({
  components: { CatalogItem },
  props: {
    products: Array as PropType<IProduct[]>,
    clickEvent: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  created() {
    this.$emit('update:get-products', this.getProducts);
  },
  computed: {
    productsList(): IProduct[] {
      return this.products || [];
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'deviceWidth', 'fullImagePath']),
    ...catalogStoreMapper.mapGetters(['settings', 'receiveBranchId']),
    ...catalogStoreMapper.mapState(['basket']),
  },
  methods: {
    async getProducts(ids: number[]): Promise<IProduct[]> {
      return await getProductsByIds({ products: ids });
    },
    onClick() {
      this.clickEvent();
    },
    onProductUpdateAmount({
      product,
      $event: amount,
    }: {
      product: IProduct;
      $event: number;
    }) {
      if (this.getProductAmountInBasket(product) !== amount) {
        this.addToBasket({
          amount: amount - this.getProductAmountInBasket(product),
          product,
        });
      }
    },
    getProductAmountInBasket(product: IProduct): number {
      return this.basket.find((entry) => entry.product.id === product.id)?.amount || 0;
    },
    getProductImage(product: IProduct): string {
      if (!product.images[0]) return '';

      return this.fullImagePath(
        getImageThumbnailPath(product.images[0], { deviceWidth: this.deviceWidth }),
      );
    },
    getProductMaxAmount(product: IProduct): Nullable<number> {
      return this.settings.amountControl
        ? product.amounts?.find((entry) => entry.branch_id === this.receiveBranchId)
            ?.amount ?? 0
        : undefined;
    },
    ...catalogStoreMapper.mapActions(['addToBasket']),
  },
});
