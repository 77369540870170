import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';
import 'framework7/css/framework7.bundle.min.css';
import 'src/assets/styles/styles.scss';
import { dom } from '@fortawesome/fontawesome-svg-core';
import queryString from 'query-string';
import dateFormat from 'dateformat';

import Storage from 'src/storage';
import store from 'src/store';
import i18n from 'src/translate/lang';
import config from 'src/app.config.js';
import { detectNotch, LOCALSTORAGE_APPDATA } from 'src/misc';
import { initSentry } from 'src/sentry';
import { initLogRocket } from '@/logrocket';

import App from 'src/App.vue';
import HomeSocial from '@/components/home-social.vue';
import DebugPanelmenuIcon from '@/components/DebugPanelmenuIcon.vue';
import SettingsPanelmenuIcon from '@/components/SettingsPanelmenuIcon.vue';

Vue.component('home-social', HomeSocial);
Vue.component('debug-panelmenu-icon', DebugPanelmenuIcon);
Vue.component('settings-panelmenu-icon', SettingsPanelmenuIcon);

Vue.config.productionTip = false;

Framework7.use(Framework7Vue);

dom.watch();

dateFormat.i18n = config.datetime.dateFormatI18n;

document.addEventListener('deviceready', () => {
  window.Keyboard?.shrinkView?.(true);

  window.addEventListener('keyboardDidShow', () => {
    document.activeElement?.scrollIntoView();
  });
});

const requireComponentIndex = require.context(
  './modules',
  true,
  /^[^_].+\/dist\/index.ts$/,
);

requireComponentIndex.keys().forEach((fileName) => {
  requireComponentIndex(fileName).init();
});

try {
  window.storage = JSON.parse(
    localStorage.getItem(`${config.api.client}-storage`) || 'null',
  );
} catch {
  window.storage = {};
}

if (window?.storage?.[LOCALSTORAGE_APPDATA]) {
  init();
} else {
  Storage.forEach(() => {}).finally(init);
}

function init() {
  const qs = queryString.parseUrl(window.location.href);

  window.devMode =
    Object.keys(qs.query || {}).length > 0 ||
    !!Storage.getSync('devMode') ||
    process.env.VUE_APP_DEVMODE === 'true';

  if (window.devMode) {
    window.qs = qs.query;
  } else {
    initSentry();
    initLogRocket();
  }

  new Vue({
    i18n,
    render: (h) => h(App),
    store,
  }).$mount('#app');
}

if (process.env.VUE_APP_PWA === 'true' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register('./sw.js').then(() => {
    console.log('Service Worker Registered');
  });
}

detectNotch();
