






import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'TheBarWrapper',
  props: {
    safeArea: Boolean as PropType<boolean>,
  },
  computed: {
    isEmpty(): boolean {
      return !this.$slots.default;
    },
  },
});
