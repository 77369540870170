




























import { ISpecialOffer } from '../types';

import { computed, defineComponent, ref, PropType, getCurrentInstance } from 'vue';

import { specialOfferStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';
import { mixin } from '../composition';

import BonusBadge from 'src/components/bonus-badge.vue';

export default defineComponent({
  name: 'SpecialOffersListEntrySlideItem',
  components: { BonusBadge },
  props: {
    offer: {
      type: Object as PropType<ISpecialOffer>,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const popupOpened = ref<boolean>(false);
    const popupComponent = computed<string>(() => `${props.offer.type}-uni-popup`);
    const { goToOffer } = mixin(props as any);

    function showPopup() {
      // TODO: check if components global, not local
      if (vm?.proxy.$options.components?.[popupComponent.value]) {
        popupOpened.value = true;
      } else {
        goToOffer();
      }
    }

    return { popupOpened, popupComponent, showPopup };
  },
  computed: {
    image(): string {
      return this.offer.image
        ? this.fullImagePath(
            getImageThumbnailPath(this.offer.image, 'scale', {
              size: this.deviceWidth,
            }),
          )
        : '';
    },
    styles(): { [key: string]: string } {
      return {
        backgroundImage: this.image ? `url(${this.image})` : '',
      };
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...specialOfferStoreMapper.mapGetters(['settings']),
  },
});
