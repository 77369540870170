































import Vue from 'vue';

import { loadHistory } from '../api';
import { showErrorDialog } from '@/misc';

import HistoryItem from './history-item.vue';
import { ICatalogHistoryItem } from '../types';
import { catalogStoreMapper } from '../store';

export default Vue.extend({
  name: 'CatalogHistory',
  components: { HistoryItem },
  data: () => ({
    history: [] as ICatalogHistoryItem[],
    loading: false,
    total: 0,
    page: 1,
  }),
  mounted() {
    this.loadHistory({ reset: true });
  },
  computed: {
    limit(): number {
      return this.settings.historyItemsPerPage || 10;
    },
    historyLoaded(): boolean {
      return this.totalPages === this.page;
    },
    preloaderClasses(): any[] {
      return [
        (this as any).$style.preloader,
        { bottom: !this.historyLoaded && this.history.length },
      ];
    },
    totalPages(): number {
      return this.total ? Math.ceil(this.total / this.limit) : 0;
    },
    showPreloader(): boolean {
      return !this.historyLoaded && this.loading;
    },
    ...catalogStoreMapper.mapGetters(['settings']),
    ...catalogStoreMapper.mapState(['orderStatus']),
  },
  methods: {
    loadHistory(params?: any): any {
      this.loading = true;

      return loadHistory({ limit: this.limit })
        .then((response) => {
          this.total = response.total;
          this.history = response.items;

          this.$nextTick(this.updateOrderStatus);
        })
        .catch((error) => {
          showErrorDialog.call(this, { error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onInfinite(): void {
      if (!this.loading && !this.historyLoaded) {
        const nextPage = this.page + 1;

        this.loading = true;

        loadHistory({ limit: this.limit, page: nextPage })
          .then((response: any) => {
            this.page = nextPage;
            this.total = response.total;
            this.history = this.history.concat(response.items);

            this.$nextTick(this.updateOrderStatus);
          })
          .catch((error: any) => {
            showErrorDialog.call(this, { error });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onPTRRefresh(done: () => void): void {
      if (!this.loading) {
        this.loading = true;

        loadHistory({ limit: this.limit })
          .then((response: any) => {
            this.page = 1;
            this.total = response.total;
            this.history = response.items;

            this.$nextTick(this.updateOrderStatus);
          })
          .catch((error: any) => {
            showErrorDialog.call(this, { error });
          })
          .finally(() => {
            this.loading = false;

            done();
          });
      }
    },
    updateOrderStatus() {
      this.history.forEach((item) => {
        if (item.id === this.orderStatus?.id) {
          this.setOrderStatus(item);
        }
      });
    },
    ...catalogStoreMapper.mapMutations(['setOrderStatus']),
  },
});
