import { Mutations } from 'vuex-smart-module';

import { IFeedback } from '../types';

import FeedbackState from './state';

export default class FeedbackMutations extends Mutations<FeedbackState> {
  setFeedback(list: IFeedback[]) {
    this.state.feedback = list;
  }
}
