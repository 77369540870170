

























































import { CodeType } from 'src/store/types';

import Vue from 'vue';
import VueQRCode from '@chenfengyuan/vue-qrcode';
import QRCode from 'qrcode-svg-ts';

import bus from 'src/bus';
import { appStoreMapper } from 'src/store/appstore';
import { bonusStoreMapper } from '@/modules/bonus/dist/store';
import { useBrightness } from 'src/composition';
import { formatNumber } from 'src/misc';

let brightnessTurnOn: () => void;
let brightnessTurnOff: () => void;

export default Vue.extend({
  name: 'Bonus',
  components: { VueQRCode },
  data: () => ({
    popupVisible: false,
    refreshCount: 0,
    qr: '',
    options: {
      width: (window.innerWidth * 2) / 3,
      margin: 2,
      errorCorrectionLevel: 'Q',
    },
  }),
  setup() {
    const { brightnessTurnOn: turnOn, brightnessTurnOff: turnOff } = useBrightness();

    brightnessTurnOn = turnOn;
    brightnessTurnOff = turnOff;

    return {};
  },
  computed: {
    CodeType() {
      return CodeType;
    },
    idDevice(): number {
      return this.user?.idDevice || 0;
    },
    idUser(): number {
      return this.user?.idUser || 0;
    },
    ...appStoreMapper.mapGetters(['codeType', 'user']),
    ...appStoreMapper.mapState(['authToken']),
    ...bonusStoreMapper.mapGetters(['settings']),
    ...bonusStoreMapper.mapState(['customTitle']),
  },
  methods: {
    formatNumber,
    generateQrCode() {
      this.qr = new QRCode({
        content: this.user?.codeQR || this.user?.code || 'invalid qr-code',
        padding: 2,
        width: (window.innerWidth * 2) / 3,
        height: (window.innerWidth * 2) / 3,
        color: '#000000',
        background: '#ffffff',
        ecl: 'L',
        container: 'svg-viewbox',
        join: true,
      }).svg();
    },
    closePopup() {
      this.$f7?.popup.close();
    },
    onPopupClose() {
      brightnessTurnOff();
    },
    onPopupOpened() {
      bus.$emit('getMessages', { tries: 10 * 6 });

      brightnessTurnOn();
    },
    onPopupOpen() {
      setTimeout(() => {
        this.options.width = (window.innerWidth * 2) / 3;
        this.popupVisible = true;
      }, 100);
    },
    onPopupClosed() {
      this.popupVisible = false;
      this.setCustomTitle('');
    },
    onClick() {
      this.options.width = (window.innerWidth * 2) / 3;
      this.refreshCount++;
    },
    ...bonusStoreMapper.mapMutations(['setCustomTitle']),
  },
  watch: {
    user: {
      handler() {
        this.generateQrCode();
      },
      deep: true,
      immediate: true,
    },
  },
});
