


















import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import config from '../auth.conf.json';
import { authStoreMapper } from '../store';
import { appStoreMapper } from 'src/store/appstore';

export default defineComponent({
  name: 'AuthBlock',
  computed: {
    ...authStoreMapper.mapGetters(['isAuthorized', 'user', 'settings']),
    ...appStoreMapper.mapGetters(['userNameShort']),
  },
  methods: {
    goToAuth() {
      f7.panel.close();

      f7.views.current.router.navigate('/auth');
    },
    goToProfile() {
      f7.panel.close();

      if (this.settings.tab) {
        f7.tab.show(`#${config.tabName}`);
      } else {
        this.$nextTick(() => {
          f7.views.current.router.navigate('/profile');
        });
      }
    },
  },
});
