import { createComposable, createMapper, Module } from 'vuex-smart-module';

import AuthMutations from './mutations';
import AuthActions from './actions';
import AuthState from './state';
import AuthGetters from './getters';

const module = new Module({
  actions: AuthActions,
  getters: AuthGetters,
  mutations: AuthMutations,
  namespaced: true,
  state: AuthState,
});

export default module;

export const authStoreMapper = createMapper(module);
export const useAuthStore = createComposable(module);
