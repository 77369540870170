
import { defineComponent, h, useCssModule } from 'vue';

import { slotSeeker } from '@/utils';

export default defineComponent({
  name: 'HomeNavbarSlotRight',
  render() {
    const $style = useCssModule();

    return h('f7-nav-right', { class: $style.navRight }, [
      slotSeeker('home-navbar-slot-right'),
      this.$slots.default,
    ]);
  },
});
