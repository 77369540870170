




import Vue from 'vue';

import { bonusStoreMapper } from '../store';

import BonusProgress from './BonusProgress.vue';

export default Vue.extend({
  name: 'BonusHomeSlotGroup',
  components: { BonusProgress },
  computed: {
    show(): boolean {
      return !!this.nextGroup && !!this.settings.showNextGroup;
    },
    ...bonusStoreMapper.mapGetters(['settings', 'nextGroup']),
  },
});
