
import { CreateElement } from 'vue';
import { defineComponent } from 'vue';
import { CSSSelector } from 'framework7/components/app/app-class';

import bus from 'src/bus';
import { appStoreMapper } from 'src/store/appstore';
import config from 'src/app.config.js';
import { getModule } from 'src/misc';
import i18n from '@/translate/lang';

import Tabbar from 'src/components/tabbar.vue';
import TabbarSlot from 'src/components/TabbarSlot.vue';

export default defineComponent({
  name: 'Tabs',
  data: () => ({
    loadingProgress: 0,
    showRepeatButton: false,
    interval: null as any,
  }),
  render(this: any, h: CreateElement) {
    return h(
      'f7-views',
      {
        class: 'safe-areas',
        props: {
          tabs: true,
        },
      },
      [
        h(TabbarSlot),
        !this?.$theme?.aurora && h(Tabbar),
        this.appData?.settings ? getTabs.call(this, h) : getLoadingProgress.call(this, h),
      ],
    );
  },
  mounted() {
    if (this.defaultTab) {
      bus.$on('authAfterLogout', () => {
        setTimeout(() => {
          this.setResetTabs();
          this.$f7?.tab.show(`#${getModule(this.defaultTab).tabName}` as CSSSelector);
        }, 500);
      });
    }

    if (!this.appData.settings) {
      this.startProgress();
    }

    bus.$on('auth:reset-timeout', this.startProgress);
    bus.$on('auth:init-error', this.showError);
    bus.$on('regDeviceDidFail', this.showError);
    bus.$on('appDataDidFail', this.showError);
    bus.$on('appDataDidLoad', () => {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    });
  },
  computed: {
    ...appStoreMapper.mapGetters(['defaultTab']),
    ...appStoreMapper.mapState(['appData']),
  },
  methods: {
    startProgress() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      const timeout = 20 * 1000;
      const step = (100 - this.loadingProgress) / (timeout / 1000);
      const statusElement = this.$refs.status as HTMLElement;

      if (statusElement) {
        statusElement.innerHTML = i18n.t('global.misc.connection.connecting').toString();
      }

      this.interval = setInterval(() => {
        this.loadingProgress += step;

        const handleElement = this.$refs.handle as HTMLElement;
        if (handleElement) {
          handleElement.style.width = this.loadingProgress + '%';
        }

        if (this.loadingProgress > 100) {
          this.showError();
        }
      }, 1000);
    },
    repeatRegister() {
      bus.$emit('auth:init');

      this.showRepeatButton = false;
      this.loadingProgress = 0;

      this.startProgress();
    },
    showError() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      this.showRepeatButton = true;

      this.$nextTick(() => {
        (this.$refs.status as HTMLElement).innerHTML = i18n
          .t('global.misc.connection.error')
          .toString();
      });
    },
    ...appStoreMapper.mapMutations(['setCurrentTab', 'setResetTabs', 'setTabDidOpen']),
  },
});

function getLoadingProgress(this: any, h: any) {
  return h('div', { class: this.$style.loader }, [
    h('div', { class: this.$style.logo }),
    h('div', { class: this.$style.progress }, [
      h('div', { class: this.$style.status, ref: 'status' }),
      !this.showRepeatButton &&
        h('div', { class: this.$style.bar }, [
          h('div', { class: this.$style.handle, ref: 'handle' }),
        ]),
      this.showRepeatButton &&
        h(
          'f7-button',
          {
            class: this.$style.button,
            props: { fill: true, large: true },
            on: { click: this.repeatRegister },
          },
          i18n.t('global.misc.connection.retry').toString(),
        ),
    ]),
  ]);
}

function getTabs(this: any, h: any) {
  const modules = config.modules.tabs;
  let tabs: any = [];

  modules.forEach((module: any) => {
    const m = getModule(module);
    const tabName = m.tabName;
    const moduleAsTab =
      tabName && (this.appData.settings?.modules?.[module]?.tab ?? true);

    if (moduleAsTab && this.appData.settings?.modules?.[module]) {
      tabs.push(
        h('f7-view', {
          key: `${this.$store.state.domain}-${module}`,
          class: {
            'with-tabbar': true,
          },
          props: {
            id: tabName,
            url: m.url,
            tab: true,
            'tab-active': this.defaultTab
              ? module === this.defaultTab
              : module === 'home',
          },
          on: {
            'tab:show': () => {
              this.setTabDidOpen(module);
              this.setCurrentTab(module);
            },
          },
        }),
      );
    }
  });

  return tabs;
}
