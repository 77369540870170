













import Vue from 'vue';
import { f7 } from 'framework7-vue';

import { bookingStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';

export default Vue.extend({
  name: 'BookingHomeSlotButton',
  computed: {
    show(): boolean {
      return !!this.settings.homeShowButton;
    },
    ...appStoreMapper.mapGetters(['relatedModuleLogoAvailable']),
    ...bookingStoreMapper.mapGetters(['settings']),
  },
  methods: {
    openBooking() {
      f7.views.current.router.navigate('/booking/');
    },
  },
});
