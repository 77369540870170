



















import { defineComponent, PropType } from 'vue';

import { useCatalogStore } from '../store';
import { formatNumber } from '@/utils';

export default defineComponent({
  name: 'CatalogBonusPayBar',
  props: {
    total: Number as PropType<number>,
  },
  setup(props, { emit }) {
    const catalogStore = useCatalogStore();
    const payByBonusType = catalogStore.getters.settings.payByBonusType;

    function onClick() {
      emit('click');
    }

    return { onClick, payByBonusType, formatNumber };
  },
});
