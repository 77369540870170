import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import FeedbackState from './state';

export default class FeedbackGetters extends Getters<FeedbackState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get moduleIsActive(): boolean {
    return !!this.getters.settings;
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData?.settings?.modules
      ?.feedback as IModuleSettings;

    return settings;
  }
}
