import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { BonusGroup, IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import BonusState from './state';

export default class BonusGetters extends Getters<BonusState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get moduleEnabled(): boolean {
    // this is default module
    return true;
    //return !!this.app.state.appData?.settings?.modules?.bonus;
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData?.settings?.modules?.bonus || { groups: [] };

    settings.homeBonusButtonType = settings.homeBonusButtonType ?? 'button';
    settings.showNextGroup = settings.showNextGroup ?? true;

    return settings;
  }

  get nextGroup(): Nullable<BonusGroup> {
    return this.getters.settings.groups.find(
      (group) => group.id === this.app.getters.user?.nextGroupId,
    );
  }
}
