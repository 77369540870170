




import { defineComponent, PropType } from 'vue';

import { openUrl } from 'src/misc';
import config from 'src/app.config.js';
import { appStoreMapper } from '@/store/appstore';

export default defineComponent({
  name: 'Content',
  props: {
    html: String as PropType<string>,
  },
  computed: {
    content(): string {
      let result = this.html || '';

      result = result.replace(/{{deviceWidth}}/g, this.deviceWidth.toString());

      if (config.app?.miniapp || config.app?.pwa) {
        result = result.replace(/rel=".+"/g, '');
        result = result.replace(/target=".+"/g, '');
      }

      return result;
    },
    ...appStoreMapper.mapGetters(['deviceWidth']),
  },
  watch: {
    content: {
      handler(string: string) {
        if (string) {
          this.$nextTick(() => {
            const body = this.$refs.body as Element;

            body.querySelectorAll('a').forEach((element) => {
              if (config.app?.miniapp || config.app?.pwa) {
                element.classList.add('external');
              }

              element.addEventListener('click', (e: Event) => {
                openUrl(element.href, '_system', e);
              });
            });

            body.querySelectorAll('table:not(.no-scroll)').forEach((element) => {
              const wrapper = document.createElement('div');
              wrapper.classList.add('scroll');
              body.insertBefore(wrapper, element);
              wrapper.appendChild(element);
            });
          });
        }
      },
      immediate: true,
    },
  },
});
