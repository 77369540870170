












import { Gift, GiftType } from '../types';

import Vue, { PropType } from 'vue';
import bus from '@/bus';

import { appStoreMapper } from '@/store/appstore';

export default Vue.extend({
  name: 'GiftButtonAdd',
  props: {
    gift: Object as PropType<Gift>,
    type: {
      type: String as PropType<GiftType>,
      default: 'gift',
    },
  },
  data: () => ({
    bonusSpent: 0,
  }),
  mounted() {
    bus.$once('bonus:spent:set', (value: number) => (this.bonusSpent = value));
    bus.$emit('bonus:spent:get');
  },
  computed: {
    giftType(): 'gifts' | 'coupons' {
      if (this.type === 'coupon') {
        return 'coupons';
      }

      return 'gifts';
    },
    bonusDeficit(): number {
      const diff = (this.user ? this.user.bonus - this.gift.price : 0) - this.bonusSpent;

      return diff >= 0 ? 0 : Math.abs(diff);
    },
    requestButtonText(): string {
      if (this.bonusDeficit > 0) {
        return this.$t(`modules.${this.giftType}.activation.insufficient`, [
          this.bonusDeficit,
          this.$tc('global.bonus.plural', this.bonusDeficit).toString(),
        ]).toString();
      } else {
        return this.$t(
          this.gift.price
            ? `modules.${this.giftType}.actions.request`
            : `modules.${this.giftType}.actions.now`,
          [this.gift.price, this.$tc('global.bonus.plural', this.gift.price).toString()],
        ).toString();
      }
    },
    ...appStoreMapper.mapGetters(['user', 'appSettings']),
  },
  methods: {
    onRequestClicked() {
      if (!!this.bonusDeficit && this.type === 'coupon') {
        this.$f7?.dialog.alert(
          this.$t('modules.coupons.dialog.insufficient.text', [
            `${this.bonusDeficit} ${this.$tc(
              'global.bonus.plural',
              this.bonusDeficit,
            ).toString()}`,
            `${this.gift.price} ${this.$tc(
              'global.bonus.plural',
              this.gift.price,
            ).toString()}`,
          ]).toString(),
          this.$t('modules.coupons.dialog.insufficient.title').toString(),
        );

        return false;
      }

      if (this.appSettings.needRegister && !this.user?.idUser) {
        bus.$emit('needRegister', {
          title: this.$t(`modules.${this.giftType}.title`).toString(),
          text: this.$t(`modules.${this.giftType}.needRegister.text`).toString(),
        });

        return false;
      }

      const dialog = this.$f7?.dialog.create({
        title: this.$t(`modules.${this.giftType}.activation.title`).toString(),
        text: this.$t(`modules.${this.giftType}.activation.confirm`, [
          this.gift.title,
        ]).toString(),
        buttons: [
          {
            text: this.$t('global.dialog.cancel').toString(),
            onClick: () => {
              this.$emit('cancel');
            },
            close: true,
          },
          {
            text: this.$t(`modules.${this.giftType}.actions.activate`).toString(),
            onClick: () => {
              this.$emit('confirm');
            },
            close: true,
            bold: true,
          },
        ],
      });

      dialog.open();
    },
  },
});
