













import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    visible: Boolean as PropType<boolean>,
  },
  data: () => ({
    innerVisibility: false,
  }),
  methods: {
    onClick() {
      this.innerVisibility = !this.innerVisibility;
    },
  },
  watch: {
    visible: {
      handler() {
        this.innerVisibility = this.visible;
      },
      immediate: true,
    },
    innerVisibility() {
      this.$emit('update:visible', this.innerVisibility);
    },
  },
});
