






import { defineComponent } from 'vue';

import { usePopupOpener } from '../composition';

export default defineComponent({
  name: 'BonusMenuItem',
  setup() {
    const { openBonus } = usePopupOpener();
    return { openBonus };
  },
});
