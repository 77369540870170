
import { IconType, IModuleSettings } from '../types';

import { CreateElement, RenderContext, VNode } from 'vue';
import { f7 } from 'framework7-vue';

import config from '../catalog.conf.json';
import store from 'src/store';

export default {
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode[] {
    const settings = store.getters['catalog/settings'] as IModuleSettings;
    const items = [
      h(
        'f7-list-item',
        {
          slot: 'list',
          key: 'catalog-menu-item-1',
          props: {
            title: settings?.title || context.parent.$t(`modules.catalog.title`),
          },
          on: {
            click: () => {
              f7.panel.close();

              if (settings?.tab) {
                f7.tab.show(`#${config.tabName}`);
              } else {
                f7.views.current.router.navigate({ name: 'Catalog' });
              }
            },
          },
        },
        [
          h('div', {
            class: [
              'icon',
              {
                delivery: settings?.icon === IconType.delivery,
              },
            ],
            slot: 'media',
          }),
        ],
      ),
    ];

    if (settings.showBasket) {
      items.push(
        h(
          'f7-list-item',
          {
            slot: 'list',
            key: 'catalog-menu-item-2',
            props: {
              title:
                settings?.historyTitle ||
                context.parent.$t(`modules.catalog.history.title`),
            },
            on: {
              click: () => {
                f7.panel.close();
                f7.views.current.router.navigate('/catalog/history');
              },
            },
          },
          [
            h('div', {
              class: ['icon', 'history'],
              slot: 'media',
            }),
          ],
        ),
      );
    }

    return items;
  },
};
