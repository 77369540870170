








import Vue from 'vue';

import bus from '@/bus';

import { relatedStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';

export default Vue.extend({
  name: 'RelatedLogo',
  computed: {
    currentDomain(): IRelated | undefined {
      return this.list.find((related) => related.domain === this.domain);
    },
    currentDomainTitle(): string {
      return this.currentDomain?.name || '';
    },
    showButton(): boolean {
      return this.moduleEnabled && !!this.currentDomain;
    },
    ...appStoreMapper.mapState(['domain']),
    ...relatedStoreMapper.mapGetters(['list', 'moduleEnabled', 'settings']),
  },
  methods: {
    onSwitchRelatedClicked() {
      bus.$emit('relatedPopupShow');
    },
  },
});
