interface IOptions {
  separator?: string;
  delimiter?: string;
}

function _proceedDecimalPart(
  value: number,
  decimalRoundingRule: decimalRoundingRule,
  decimal: number = 0,
): string {
  const decimalPartString = value.toString().split('.')[1];
  const decimalPart = decimalPartString ? Number(decimalPartString) : 0;
  let result = '';

  if (decimalPart) {
    const tempResult = decimalPart / Math.pow(10, decimalPartString.length - decimal);
    let roundedResult: number;

    switch (decimalRoundingRule) {
      case 'floor':
        roundedResult = Math.floor(
          tempResult % 1 < 0.01 ? Math.trunc(tempResult) : tempResult,
        );
        break;
      case 'ceil':
        roundedResult = Math.ceil(
          tempResult % 1 < 0.01 ? Math.trunc(tempResult) : tempResult,
        );
        break;
      case 'round':
        roundedResult = Math.round(
          tempResult % 1 < 0.01 ? Math.trunc(tempResult) : tempResult,
        );
        break;
      case 'trunc':
        roundedResult = Math.trunc(tempResult);
        break;
      case 'default':
      default:
        roundedResult = tempResult;
    }

    result = roundedResult.toString();
  }

  result = result.padEnd(decimal, '0');

  return result;
}

function _formatNumber(num: number): string;
function _formatNumber(
  num: number,
  decimalRoundingRule: decimalRoundingRule | undefined,
  decimal?: number,
): string;
function _formatNumber(
  num: number,
  decimalRoundingRule: decimalRoundingRule | undefined,
  decimal: number,
  options: {
    separator?: string;
    delimiter?: string;
  },
): string;
function _formatNumber(num: number, options: IOptions): string;
function _formatNumber(
  num: number,
  decimalRoundingRuleOrOptions: decimalRoundingRule | IOptions = 'default',
  decimal: number = 0,
  options?: IOptions,
) {
  let decimalRoundingRule;

  if (typeof decimalRoundingRuleOrOptions === 'string') {
    decimalRoundingRule = decimalRoundingRuleOrOptions;
  } else {
    options = decimalRoundingRuleOrOptions;
  }

  const separator = options?.separator ?? ' ';
  const delimiter = options?.delimiter ?? '.';
  let resultNum = '';

  switch (decimalRoundingRule) {
    case 'round':
      resultNum = num.toFixed(decimal);
      break;
    case 'floor':
      if (decimal) {
        resultNum =
          Math.trunc(num) + '.' + _proceedDecimalPart(num, decimalRoundingRule, decimal);
      } else {
        resultNum = Math.floor(num).toString();
      }
      break;
    case 'ceil':
      if (decimal) {
        resultNum =
          Math.trunc(num) + '.' + _proceedDecimalPart(num, decimalRoundingRule, decimal);
      } else {
        resultNum = Math.ceil(num).toString();
      }
      break;
    case 'trunc':
      const [left, right] = num.toString().split('.');
      resultNum += left;
      if (!right) {
        if (decimal) resultNum += '.' + ''.padEnd(decimal, '0');
      } else {
        const padded = right.substring(0, decimal).padEnd(decimal, '0');
        if (decimal) resultNum += '.' + padded;
      }
      break;

    default:
      resultNum = num.toString();
  }

  let [left, right] = resultNum.split('.');
  left = left.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator);
  right = Number(right) ? delimiter + right : '';

  return left + right;
}

export const formatNumber = _formatNumber;
