









































import { defineComponent } from 'vue';
import StarRating from 'vue-star-rating';
import { f7 } from 'framework7-vue';

import { appStoreMapper } from '@/store/appstore';
import { feedbackStoreMapper } from '../store';
import { timeZone } from '@/utils';
import { showErrorDialog } from '@/misc';

export default defineComponent({
  name: 'FeedbackList',
  components: {
    StarRating,
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    if (!this.feedback.length) {
      this.loadFeedback();
    }
  },
  computed: {
    ...appStoreMapper.mapGetters(['timeZoneOptions']),
    ...feedbackStoreMapper.mapState(['feedback']),
  },
  methods: {
    loadFeedback() {
      this.loading = true;
      f7.preloader.show();

      this.getFeedback()
        .catch((error) => {
          showErrorDialog.call(this, { error });
        })
        .finally(() => {
          this.loading = false;
          f7.preloader.hide();
        });
    },
    getFormattedDate(date: string) {
      return timeZone(date, { type: 'datetimeShort', timezone: this.timeZoneOptions });
    },
    onPTRRefresh(done: () => void) {
      done();

      this.loadFeedback();
    },
    ...feedbackStoreMapper.mapActions(['getFeedback']),
  },
});
