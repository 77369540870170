



































import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
} from 'vue';

import { formatNumber } from '@/utils/formatNumber';
import { useBonusStore } from '@/modules/bonus/dist/store';

import AnimatedNumber from '@/components/AnimatedNumber.vue';
import BonusIcon from '@/components/bonus-icon.vue';

export default defineComponent({
  name: 'BonusHomeSlotCard',
  components: { AnimatedNumber, BonusIcon },
  props: {
    bonus: {
      type: Number as PropType<number>,
      required: true,
      validator: (value: number) => value >= 0,
    },
    groupBonus: {
      type: Number as PropType<number>,
      validator: (value: number) => value >= 0 && value <= 100,
    },
    groupName: {
      type: String as PropType<string>,
    },
    decimalOptions: {
      type: Object as PropType<IDecimalOptions>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const bonusStore = useBonusStore();

    const bonusComputed = computed<string>(() => formatNumber(props.bonus));
    const bonusIconSize = ref<number>(getItemSize());
    const isDesktop = document.documentElement.classList.contains('device-desktop');

    onMounted(() => {
      window.addEventListener('resize', onResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    function getItemSize(): number {
      return isDesktop ? 45 : Math.ceil(window.innerWidth * 0.09);
    }

    function formatBonus(value: number) {
      return formatNumber(
        value,
        props.decimalOptions.decimalRoundingRule,
        props.decimalOptions.decimal,
      );
    }

    function onClick() {
      emit('click');
    }

    function onResize() {
      bonusIconSize.value = getItemSize();
    }

    return {
      bonusComputed,
      bonusIconSize,
      formatNumber,
      formatBonus,
      onClick,
      settings: bonusStore.getters.settings,
    };
  },
});
