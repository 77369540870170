import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-smart-module';

import AppStore from './appstore';

Vue.use(Vuex);

export default createStore(AppStore, {
  strict: process.env.NODE_ENV !== 'production',
});
