















import Vue from 'vue';
import { defineComponent, PropType } from 'vue';

import i18n from 'src/translate/lang';
import { date, required } from 'src/validators/validators';

import ListInput from './list-input.vue';
import { appStoreMapper } from '@/store/appstore';

export default defineComponent({
  name: 'ListInputDate',
  components: { ListInput },
  props: {
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showError: Boolean as PropType<boolean>,
    value: {
      type: null,
    },
    masked: Boolean as PropType<boolean>,
    label: {
      type: String as PropType<string>,
      default: i18n.t('global.fields.date.label'),
    },
    placeholder: String as PropType<string>,
  },
  data: () => ({
    validationParams: {
      $autoDirty: true,
    } as any,
  }),
  created() {
    if (this.masked) {
      this.validationParams.date = date;
    }
  },
  computed: {
    mask(): Nullable<string> {
      return this.masked ? '##.##.####' : undefined;
    },
    pattern(): Nullable<string> {
      return this.masked ? '[0-9]{2}.[0-9]{2}.[0-9]{4}' : undefined;
    },
    placeholderComputed(): Nullable<string> {
      return this.masked
        ? i18n.t('global.fields.date.placeholder').toString()
        : this.placeholder;
    },
    type(): Nullable<string> {
      return this.masked ? 'tel' : 'date';
    },
    ...appStoreMapper.mapGetters(['appSettings']),
  },
  watch: {
    required: {
      handler(value: boolean) {
        if (value) {
          this.validationParams.required = required;
        } else {
          Vue.delete(this.validationParams, 'required');
        }
      },
      immediate: true,
    },
  },
});
