import { API_GEOCODER_SUGGEST, API_GEOCODER_SUGGEST_ADDRESS } from '@/misc/const';
import { request } from '@/api/index';

interface GeocoderSettings {
  settings?: {
    radius?: number;
    [key: string]: any;
  };
}

export interface IGetGeocoderSuggestionsRequest extends GeocoderSettings {
  query: string;
}

export interface IGetGeocoderAddressRequest extends GeocoderSettings {
  lat: string;
  lng: string;
}

export interface IGeocoderResponse {
  data: GeocoderResponse;
}

export type GeocoderResponse = {
  buildFormat: GeocoderBuildFormat;
  point: IMapPosition;
  radius: number;
  suggest: Suggest[];
};

export async function getGeocoderSuggestions(
  data: IGetGeocoderSuggestionsRequest,
): Promise<GeocoderResponse> {
  const mergeDataWithSettings = {
    query: data.query,
    ...data.settings,
  };

  return request<IGeocoderResponse>({
    api: 'core',
    method: 'POST',
    url: API_GEOCODER_SUGGEST,
    data: mergeDataWithSettings,
  }).then((response) => response.data);
}

export async function getGeocoderAddress(
  data: IGetGeocoderAddressRequest,
): Promise<GeocoderResponse> {
  const mergeDataWithSettings = {
    point: {
      lat: data.lat.substr(0, 15),
      lng: data.lng.substr(0, 15),
    },
    ...data.settings,
  };

  return request<IGeocoderResponse>({
    api: 'core',
    method: 'POST',
    url: API_GEOCODER_SUGGEST_ADDRESS,
    data: mergeDataWithSettings,
  }).then((response) => response.data);
}
