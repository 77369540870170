import { createComposable, createMapper, Module } from 'vuex-smart-module';

export interface ITabsState {
  [key: string]: boolean;
}

import AppActions from '@/store/app/actions';
import AppGetters from '@/store/app/getters';
import AppMutations from '@/store/app/mutations';
import AppState from '@/store/app/state';

const module = new Module({
  namespaced: true,
  actions: AppActions,
  getters: AppGetters,
  mutations: AppMutations,
  state: AppState,
});

export default module;

export const appStoreMapper = createMapper(module);
export const useAppStore = createComposable(module);
