









import Vue from 'vue';
import { f7 } from 'framework7-vue';

import { OSNotification } from 'onesignal-cordova-plugin';
import { idAction } from '../types';

import config from '../actions.conf.json';
import bus from 'src/bus';

import ActionPopup from './action-popup.vue';

export default Vue.extend({
  components: {
    ActionPopup,
  },
  data: () => ({
    id: null as Nullable<number>,
    popupOpened: false,
  }),
  destroyed() {
    bus.$off('action-push', this.handlePush);
    bus.$off('actions-list', this.openActionsList);
  },
  mounted() {
    bus.$on('action-push', this.handlePush);
    bus.$on('actions-list', this.openActionsList);
  },
  methods: {
    async handlePush(notification: OSNotification) {
      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;

      this.id = additionalData?.idConnectedEntity as unknown as idAction;
      this.popupOpened = !!this.id;
    },
    openActionsList() {
      f7.tab.show(`#${config.tabName}`);
    },
  },
});
