import { reactive } from 'vue';
import { IValidation, IValidationState } from './types';
import { Validation } from 'vuelidate';

export default function () {
  const validationState: IValidation = reactive({
    dirty: false,
    invalid: false,
  });

  const onStateChanged = (state: IValidationState) => {
    // @ts-ignore
    validationState[state.key] = state.v$[state.key] as Validation;

    let dirty = false;
    let invalid = false;

    Object.keys(validationState).forEach((key) => {
      if (key !== 'dirty' && key !== 'invalid') {
        if (validationState[key].$dirty) {
          dirty = true;
        }

        if (validationState[key].$invalid) {
          invalid = true;
        }
      }
    });

    validationState.dirty = dirty;
    validationState.invalid = invalid;
  };

  function touchAll() {
    Object.keys(validationState).forEach((key) => {
      if (key !== 'dirty' && key !== 'invalid') {
        validationState[key].$touch();
      }
    });
  }

  return { onStateChanged, validationState, touchAll };
}
