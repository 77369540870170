

























































import Vue from 'vue';

import bus from '@/bus';
import { stampCardStoreMapper } from '../store';
import { formatNumber } from '@/utils';

export default Vue.extend({
  name: 'StampCard',
  computed: {
    isShort(): boolean {
      return !!this.settings && this.settings.stampsToReward <= 3;
    },
    computedCounterTitle(): string {
      return this.settings?.counterTitle || 'Штампов';
    },
    computedStampsValue(): string {
      if (!this.settings) {
        return '';
      }

      let result = '';

      if (this.settings.isReduced) {
        result = formatNumber(
          this.settings.userStamps / this.settings.reduceBy,
          'trunc',
          1,
        );
      } else {
        result = this.settings.userStamps.toString();
      }

      if (!this.settings.isDividerHidden) {
        result += '/';

        if (this.settings.isReduced) {
          result += formatNumber(
            this.settings.stampsToReward / this.settings.reduceBy,
            'round',
            1,
          );
        } else {
          result += this.settings.stampsToReward.toString();
        }
      }

      return result;
    },
    progressPercent(): number {
      if (!this.settings) {
        return 0;
      }

      return Math.floor((this.settings.userStamps / this.settings.stampsToReward) * 100);
    },
    ...stampCardStoreMapper.mapGetters(['settings']),
  },
  methods: {
    openBonus() {
      bus.$emit('bonus:open-popup', {
        customTitle: this.$t('modules.stampcard.popup.title').toString(),
      });
    },
  },
});
