












import Vue from 'vue';
import { defineComponent, PropType } from 'vue';

import i18n from 'src/translate/lang';
import { required } from 'src/validators/validators';

import ListInput from 'src/components/fields/list-input.vue';

export default defineComponent({
  name: 'ListInputTextarea',
  components: { ListInput },
  props: {
    name: {
      type: String as PropType<string>,
      default: () => 'content',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showError: Boolean as PropType<boolean>,
    value: null,
    label: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.textarea.label'),
    },
    placeholder: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.textarea.placeholder'),
    },
    multiple: Array as PropType<any[]>,
    isMultiple: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    radio: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    idKey: String as PropType<string>,
    labelKey: String as PropType<string>,
  },
  data: () => ({
    validationParams: {
      $autoDirty: true,
    } as any,
  }),
  watch: {
    required: {
      handler(value: boolean) {
        if (value) {
          this.validationParams.required = required;
        } else {
          Vue.delete(this.validationParams, 'required');
        }
      },
      immediate: true,
    },
  },
});
