






import Vue from 'vue';
import { f7 } from 'framework7-vue';
import { appStoreMapper } from '@/store/appstore';

export default Vue.extend({
  name: 'NotificationsNavbarButton',
  computed: {
    showNotifications(): boolean {
      return !!this.user?.idUser;
    },
    ...appStoreMapper.mapGetters(['user']),
  },
  methods: {
    goToNotifications() {
      f7.views.current.router.navigate({ path: '/notifications/' });
    },
  },
});
