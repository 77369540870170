










































































































import { AuthType } from 'src/types/enum';
import { ICustomField, IUser } from 'src/types';
import { IRegistrationRequest } from '../types';

import { computed, defineComponent, PropType, ref, watch, nextTick } from 'vue';
import { f7 } from 'framework7-vue';

import bus from 'src/bus';
import { useAppStore } from 'src/store/appstore';
import { useAuthStore } from '../store';
import { openUrl, showErrorDialog } from 'src/misc';
import { getFormattedDate } from '../misc';
import config from 'src/app.config.js';
import useValidationState from '@/validators/validation-state';

import ListInputDate from 'src/components/fields/list-input-date.vue';
import ListInputName from 'src/components/fields/list-input-name.vue';
import ListInputPassword from 'src/components/fields/list-input-password.vue';
import ListInputSurname from 'src/components/fields/list-input-surname.vue';
import ListInputUsername from 'src/components/fields/list-input-username.vue';
import ListInputEmail from 'src/components/fields/list-input-email.vue';
import RegistrationSlotForm from './registration-slot-form.vue';

export default defineComponent({
  name: 'Registration',
  components: {
    ListInputDate,
    ListInputName,
    ListInputPassword,
    ListInputSurname,
    ListInputUsername,
    ListInputEmail,
    RegistrationSlotForm,
  },
  props: {
    username: String as PropType<string>,
  },
  setup(props) {
    const { validationState, onStateChanged, touchAll } = useValidationState();
    const refForm = ref<HTMLElement>(null as unknown as any);
    const refUsername = ref<Vue>(null as unknown as any);
    const refPassword = ref<Vue>(null as unknown as any);

    const maskedUsername = ref<string>(props.username || '');
    const name = ref<string>('');
    const surname = ref<string>('');
    const birthday = ref<string | Date>('');
    const password = ref<string>('');
    const email = ref<string>('');

    const buttonClicked = ref<boolean>(false);
    const success = ref<boolean>(false);
    const emailReceiptActive = ref<boolean>(false);

    const customFieldsData = ref<Nullable<ICustomField>>(null);

    const appStore = useAppStore();
    const authStore = useAuthStore();

    const displayName = ref(
      authStore.getters.user?.fName ||
        authStore.getters.user?.lName ||
        authStore.getters.user?.display_name ||
        '',
    );

    const authTypeBase = computed<boolean>(
      () => authStore.getters.settings.type === AuthType.base,
    );
    const authTypeSms = computed<boolean>(
      () => authStore.getters.settings.type === AuthType.sms,
    );
    const authTypeCall = computed<boolean>(
      () => authStore.getters.settings.type === AuthType.call,
    );
    const EULAlink = computed<string>(
      () =>
        `${config.api.protocol}://${appStore.getters.clientName}.${config.api.host}/content/show/eula`,
    );
    const emailReceiptAvailability = computed<boolean>(
      () =>
        authStore.getters.settings.receiptToEmailModuleEnabled &&
        authStore.getters.settings.email,
    );
    const emailReceiptNeedEmail = computed<boolean>(() => !email.value);

    function onPageAfterIn() {
      nextTick(() => {
        if (!props.username) {
          (refUsername.value.$el as HTMLInputElement)?.querySelector('input')?.focus();
        } else if (!authStore.getters.settings.usePincode) {
          (refPassword.value.$el as HTMLInputElement)?.querySelector('input')?.focus();
        }
      });
    }

    function onUpdate(data: ICustomField) {
      customFieldsData.value = data;
    }

    function openEULA(e: Event) {
      openUrl(EULAlink.value, '_system', e);
    }

    async function register() {
      buttonClicked.value = true;

      if (validationState.invalid) {
        touchAll();

        const invalidInput = refForm.value.querySelector(
          '.item-input-invalid input',
        ) as HTMLInputElement;

        if (invalidInput) {
          invalidInput.focus();
        }

        return;
      }

      const params: IRegistrationRequest = {
        phone: maskedUsername.value,
        password: password.value,
        display_name: (name.value + ' ' + surname.value).trim(),
        bDate: getFormattedDate(birthday.value as any),
        email: email.value,
        sendReceiptToEmail: emailReceiptActive.value,
        ...customFieldsData.value,
      };

      if (authStore.getters.settings.usePincode) {
        f7.views.current.router.navigate('/auth/pin', {
          props: {
            phone: maskedUsername.value,
            regData: params,
          },
        });

        return;
      }

      f7.preloader.show();

      authStore
        .dispatch('registerUser', params)
        .then((user) => {
          displayName.value =
            (user as IUser).fName ||
            (user as IUser).lName ||
            (user as IUser).display_name ||
            '';

          if (authTypeBase.value) {
            success.value = true;

            if (document.body.querySelector('.auth-popup')) {
              f7.popup.close('.auth-popup');
              bus.$emit('getMessages', { tries: 10 * 6 });
            } else {
              setTimeout(() => {
                bus.$emit('getMessages', { tries: 10 * 6 });

                f7.views.current.router.back(f7.views.current.router.history[0], {
                  force: true,
                });
              }, authStore.getters.settings.successTimeout ?? 1500);
            }
          } else if (authTypeSms.value || authTypeCall.value) {
            f7.views.current.router.navigate('/auth/pincode', {
              props: {
                phone: props.username,
                fromRegistration: true,
              },
            });
          }

          bus.$emit('authAfterRegisterSuccess');

          if (authStore.state.afterRegisterCallback) {
            authStore.state.afterRegisterCallback(user as IUser);
            authStore.commit('setAfterRegisterCallback', null);
            authStore.commit('setAfterLoginCallback', null);
          }
        })
        .catch((error) => {
          showErrorDialog({ error });
        })
        .finally(() => {
          f7.preloader.hide();
        });
    }

    function delayedRegister() {
      buttonClicked.value = true;

      nextTick(register);
    }

    function onEmailReceiptClick() {
      if (emailReceiptNeedEmail.value) {
        return false;
      }

      emailReceiptActive.value = !emailReceiptActive.value;
    }

    watch(
      () => email.value,
      (value: string) => {
        if (!value) {
          emailReceiptActive.value = false;
        }
      },
    );

    return {
      validationState,
      onStateChanged,
      refForm,
      refUsername,
      refPassword,
      maskedUsername,
      name,
      surname,
      birthday,
      password,
      email,
      buttonClicked,
      success,
      emailReceiptActive,
      appSettings: appStore.getters.appSettings,
      settings: authStore.getters.settings,
      user: authStore.getters.user,
      emailReceiptAvailability,
      emailReceiptNeedEmail,
      authTypeBase,
      authTypeSms,
      authTypeCall,
      EULAlink,
      displayName,
      onPageAfterIn,
      onUpdate,
      openEULA,
      delayedRegister,
      onEmailReceiptClick,
    };
  },
});
