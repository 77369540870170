import { IModule } from '@/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import {
  faTimes,
  faBarcodeRead,
  faStars,
  faQrcode,
} from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './bonus.conf.json';

import store from 'src/store';
import BonusStore from './store';

import Bonus from './components/bonus.vue';
import BonusMenuItem from './components/bonus-menu-item.vue';
import BonusNavbarButton from './components/bonus-navbar-button.vue';
import BonusHomeSlotButton from './components/bonus-home-slot-button.vue';
import BonusHomeSlotDelay from './components/bonus-home-slot-delay.vue';
import BonusHomeSlotOverview from './components/bonus-home-slot-overview.vue';
import BonusMessage from './components/bonus-message.vue';
import BonusGroups from './components/BonusGroups.vue';

library.add(faCheck, faTimes, faBarcodeRead, faChevronRight, faStars, faQrcode);

export function init() {
  Vue.component('bonus', Bonus);
  Vue.component('bonus-menu-item', BonusMenuItem);
  Vue.component('bonus-navbar-button', BonusNavbarButton);
  Vue.component('bonus-home-slot-button', BonusHomeSlotButton);
  Vue.component('bonus-home-slot-delay', BonusHomeSlotDelay);
  Vue.component('bonus-home-slot-overview', BonusHomeSlotOverview);
  Vue.component('bonus-message', BonusMessage);
  Vue.component('bonus-groups', BonusGroups);

  registerModule(store, ['bonus'], 'bonus/', BonusStore);

  Misc.registerModuleConfig(config as IModule);
}
