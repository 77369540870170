import { IModule } from '@/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faBell as faBellLight } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './notifications.conf.json';

import store from 'src/store';
import NotificationsStore from './store';

import Notifications from './components/Notifications.vue';
import NotificationsDaemon from './components/NotificationsDaemon.vue';
import NotificationsMenuItem from './components/NotificationsMenuItem.vue';
import NotificationsNavbarButton from './components/NotificationsNavbarButton.vue';

library.add(faBell, faBellLight);

export function init() {
  Vue.component('notifications-daemon', NotificationsDaemon);
  Vue.component('notifications-menu-item', NotificationsMenuItem);
  Vue.component('notifications-navbar-button', NotificationsNavbarButton);

  const routes = [
    {
      component: Notifications,
      path: config.url,
    },
  ];

  registerModule(store, ['notifications'], 'notifications/', NotificationsStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
