var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-sheet',{ref:"branches",staticClass:"maps-branch-list",class:[_vm.$style.branches, ( _obj = {}, _obj[_vm.$style.noHandler] = _vm.branches.length <= 3, _obj )],staticStyle:{"height":"auto"},attrs:{"swipe-to-step":"","backdrop":false},on:{"sheet:stepopen":_vm.onSheetStepOpen,"sheet:stepclose":_vm.onSheetStepClose,"sheet:stepprogress":_vm.onSheetStepProgress}},[_c('div',{staticClass:"swipe-handler",class:_vm.$style.handler}),_c('div',{ref:"wrapper",class:[
      _vm.$style.wrapper,
      ( _obj$1 = {}, _obj$1[_vm.$style.scroll] = _vm.stepOpened, _obj$1 ) ]},[_c('div',{ref:"step",staticClass:"sheet-modal-swipe-step",class:[
        _vm.$style.step,
        ( _obj$2 = {}, _obj$2[_vm.$style.singleOption] = _vm.branches.length === 1, _obj$2 ) ]},[_c('BranchList',{class:[_vm.$style.first, ( _obj$3 = {}, _obj$3[_vm.$style.fade] = _vm.branches.length > 3 && !_vm.stepProgress, _obj$3 )],attrs:{"no-title":"","no-desc":"","limit":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var _obj;

      var branch = ref.branch;
      var workingTime = ref.workingTime;
      var distance = ref.distance;
      var formattedDistance = ref.formattedDistance;return [_c('div',{class:_vm.$style.branch,on:{"click":function($event){return _vm.onBranchClick(branch)}}},[_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(branch.name))]),_c('div',{class:[_vm.$style.workingTime, ( _obj = {}, _obj[_vm.$style.closed] = !workingTime.opened, _obj )]},[(_vm.workingTimeFormatted(workingTime, branch))?[_vm._v(_vm._s(_vm.workingTimeFormatted(workingTime, branch)))]:[_vm._v(" ")],(distance)?_c('span',{class:_vm.$style.distance},[_vm._v(" · "+_vm._s(formattedDistance))]):_vm._e()],2)])]}}])})],1),_c('BranchList',{attrs:{"no-title":"","no-desc":"","from":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var _obj;

      var branch = ref.branch;
      var workingTime = ref.workingTime;
      var distance = ref.distance;
      var formattedDistance = ref.formattedDistance;return [_c('div',{class:_vm.$style.branch,on:{"click":function($event){return _vm.onBranchClick(branch)}}},[_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(branch.name))]),_c('div',{class:[_vm.$style.workingTime, ( _obj = {}, _obj[_vm.$style.closed] = !workingTime.opened, _obj )]},[(_vm.workingTimeFormatted(workingTime, branch))?[_vm._v(_vm._s(_vm.workingTimeFormatted(workingTime, branch)))]:[_vm._v(" ")],(distance)?_c('span',{class:_vm.$style.distance},[_vm._v(" · "+_vm._s(formattedDistance))]):_vm._e()],2)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }