
import { defineComponent } from 'vue';
import { CreateElement } from 'vue';
import { f7 } from 'framework7-vue';

import i18n from '@/translate/lang';
import config from '../auth.conf.json';
import { appStoreMapper } from 'src/store/appstore';
import { authStoreMapper } from '../store';

export default defineComponent({
  name: 'AuthMenuItems',
  computed: {
    ...appStoreMapper.mapGetters(['userNameShort']),
    ...authStoreMapper.mapGetters(['isAuthorized', 'settings']),
  },
  render(this: any, h: CreateElement) {
    return this.isAuthorized && !this.userNameShort
      ? h(
          'f7-list-item',
          {
            slot: 'list',
            props: {
              title: this.settings.title || i18n.t(`modules.auth.title`).toString(),
            },
            on: {
              click: () => {
                f7.panel.close();

                this.$nextTick(() => {
                  if (this.isAuthorized) {
                    if (this.settings.tab) {
                      f7.tab.show(`#${config.tabName}`);
                    } else {
                      f7.views.current.router.navigate('/profile');
                    }
                  } else {
                    f7.views.current.router.navigate('/profile');
                  }
                });
              },
            },
          },
          [
            h('f7-icon', {
              props: {
                icon: 'far fa-user',
              },
              slot: 'media',
            }),
          ],
        )
      : h(undefined);
  },
});
