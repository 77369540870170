
import { defineComponent } from 'vue';
import { CreateElement } from 'vue';
import { f7 } from 'framework7-vue';

import i18n from '@/translate/lang';
import config from '../actions.conf.json';
import { actionsStoreMapper } from '../store';

export default defineComponent({
  name: 'ActionsMenuItem',
  render(this: any, h: CreateElement) {
    return h(
      'f7-list-item',
      {
        slot: 'list',
        props: {
          title: this.settings?.title || i18n.t(`modules.${config.name}.title`),
        },
        on: {
          click: () => {
            f7.panel.close();
            f7.tab.show(`#${config.tabName}`);
          },
        },
      },
      [
        h('div', {
          class: this.$style.icon,
          slot: 'media',
        }),
      ],
    );
  },
  computed: {
    ...actionsStoreMapper.mapGetters(['settings']),
  },
});
