

















import Vue from 'vue';

import bus from '@/bus';
import { catalogStoreMapper } from '../store';

export default Vue.extend({
  name: 'DeliveryBlocker',
  mounted() {
    bus.$on('delivery:block', () => {
      this.$f7.sheet.open('.delivery-blocker-sheet');
    });
  },
  computed: {
    ...catalogStoreMapper.mapGetters(['receiveAvailable']),
  },
  methods: {
    onClick() {
      bus.$emit('delivery:open');
    },
  },
});
