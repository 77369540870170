import { createComposable, createMapper, Module } from 'vuex-smart-module';

import PagesActions from './actions';
import PagesGetters from './getters';
import PagesMutations from './mutations';
import PagesState from './state';

const module = new Module({
  actions: PagesActions,
  getters: PagesGetters,
  mutations: PagesMutations,
  namespaced: true,
  state: PagesState,
});

export default module;

export const pagesStoreMapper = createMapper(module);
export const usePagesStore = createComposable(module);
