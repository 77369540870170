




import Vue from 'vue';
import { f7 } from 'framework7-vue';

import config from '@/app.config.js';

export default Vue.extend({
  name: 'SettingsPanelmenuIcon',
  computed: {
    showSettings(): boolean {
      return config.lang.list.length > 1;
    },
  },
  methods: {
    goToSettings() {
      f7.panel.close();
      f7.views.current.router.navigate('/settings/');
    },
  },
});
