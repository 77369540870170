import { createComposable, createMapper, Module } from 'vuex-smart-module';

import GetWalletState from './state';
import GetWalletGetters from './getters';

const module = new Module({
  getters: GetWalletGetters,
  namespaced: true,
  state: GetWalletState,
});

export default module;

export const getWalletStoreMapper = createMapper(module);
export const useGetWalletStore = createComposable(module);
