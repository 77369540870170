import { Context } from 'vuex-smart-module';
import { f7 } from 'framework7-vue';

import bus from 'src/bus';
import i18n from 'src/translate/lang';
import { showErrorDialog } from 'src/misc';
import AppStore from '@/store/appstore';
import RefStore from './store';

export const showCodeDialog = (
  appStore: Context<typeof AppStore>,
  refStore: Context<typeof RefStore>,
  callbacks?: any,
) => {
  f7.panel.close();

  const dialog = f7.dialog.prompt(
    i18n.t('modules.referral.code.dialogText') as string,
    i18n.t('modules.referral.code.promocode') as string,
    async (value: string) => {
      f7.preloader.show();

      try {
        await refStore.dispatch('activateCode', { code: value });
        await appStore.dispatch('getAppData', bus);

        bus.$emit('getMessages', { tries: 10 * 6 });

        bus.$emit('promoDidEnter');
      } catch (error) {
        showErrorDialog({ error, callbacks });
      } finally {
        f7.preloader.hide();
      }
    },
  );

  setTimeout(() => {
    const input = dialog?.$el[0].querySelector('.dialog-input') as HTMLInputElement;
    const ok = dialog?.$el[0].querySelector(
      '.dialog-button:last-child',
    ) as HTMLInputElement;

    if (ok) {
      ok.innerHTML = i18n.t('modules.referral.code.actions.activate') as string;
    }

    if (input) {
      input.focus();
      input.setAttribute(
        'placeholder',
        i18n.t('modules.referral.code.promocode') as string,
      );
      input.setSelectionRange(input.value.length, input.value.length);
    }
  }, 100);
};
