import { SetupContext, ref, watch } from 'vue';
import useVuelidate from '@vuelidate/core';

export default function (props: any, context: SetupContext) {
  const field = ref(props.value);
  const fieldName = props.name;
  const errorText = ref('');

  let v$ = useVuelidate(
    {
      [fieldName]: props.validation,
    },
    { [fieldName]: field },
  ).value;

  const setValue = ($event: InputEvent) => {
    field.value = ($event.target as HTMLInputElement).value.trim();
  };

  function emitState() {
    context.emit('state', {
      key: fieldName,
      value: field.value,
      v$,
    });
  }

  watch(
    () => props.required,
    () => {
      v$ = useVuelidate(
        {
          [fieldName]: props.validation,
        },
        { [fieldName]: field },
      ).value;

      emitState();
    },
  );

  watch(
    () => props.value,
    (value: any) => {
      field.value = value;
    },
    { immediate: true },
  );

  watch(
    () => field.value,
    () => {
      // @ts-ignore
      errorText.value = v$[fieldName]?.$errors[0]?.$message;

      context.emit('input', field.value);

      emitState();
    },
    { immediate: true },
  );

  watch(
    () => v$[fieldName].$dirty,
    () => {
      // @ts-ignore
      errorText.value = v$[fieldName]?.$errors[0]?.$message;
    },
  );

  return { errorText, emitState, field, fieldName, setValue, v$ };
}
