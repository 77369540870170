





















































































































































import Vue, { defineComponent, PropType } from 'vue';
import { f7 } from 'framework7-vue';
import { IMaskComponent } from 'vue-imask';

import { IUser } from '@/types';
import { IDropPasswordResponse, IRegistrationRequest } from '../types';

import bus from '@/bus';
import config from '../auth.conf.json';
import i18n from '@/translate/lang';
import { showErrorDialog } from '@/misc';
import { authStoreMapper } from '../store';

export default defineComponent({
  components: { IMaskComponent },
  props: {
    phone: {
      type: String as PropType<string>,
      required: true,
    },
    regData: Object as PropType<Nullable<IRegistrationRequest>>,
    fromRegistration: Boolean as PropType<boolean>,
    fromProfile: Boolean as PropType<boolean>,
    openProfile: Boolean as PropType<boolean>,
  },
  data: () => ({
    pin: '',
    error: '',
    remindPasswordDelay: 45,
    remindPasswordTimer: 0,
    remindPasswordTimerInterval: null as any,
    nextAttemptSMSActivated: false,
    nextAttemptSMS: false,
    success: false,
    pageShown: false,
    pincode: [] as number[],
    pincodeNew: [] as number[],
    pincodeForgotten: false,
    checkCode: '',
    checkSucceed: false,
    debugCode: '',
  }),
  created() {
    if (this.regData) {
      this.checkSucceed = true;
    }

    if (this.fromProfile) {
      this.checkSucceed = true;
    }
  },
  mounted() {
    if (this.settings.smsTimeout != null) {
      this.remindPasswordDelay = this.settings.smsTimeout;
    }

    if (this.resetPasswordDelay) {
      const delay =
        this.remindPasswordDelay -
        Math.floor((+new Date() - +this.resetPasswordDelay) / 1000);
      this.remindPasswordTimer = delay < this.remindPasswordDelay ? delay : 0;
    }
  },
  computed: {
    pseudoArray(): number[] {
      const array: number[] = [];
      for (let i = 0; i < this.pinLength; i++) {
        array.push(i);
      }
      return array;
    },
    pincodeLength(): number {
      return this.settings.pincodeLength || 5;
    },
    pinLength(): number {
      return this.settings.pinLength || 6;
    },
    checkCodeMask() {
      let mask = '';

      for (let i = 0; i < this.pincodeLength; i++) {
        mask += '0';
      }

      return mask;
    },
    checkCodeFormatted(): string {
      return this.checkCode.replace(/\D/g, '');
    },
    displayName(): string {
      return this.user?.fName || this.user?.lName || this.user?.display_name || '';
    },
    ...authStoreMapper.mapGetters(['settings', 'user']),
    ...authStoreMapper.mapState([
      'resetPasswordDelay',
      'afterLoginCallback',
      'afterRegisterCallback',
    ]),
  },
  methods: {
    focusInput() {
      const input = (this.$refs.pincode as unknown as Vue).$el as HTMLInputElement;

      input.focus();

      setTimeout(() => {
        input.setSelectionRange(0, 0);
      }, 100);
    },
    resetPassword(ask?: boolean) {
      if (this.remindPasswordTimer > 0) {
        return showErrorDialog.call(this, {
          error: {
            message: i18n.t('modules.auth.droppass.delay'),
          },
        });
      }

      const dropPassword = () => {
        f7.preloader.show();

        this.dropPassword({ username: this.phone })
          .then((response) => {
            f7.dialog.alert(
              i18n.t('modules.auth.droppin.done') as string,
              i18n.t('modules.auth.droppin.title') as string,
              () => {
                setTimeout(() => {
                  this.focusInput();
                }, 100);
              },
            );

            if ((response as IDropPasswordResponse).debug) {
              this.debugCode = (response as IDropPasswordResponse).debug?.code || '';
            }

            this.pincodeForgotten = true;
          })
          .catch((error) => {
            showErrorDialog.call(this, { error });
          })
          .finally(() => {
            this.setResetPasswordDelay(new Date());
            f7.preloader.hide();
          });
      };

      if (ask) {
        f7.dialog.confirm(
          i18n.t('modules.auth.droppin.confirm').toString(),
          i18n.t('modules.auth.droppin.title').toString(),
          dropPassword,
        );
      } else {
        dropPassword();
      }
    },
    back() {
      if (this.fromRegistration) {
        f7.views.current.router.back(f7.views.current.router.history[0], {
          force: true,
        });
      } else {
        f7.views.current.router.back();
      }
    },
    push(digit: number): void {
      if (this.pincode.length < this.pinLength) {
        this.pincode.push(digit);
        this.error = '';
      }
    },
    popLastDigit(): void {
      if (this.error) {
        this.error = '';
      }

      if (this.pincode.length > 0 && this.pincode.length < this.pinLength) {
        this.pincode.pop();
      }
    },
    signIn() {
      if (this.pincode.length < this.pinLength) {
        this.error = i18n.t('modules.auth.signin.badpincode').toString();
        return;
      }

      this.$refs.pincode &&
        ((this.$refs.pincode as unknown as Vue).$el as HTMLInputElement).blur();

      f7.preloader.show();

      this.auth({ username: this.phone, password: this.pincode.join('') })
        .then((user) => {
          this.pincode = [];
          this.checkCode = '';

          this.success = true;

          if (document.body.querySelector('.auth-popup')) {
            f7.popup.close('.auth-popup');
          } else {
            setTimeout(
              () => {
                if (this.openProfile) {
                  if (this.settings.tab) {
                    f7.views.current.router.back(f7.views.current.router.history[0], {
                      force: true,
                      animate: false,
                    });

                    f7.tab.show(`#${config.tabName}`);
                  } else {
                    f7.views.current.router.navigate('/profile/');
                  }
                } else {
                  f7.views.current.router.back(f7.views.current.router.history[0], {
                    force: true,
                  });
                }

                if (this.fromRegistration) {
                  bus.$emit('getMessages', { interval: 1500, tries: 15 });
                }
              },
              this.checkSucceed ? 0 : this.settings.successTimeout ?? 1500,
            );
          }

          bus.$emit('authAfterLoginSuccess');

          if (this.afterLoginCallback) {
            this.afterLoginCallback(user as IUser);
            this.setAfterRegisterCallback(null);
            this.setAfterLoginCallback(null);
          }
        })
        .catch((error) => {
          showErrorDialog.call(this, {
            error,
          });

          this.pincode = [];
          this.checkCode = '';

          bus.$emit('authAfterLoginFailed');
        })
        .finally(() => {
          f7.preloader.hide();

          bus.$emit('authAfterLoginFinally');
        });
    },
    checkIn() {
      if (this.checkCodeFormatted.length < this.pincodeLength) {
        this.error = i18n.t('modules.auth.signin.badpincode').toString();
        return;
      }

      this.$refs.pincode &&
        ((this.$refs.pincode as unknown as Vue).$el as HTMLInputElement).blur();

      f7.preloader.show();

      this.auth({ username: this.phone, password: this.checkCodeFormatted })
        .then(() => {
          this.pincodeForgotten = false;
          this.checkSucceed = true;
          this.pincode = [];
        })
        .catch((error) => {
          showErrorDialog.call(this, {
            error,
            callbacks: {
              onClose: () => {
                this.focusInput();
              },
            },
          });

          this.pincode = [];
          this.checkCode = '';
        })
        .finally(() => {
          f7.preloader.hide();
        });
    },
    updatePin() {
      f7.preloader.show();

      const params: IRegistrationRequest = {
        password: this.pincode.join(''),
      };

      this.updateUser(params)
        .then((user) => {
          if (document.body.querySelector('.auth-popup')) {
            f7.popup.close('.auth-popup');
          } else {
            setTimeout(
              () => {
                if (this.openProfile) {
                  if (this.settings.tab) {
                    f7.views.current.router.back(f7.views.current.router.history[0], {
                      force: true,
                      animate: false,
                    });

                    f7.tab.show(`#${config.tabName}`);
                  } else {
                    f7.views.current.router.navigate('/profile/');
                  }
                } else if (this.fromProfile) {
                  f7.views.current.router.back();
                } else {
                  f7.views.current.router.back(f7.views.current.router.history[0], {
                    force: true,
                  });
                }

                if (this.fromRegistration) {
                  bus.$emit('getMessages', { interval: 1500, tries: 15 });
                }
              },
              this.checkSucceed ? 0 : this.settings.successTimeout ?? 1500,
            );
          }

          bus.$emit('authAfterLoginSuccess');

          if (this.afterLoginCallback) {
            this.afterLoginCallback(user as IUser);
            this.setAfterRegisterCallback(null);
            this.setAfterLoginCallback(null);
          }
        })
        .catch((error) => {
          showErrorDialog.call(this, {
            error,
          });

          this.pincode = [];
          this.pincodeNew = [];
        })
        .finally(() => {
          f7.preloader.hide();
        });
    },
    register() {
      f7.preloader.show();

      const params: IRegistrationRequest = this.regData!;
      params.password = this.pincode.join('');

      this.registerUser(params)
        .then((user) => {
          this.success = true;

          if (document.body.querySelector('.auth-popup')) {
            f7.popup.close('.auth-popup');
            bus.$emit('getMessages', { tries: 10 * 6 });
          } else {
            setTimeout(() => {
              bus.$emit('getMessages', { tries: 10 * 6 });

              f7.views.current.router.back(f7.views.current.router.history[0], {
                force: true,
              });
            }, this.settings.successTimeout ?? 1500);
          }

          bus.$emit('authAfterRegisterSuccess');

          if (this.afterRegisterCallback) {
            this.afterRegisterCallback(user as IUser);
            this.setAfterRegisterCallback(null);
            this.setAfterLoginCallback(null);
          }
        })
        .catch((error) => {
          showErrorDialog({ error });
        })
        .finally(() => {
          f7.preloader.hide();
        });
    },
    ...authStoreMapper.mapActions(['auth', 'dropPassword', 'updateUser', 'registerUser']),
    ...authStoreMapper.mapMutations([
      'setResetPasswordDelay',
      'setAfterLoginCallback',
      'setAfterRegisterCallback',
    ]),
  },
  watch: {
    pincode() {
      if (this.pincode) {
        this.error = '';
      }

      if (this.pincode.length >= this.pinLength) {
        if (this.checkSucceed) {
          if (this.pincodeNew.length === 0) {
            this.pincodeNew = this.pincode;
            this.pincode = [];
          } else if (this.pincode.join('') !== this.pincodeNew.join('')) {
            f7.dialog.alert(
              this.$t('modules.auth.pin.error.repeat.text').toString(),
              this.$t('modules.auth.pin.error.repeat.title').toString(),
              () => {
                this.pincode = [];
                this.pincodeNew = [];
              },
            );
          } else {
            if (this.regData) {
              this.register();
            } else {
              this.updatePin();
            }
          }
        } else {
          this.signIn();
        }
      }
    },
    checkCode() {
      if (this.checkCodeFormatted) {
        this.error = '';
      }

      if (this.checkCodeFormatted.length >= this.pincodeLength) {
        this.checkIn();
      }
    },
    resetPasswordDelay() {
      this.remindPasswordTimer = this.remindPasswordDelay;
    },
    remindPasswordTimer() {
      if (!this.remindPasswordTimerInterval && this.remindPasswordTimer > 0) {
        this.remindPasswordTimerInterval = setInterval(() => {
          if (this.remindPasswordTimer <= 0) {
            this.remindPasswordTimer = 0;
            clearInterval(this.remindPasswordTimerInterval);
            this.remindPasswordTimerInterval = null;
          } else {
            this.remindPasswordTimer--;
          }
        }, 1000);
      }
    },
  },
});
