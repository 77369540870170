var render, staticRenderFns
import script from "./NotificationsMenuItem.vue?vue&type=script&lang=ts&"
export * from "./NotificationsMenuItem.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationsMenuItem.vue?vue&type=style&index=0&id=29cfded1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.8_wzvh6gi4tchptuexh7cjhim74e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29cfded1",
  null
  
)

export default component.exports