export const LOCALSTORAGE_APPDATA = 'app-data';
export const LOCALSTORAGE_DEVICE = 'idDevice';
export const LOCALSTORAGE_TOKEN = 'authToken';
export const LOCALSTORAGE_SECONDRUN = 'secondRun';
export const LOCALSTORAGE_DOMAIN = 'domain';
export const LOCALSTORAGE_DOMAIN_FIRST = 'domainFirst';
export const LOCALSTORAGE_DOMAIN_TOKENS = 'domainTokens';
export const LOCALSTORAGE_RECORDSESSION = 'recordSession';
export const LOCALSTORAGE_STAT_QUEUE = 'statQueue';
export const LOCALSTORAGE_NEED_UPDATE_TIMESTAMP = 'needUpdate';
export const LOCALSTORAGE_PWA_DEBUG = 'pwaDebug';
export const LOCALSTORAGE_LOCALE = 'locale';
export const LOCALSTORAGE_HOST = 'client';
export const LOCALSTORAGE_UUID = 'uuid';
export const LOCALSTORAGE_PUSHTOKEN = 'pushToken';
export const LOCALSTORAGE_VERBOSE = 'verbose';

export const API_ENDPOINT_APPDATA = 'app';
export const API_ENDPOINT_STAT = 'stat';
export const API_GEOCODER_SUGGEST = 'geocoder/suggest';
export const API_GEOCODER_SUGGEST_ADDRESS = 'geocoder/get-address';
