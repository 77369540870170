import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';

import { OSNotification } from 'onesignal-cordova-plugin';
import User from 'onesignal-cordova-plugin/dist/UserNamespace';
import { Tags } from '../types/onesignal';

import * as OS from '../utils';

import AppStore from '@/store/appstore';
import NotificationsState from './state';
import NotificationsGetters from './getters';
import NotificationsMutations from './mutations';

export default class NotificationsActions extends Actions<
  NotificationsState,
  NotificationsGetters,
  NotificationsMutations,
  NotificationsActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  setTag({
    key,
    value,
    user,
  }: {
    key: string;
    value: string;
    user: User;
  }): Promise<Tags> {
    return OS.setTag(key, value, user).then((tagsSent) => {
      this.commit('setTag', tagsSent);

      return tagsSent;
    });
  }

  setTags({ tags, user }: { tags: Tags; user: User }): Promise<Tags> {
    return OS.setTags(tags, user).then((tagsSent) => {
      this.commit('setTags', tagsSent);

      return tagsSent;
    });
  }

  getTags(user: User): Promise<Tags> {
    return OS.getTags(user);
  }

  removeTags({ tags, user }: { tags: string[]; user: User }): Promise<void> {
    return OS.removeTags(tags, user);
  }

  addAlias({ label, id, user }: { label: string; id: string; user: User }) {
    OS.addAlias(label, id, user);
  }

  removeAlias({ label, user }: { label: string; user: User }): void {
    OS.removeAlias(label, user);
  }

  notificationOpened(notification: OSNotification) {
    console.log('notificationOpened', notification);
  }

  foregroundWillDisplay(notification: OSNotification) {
    console.log('notificationReceived', notification);
  }
}
