






























































































































import { PropType } from 'vue';
import Vue from 'vue';
import { f7 } from 'framework7-vue';
import QRCode from 'qrcode-svg-ts';

import { ReferralType, RewardType } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { refStoreMapper } from '../store';

import BonusIcon from './bonus-amount.vue';

export default Vue.extend({
  name: 'ReferralSlot',
  components: { BonusIcon },
  props: {
    sheet: Boolean as PropType<boolean>,
  },
  data: () => ({
    qr: '',
  }),
  created() {
    this.generateQrCode();
  },
  computed: {
    referralType(): ReferralType {
      return this.settings.type;
    },
    referrerReward(): RewardType {
      return this.settings.refererReward;
    },
    allOrders(): boolean {
      return this.referralType === ReferralType.all;
    },
    firstOrder(): boolean {
      return this.referralType === ReferralType.first;
    },
    rewardFix(): boolean {
      return this.referrerReward === RewardType.fix;
    },
    rewardFlex(): boolean {
      return this.referrerReward === RewardType.flex;
    },
    expireText(): string {
      return this.$t('modules.referral.refer.expire', [
        this.settings.regDay,
        this.$tc('global.days.plural2', this.settings.regDay).toString(),
      ]).toString();
    },
    referralCode(): string {
      return (
        this.$t('modules.referral.refer.subheader') + ' ' + this.referral?.code ?? '—'
      );
    },
    ...appStoreMapper.mapGetters(['isPWA', 'user', 'appSettings']),
    ...refStoreMapper.mapGetters(['referral', 'settings']),
  },
  methods: {
    generateQrCode() {
      this.qr = new QRCode({
        content: this.appSettings.applink,
        padding: 2,
        width: (window.innerWidth * 2) / 3,
        height: (window.innerWidth * 2) / 3,
        color: '#000000',
        background: '#ffffff',
        ecl: 'L',
        container: 'svg-viewbox',
        join: true,
      }).svg();
    },
    onShareClick() {
      const text = this.settings.message
        ?.replace('{code}', this.referral?.code?.toString() || '0')
        .replace('{applink}', this.appSettings.applink);

      if (this.isPWA && navigator.share) {
        navigator
          .share({
            text,
          })
          .then(() => console.log('Удалось поделиться'))
          .catch((error) => console.log('Не удалось поделиться', error));
      } else if (!this.isPWA) {
        window.plugins?.socialsharing?.share(text);
      }

      if (this.sheet) {
        f7.sheet.close();
      }
    },
  },
});
