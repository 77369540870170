import { Actions } from 'vuex-smart-module';

import * as API from '@/api';
import * as misc from '@/misc';

import AppState from '@/store/app/state';
import AppGetters from '@/store/app/getters';
import AppMutations from '@/store/app/mutations';

export default class AppActions extends Actions<
  AppState,
  AppGetters,
  AppMutations,
  AppActions
> {
  async getAppData(bus: Vue) {
    if (this.state.appDataPromise) {
      return this.state.appDataPromise;
    }

    const promise = new Promise((resolve, reject) => {
      this.commit('setAppDataLoading', true);

      API.getAppData()
        .then((response) => {
          this.commit('setAppDataDidLoad', true);
          this.commit('setAppDataDidFail', false);
          this.commit('setAppData', response);
          this.commit('setAppDataLoading', false);

          // Нужно для того, чтобы после первичной инициализации эти
          // события сработали в модулях, которые первый раз активируются
          setTimeout(() => {
            bus.$emit('appDataDidLoad');
            bus.$emit('appDataDidLoadFromServer');
          }, 100);

          resolve(response);
        })
        .catch((error: any) => {
          this.commit('setAppDataDidFail', true);
          this.commit('setAppDataLoading', false);

          bus.$emit('appDataDidFail');

          reject(error);
        });
    }).finally(() => {
      this.commit('setAppDataLoading', false);
      this.commit('setAppDataPromise', null);
    });

    this.commit('setAppDataPromise', promise);

    return promise;
  }

  login(value: string) {
    this.commit('setAuthToken', value);
  }

  logout() {
    this.commit('setAuthToken', '');
    this.commit('setDomainTokens', {});
  }

  switchDomain({ domain, authToken }: { domain: relatedDomain; authToken?: string }) {
    this.commit('setDomain', domain);

    if (this.state.domainTokens[domain] || authToken) {
      this.commit('setIdDevice', 0);
      this.commit('setAuthToken', authToken || this.state.domainTokens[domain]);
      this.commit('setRegDeviceDidFail', false);
    } else {
      this.commit('setAuthToken', '');
    }
  }

  async getCurrentCoordinates({ silent }: { silent?: boolean } = {}): Promise<
    Nullable<Coords>
  > {
    return misc.getCurrentPosition({ silent }).then((coords) => {
      this.commit('setCurrentCoordinates', coords || { lat: 0, lng: 0 });
      return coords;
    });
  }

  changeLocale(value: string): void {
    this.commit('setLocale', misc.setLocale(value));
  }
}
