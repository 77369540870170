import * as API from 'src/api';
import {
  AuthResponse,
  CheckUserResponse,
  DeleteUserResponse,
  GetCodeResponse,
  ICheckUserRequest,
  IDropPasswordRequest,
  IDropPasswordResponse,
  IGetCodeRequest,
  IReceiptSavingResponse,
  IRegisterDeviceRequest,
  IRegistrationRequest,
  ISetReceiptSavingRequest,
  RegisterDeviceResponse,
  RegisterUserResponse,
} from '../types';

const API_ENDPOINT_LOGIN = 'user-auth';
const API_ENDPOINT_REGDEVICE = 'device-register';
const API_ENDPOINT_RESETPASSWORD = 'reset-password';
const API_ENDPOINT_REGUSER = 'user-register';
const API_ENDPOINT_UPDATEUSER = 'user-update';
const API_ENDPOINT_GETCODE = 'user-send-sms';
const API_ENDPOINT_CHECKUSER = 'check-if-user-exists';
const API_ENDPOINT_DELETE = 'user-delete';
const API_ENDPOINT_SETRECEIPT_SAVING = 'set-receipt-saving';
const API_ENDPOINT_GETRECEIPT_SAVING = 'get-receipt-saving';

export async function auth({
  store,
  username,
  password,
  authToken,
}: {
  store: any;
  username: string;
  password: string;
  authToken: string;
}): Promise<AuthResponse> {
  return API.request<AuthResponse>({
    data: { username, password, authToken },
    method: 'post',
    url: API_ENDPOINT_LOGIN,
  });
}

export function checkUserExists(data: ICheckUserRequest): Promise<CheckUserResponse> {
  return API.request<CheckUserResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_CHECKUSER,
  });
}

export function dropPassword(data: IDropPasswordRequest): Promise<IDropPasswordResponse> {
  return API.request<IDropPasswordResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_RESETPASSWORD,
  });
}

export function getCode(data: IGetCodeRequest): Promise<GetCodeResponse> {
  return API.request<GetCodeResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_GETCODE,
  });
}

export async function registerDevice(
  data: IRegisterDeviceRequest,
): Promise<RegisterDeviceResponse> {
  return API.request<RegisterDeviceResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_REGDEVICE,
  });
}

export async function registerUser(
  data: IRegistrationRequest,
): Promise<RegisterUserResponse> {
  return API.request<RegisterUserResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_REGUSER,
  });
}

export async function updateUser(
  data: IRegistrationRequest,
): Promise<RegisterUserResponse> {
  return API.request<RegisterUserResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_UPDATEUSER,
  });
}

export async function deleteUser(): Promise<DeleteUserResponse> {
  return API.request<DeleteUserResponse>({
    method: 'post',
    url: API_ENDPOINT_DELETE,
  });
}

export async function getReceiptSaving(): Promise<IReceiptSavingResponse> {
  return API.request<IReceiptSavingResponse>({
    method: 'get',
    url: API_ENDPOINT_GETRECEIPT_SAVING,
  });
}

export async function setReceiptSaving(
  data: ISetReceiptSavingRequest,
): Promise<IReceiptSavingResponse> {
  return API.request<IReceiptSavingResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_SETRECEIPT_SAVING,
  });
}
