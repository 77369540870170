export enum CodeType {
  qr = 'qr',
  text = 'text',
}

export interface IModuleSettings {
  name?: string;
  needRegister?: boolean;
  gmbLink?: string;
  hideLogo?: boolean;
  fullscreenLogo?: boolean;
  fullscreenLogoParallax?: boolean;
  hideTitleWhenFullscreenLogo?: boolean;
  hideTitle?: boolean;
  smallLogo?: boolean;
  defaultTab?: string;
  splashHideTimeout?: number;
  codeType?: CodeType;
  hideMenu?: boolean;
  hideMenuMiniapp?: boolean;
  updateInterval?: number;
  statDisabled?: boolean;
  catalogItemShowLabels?: boolean;
  catalogItemShowButtons?: boolean;
  catalogItemTitleOverImage?: boolean;
  catalogItemCropTitle?: boolean;
  catalogItemLinesMin?: number;
  catalogItemLinesMax?: number;
  birthdayUseSystemDatePicker?: boolean;
  datesUseSystemDatePicker?: boolean;
  hideTabbarLabels?: boolean;
  decimalRoundingRule?: decimalRoundingRule;
  decimal?: number;
  currency: ICurrencyI18n;
  sourceTimeZone?: number;
  displayTimeZone?: number;
  useClientTimeZone?: boolean;
  deviceWidthImageDivideAmount?: number;
  deviceWidthMaxPixelRatio?: number;
  deviceWidthIgnorePixelRatio?: boolean;
  version?: {
    ios: string;
    android: string;
  };
  versionUpdateRequired?: boolean;
  versionUpdateTitle?: string;
  versionUpdateText?: string;
  versionUpdateLinkAppStore?: string;
  versionUpdateLinkGogglePlay?: string;
  versionUpdateBlockPWA?: boolean;
  applink: string;
}
