




import Vue from 'vue';
import { f7 } from 'framework7-vue';

import { OSNotification } from 'onesignal-cordova-plugin';
import { idNews } from '../types';

import config from '../news.conf.json';
import bus from 'src/bus';

import NewsPopup from './news-popup.vue';

export default Vue.extend({
  components: {
    NewsPopup,
  },
  data: () => ({
    id: null as Nullable<number>,
    popupOpened: false,
  }),
  destroyed() {
    bus.$off('news-push', this.handlePush);
    bus.$off('news-list', this.openActionsList);
  },
  mounted() {
    bus.$on('news-push', this.handlePush);
    bus.$on('news-list', this.openActionsList);
  },
  methods: {
    async handlePush(notification: OSNotification) {
      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;
      this.id = additionalData?.idConnectedEntity as unknown as idNews;
      this.popupOpened = !!this.id;
    },
    openActionsList() {
      f7.tab.show(`#${config.tabName}`);
    },
  },
});
