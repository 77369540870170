








import Vue from 'vue';

import bus from '@/bus';

export default Vue.extend({
  name: 'DebugPanelmenuIcon',
  methods: {
    openDebugPopup() {
      bus.$emit('showDebugPopup');
    },
  },
});
