import { maxLength, createI18nMessage } from '@vuelidate/validators';

import i18n from '@/translate/lang';

function translationFunction(
  validator: string,
  $params: { [key: string]: string | number | boolean },
) {
  return i18n.t('global.validators.max.error', [$params.max]).toString();
}

const withI18nMessage = createI18nMessage({ t: translationFunction });

export default withI18nMessage(maxLength, { withArguments: true });
