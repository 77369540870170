


















import Vue from 'vue';

import { historyStoreMapper } from '@/modules/history/dist/store';

import HistoryListItem from './HistoryListItem.vue';

export default Vue.extend({
  name: 'HistoryList',
  components: {
    HistoryListItem,
  },
  computed: {
    ...historyStoreMapper.mapState(['history', 'loadingHistory']),
  },
});
