import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import AppStore from '@/store/appstore';
import * as API from '@/modules/history/dist/api';

import { IGetHistoryRequest, IHistoryItem } from '../types';

import HistoryState from './state';
import HistoryGetters from './getters';
import HistoryMutations from './mutations';

export default class HistoryActions extends Actions<
  HistoryState,
  HistoryGetters,
  HistoryMutations,
  HistoryActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  async resetAll() {
    this.commit('reset', true);
  }

  async getHistory({
    from,
    limit,
    reset,
  }: {
    from?: boolean;
    limit: number;
    reset?: boolean;
  }): Promise<IHistoryItem[]> {
    let promise = null;

    if (from) {
      if (this.state.loadingHistoryAfterPromise) {
        return this.state.loadingHistoryAfterPromise;
      }

      const params: IGetHistoryRequest = {
        limit,
        offset: this.state.history.length,
      };

      this.commit('setLoadingHistoryAfter', true);

      promise = API.getHistory(params)
        .then((operations) => {
          this.commit('pushHistory', operations);
          return operations;
        })
        .finally(() => {
          this.commit('setAfterPromise', null);
          this.commit('setLoadingHistoryAfter', false);
        });

      this.commit('setAfterPromise', promise);
    } else {
      if (this.state.loadingHistoryPromise) {
        return this.state.loadingHistoryPromise;
      }

      if (reset) {
        this.commit('setHistory', []);
      }

      this.commit('setLoadingHistory', true);

      promise = API.getHistory({ limit })
        .then((operations) => {
          this.commit('setHistory', operations);
          return operations;
        })
        .finally(() => {
          this.commit('setPromise', null);
          this.commit('setLoadingHistory', false);
        });

      this.commit('setPromise', promise);
    }

    return promise;
  }
}
