import { createComposable, createMapper, Module } from 'vuex-smart-module';

import RelatedState from './state';
import RelatedGetters from './getters';

const module = new Module({
  getters: RelatedGetters,
  namespaced: true,
  state: RelatedState,
});

export default module;

export const relatedStoreMapper = createMapper(module);
export const useRelatedStore = createComposable(module);
