


















































































































































































import {
  IAdditionalActionPayment,
  IPaymentType,
  ISendOrderRequest,
  ReceiveType,
} from '../types';

import { defineComponent } from 'vue';
import { f7, theme } from 'framework7-vue';

import config from 'src/app.config.js';
import bus from '@/bus';
import i18n from '@/translate/lang';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';
import validationState from 'src/validators/validation-state';
import { getModule, openUrl, rgbToHex, showErrorDialog } from '@/misc';
import { buildGeocoderValue, getPickupIntervals } from '@/utils';
import { ISuggestion } from '../api';
import { checkAddressIsInDeliveryZone } from '../utils/checkAddressIsInDeliveryZone';
import * as API from '@/api';

import ListInputEmail from 'src/components/fields/list-input-email.vue';
import ListInputName from 'src/components/fields/list-input-name.vue';
import ListInputText from 'src/components/fields/list-input-text.vue';
import ListInputTextarea from 'src/components/fields/list-input-textarea.vue';
import ListInputUsername from 'src/components/fields/list-input-username.vue';
import ListInputSelect from 'src/components/fields/list-input-select.vue';
import Time from './time.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';
import MapBlock from '@/components/MapBlock.vue';

export default defineComponent({
  components: {
    MapBlock,
    CatalogBarWrapper,
    ListInputEmail,
    ListInputName,
    ListInputTextarea,
    ListInputText,
    ListInputUsername,
    ListInputSelect,
    Time,
  },
  setup() {
    return { ...validationState() };
  },
  data: () => ({
    ReceiveType,
    paymentTypeId: 0,
    selfOrder: true,
    maskedUsername: '',
    name: '',
    comment: '',
    email: '',
    buttonClicked: false,
    pickupTime: '',
    currentClientTime: new Date(),
    checkDeliveryZone: false,
    map: null as Nullable<any>,
    mapCenter: {
      lat: 0,
      lng: 0,
    } as IMapPosition,
    checkInZoneResolve: (value: boolean) => {},
  }),
  mounted() {
    this.paymentTypeId = this.settings.paymentTypeList?.[0]?.id || 0;

    const watchCurrentClientTime = setInterval(() => {
      this.currentClientTime = new Date();
    }, 60 * 1000);

    this.$once('hook:beforeDestroy', () => {
      clearInterval(watchCurrentClientTime);
    });
  },
  computed: {
    showPaymentTypes(): boolean {
      return !!this.totalProductsPrice && !!this.settings.paymentTypeList?.length;
    },
    orderButtonName(): string {
      const defaultButtonName = i18n.t('modules.catalog.form.action.send').toString();
      const orderButtonName = this.settings.paymentTypeList?.find(
        (payment) => payment.id === this.paymentTypeId,
      )?.orderButtonName;

      return orderButtonName || defaultButtonName;
    },
    paymentNeedEmail(): boolean {
      return !!this.settings.paymentTypeList?.find(
        (paymentType) => paymentType.id === this.paymentTypeId && paymentType.needEmail,
      );
    },
    pickupTimeOptions(): ListInputOption[] {
      let timeStart = this.deliveryTimeStart;
      const timeEnd = this.deliveryTimeEnd;

      if (timeStart && timeEnd) {
        const timeStartHours = parseInt(timeStart[0]);
        const timeStartMinutes = parseInt(timeStart[1]);
        const timeStartNumber = Number(timeStart.join(''));
        const timeEndHours = parseInt(timeEnd[0]);
        //const timeEndMinutes = parseInt(timeEnd[1]);
        const timeEndNumber = Number(timeEnd.join(''));
        const timeCurrentHours = this.currentClientTime.getHours();
        const timeCurrentMinutes = this.currentClientTime.getMinutes();
        const timeCurrentNumber = Number(
          timeCurrentHours.toString() + timeCurrentMinutes.toString().padStart(2, '0'),
        );

        const timeStep =
          (this.receive === ReceiveType.delivery &&
            this.settings.delivery &&
            this.settings.delivery.timeStep) ||
          (this.receive === ReceiveType.pickup &&
            this.settings.pickup &&
            this.settings.pickup.timeStep) ||
          15 * 60;
        const afterStartGap =
          (this.receive === ReceiveType.delivery &&
            this.settings.delivery &&
            this.settings.delivery.afterStartTimeGap) ||
          (this.receive === ReceiveType.pickup &&
            this.settings.pickup &&
            this.settings.pickup.afterStartTimeGap) ||
          0;
        const beforeEndGap =
          (this.receive === ReceiveType.delivery &&
            this.settings.delivery &&
            this.settings.delivery.beforeEndTimeGap) ||
          (this.receive === ReceiveType.pickup &&
            this.settings.pickup &&
            this.settings.pickup.beforeEndTimeGap) ||
          0;

        const intervalStartDate = new Date();
        intervalStartDate.setHours(timeStartHours);
        intervalStartDate.setMinutes(timeStartMinutes);
        intervalStartDate.setSeconds(0);

        const isEndTimeAfterMidnight = timeEndHours >= 0 && timeEndHours < timeStartHours;
        const isCurrentTimeAfterMidnight =
          timeCurrentHours >= 0 && timeCurrentHours < timeStartHours;

        if (!isEndTimeAfterMidnight) {
          if (timeCurrentNumber < timeStartNumber || timeCurrentNumber > timeEndNumber) {
            return [];
          }
        } else {
          if (!isCurrentTimeAfterMidnight) {
            if (timeCurrentNumber < timeStartNumber) {
              return [];
            }
          } else {
            if (timeCurrentNumber > timeEndNumber) {
              return [];
            }
          }
        }

        if (isEndTimeAfterMidnight && isCurrentTimeAfterMidnight) {
          intervalStartDate.setHours(0);
          intervalStartDate.setMinutes(0);
          intervalStartDate.setSeconds(intervalStartDate.getSeconds() - timeStep);
        }

        let firstValidIntervalFound = false;

        while (!firstValidIntervalFound) {
          intervalStartDate.setSeconds(intervalStartDate.getSeconds() + timeStep);
          firstValidIntervalFound = intervalStartDate >= this.currentClientTime;
        }

        timeStart = [
          intervalStartDate.getHours().toString(),
          intervalStartDate.getMinutes().toString().padStart(2, '0'),
          intervalStartDate.getSeconds().toString().padStart(2, '0'),
        ];

        const intervals = getPickupIntervals(timeStart.join(':'), timeEnd.join(':'), {
          step: timeStep,
          afterStart: afterStartGap,
          beforeEnd: beforeEndGap,
        });

        return intervals.map((interval) => ({
          name: interval,
          value: interval,
        }));
      }

      return [];
    },
    paymentsList(): IPaymentType[] {
      const payments: IPaymentType[] = [];

      this.settings.paymentTypeList?.forEach((payment) => {
        if (payment.dependDelivery === 'all') {
          payments.push(payment);
        } else {
          if (this.receive === 'pickup' && payment.dependDelivery === 'pickup') {
            payments.push(payment);
          } else if (
            this.receive === 'delivery' &&
            payment.dependDelivery === 'delivery'
          ) {
            payments.push(payment);
          }
        }
      });

      return payments;
    },
    ...appStoreMapper.mapGetters([
      'defaultTab',
      'miniappHideElements',
      'pickupPoints',
      'branches',
      'appSettings',
      'user',
      'userNameFull',
    ]),
    ...appStoreMapper.mapState(['currentCoords']),
    ...catalogStoreMapper.mapGetters([
      'settings',
      'deliveryTimeStart',
      'deliveryTimeEnd',
      'notActiveProductsList',
      'receiveAvailable',
      'totalProductsPrice',
      'checkZoneEnabled',
    ]),
    ...catalogStoreMapper.mapState([
      'basket',
      'paidByBonus',
      'pickupPoint',
      'receive',
      'deliveryAddress',
      'deliveryAddressDetails',
      'deliveryAddressBranch',
      'payByBonusOrderAmount',
    ]),
  },
  methods: {
    onPageBeforeIn() {
      bus.$on('catalog:form-confirm', this.onSendClick);

      this.resetState();

      this.setInForm(true);

      if (!this.receiveAvailable) {
        this.selfOrder = false;
        this.name = this.userNameFull || '';
        this.maskedUsername = this.user?.username
          ? this.user.username !== 'Guest'
            ? this.user.username
            : ''
          : '';
      }
    },
    onPageBeforeOut() {
      bus.$off('catalog:form-confirm', this.onSendClick);
      this.setInForm(false);
    },
    inAppBrowserExitHandler(e: any, action: IAdditionalActionPayment, instance: any) {
      instance.removeEventListener('exit', this.inAppBrowserExitHandler);
      instance.removeEventListener('loadstart', this.inAppBrowserLoadStartHandler);

      instance.close();
    },
    inAppBrowserLoadStartHandler(
      e: any,
      action: IAdditionalActionPayment,
      instance: any,
    ) {
      if (
        action.params.successUrl &&
        (e.url as string).includes(action.params.successUrl)
      ) {
        instance.close();

        instance.removeEventListener('loadstart', this.inAppBrowserLoadStartHandler);

        const msgTitle =
          this.settings.finishMessageTitle ??
          i18n.t('modules.catalog.form.paid.title').toString();
        const msgText =
          this.settings.finishMessageText ??
          i18n.t('modules.catalog.form.paid.text').toString();
        const callback = () => {
          this.clearBasket();
          this.goToMainTab();

          this.setShowCatalogHistoryBar(false);

          setTimeout(() => {
            this.setShowCatalogHistoryBar(true);
          }, 100);
        };

        if (msgTitle || msgText) {
          f7.dialog.alert(msgText, msgTitle, callback);
        } else {
          callback();
        }
      } else if (
        action.params.failUrl &&
        (e.url as string).includes(action.params.failUrl)
      ) {
        instance.close();

        instance.removeEventListener('loadstart', this.inAppBrowserLoadStartHandler);

        f7.dialog.alert(
          i18n.t('modules.catalog.form.fail.text') as string,
          i18n.t('modules.catalog.form.fail.title') as string,
        );
      }
    },
    doSendRequest() {
      const address =
        this.receive === ReceiveType.delivery &&
        this.deliveryAddress &&
        this.deliveryAddress.value
          ? buildGeocoderValue(this.deliveryAddress as Suggest).value ?? ''
          : '';
      const room = this.deliveryAddressDetails?.room || '';
      const domofon = this.deliveryAddressDetails?.domofon || '';
      const entrance = this.deliveryAddressDetails?.entrance || '';
      const floor = this.deliveryAddressDetails?.floor || '';
      const lat = this.deliveryAddress?.data.lat;
      const lng = this.deliveryAddress?.data.lng;
      const addressComment = this.deliveryAddressDetails?.comment;
      const comment = this.comment
        ? this.comment + (addressComment ? '\n\n' + addressComment : '')
        : addressComment;
      const name = this.selfOrder ? this.userNameFull : this.name;
      const phone = this.selfOrder
        ? this.user?.username
          ? this.user.username !== 'Guest'
            ? this.user.username
            : ''
          : ''
        : this.maskedUsername;

      let discount = 0;

      if (this.receive === ReceiveType.pickup && this.settings.pickup?.discount) {
        discount = this.settings.pickup.discount;
      }

      let params: ISendOrderRequest = {
        // @ts-ignore
        order: {
          address,
          comment,
          name: name || '',
          phone,
          products: this.basket
            .filter((entry) => entry.product.active)
            .map((entry) => ({
              product_id: entry.product.id,
              count: entry.amount,
              paidByBonus: this.paidByBonus
                .filter((product) => product.id === entry.product.id)
                .reduce((acc, current) => {
                  acc += Math.floor(current.price * ((100 - discount) / 100));
                  return acc;
                }, 0),
            })),
          receiveType: this.receive || undefined,
          toBranch:
            this.receive === ReceiveType.pickup && this.pickupPoint
              ? this.pickupPoint.idBranch
              : this.receive === ReceiveType.delivery && this.deliveryAddressBranch
              ? this.deliveryAddressBranch
              : undefined,
          room,
          domofon,
          entrance,
          floor,
          lat,
          lng,
          paymentType: this.paymentTypeId,
          email: this.email,
        },
      };

      if (this.pickupTime) {
        params.order.comment +=
          (params.order.comment ? '\n\n' : '') + 'Самовывоз в ' + this.pickupTime;
      }

      if (this.settings.payByBonus && this.settings.payByBonusType === 'order') {
        params.order.paidByBonus = this.payByBonusOrderAmount;
      }

      f7.preloader.show();

      this.sendRequest(params)
        // @ts-ignore
        .then(({ additionalAction }) => {
          console.log(additionalAction);

          if (additionalAction) {
            const appGlobalMainColorParts = getComputedStyle(document.documentElement)
              .getPropertyValue('--app-global-main-color')
              .split(',') as [string, string, string];
            const appGlobalMainFgColorParts = getComputedStyle(document.documentElement)
              .getPropertyValue('--app-global-main-fg-color')
              .split(',') as [string, string, string];
            const appGlobalMainColorHex = rgbToHex.apply(this, appGlobalMainColorParts);
            const appGlobalMainFgColorHex = rgbToHex.apply(
              this,
              appGlobalMainFgColorParts,
            );

            const options: any = theme.ios
              ? {
                  toolbarcolor: appGlobalMainColorHex,
                  closebuttoncolor: appGlobalMainFgColorHex,
                  hardwareback: 'no',
                  hideurlbar: 'yes',
                  hidenavigationbuttons: 'yes',
                }
              : {
                  footercolor: appGlobalMainColorHex,
                  hideurlbar: 'yes',
                  hidenavigationbuttons: 'yes',
                  toolbarcolor: appGlobalMainColorHex,
                  usewkwebview: 'yes',
                  enableViewportScale: 'no',
                  zoom: 'no',
                  closebuttoncolor: appGlobalMainFgColorHex,
                  toolbarposition: 'top',
                };

            const InAppBrowserInstance = openUrl(
              additionalAction.params.url,
              '_blank',
              undefined,
              Object.entries(options)
                .reduce((acc, current) => {
                  acc.push(current.join('='));
                  return acc;
                }, [] as string[])
                .join(','),
            );

            if (config.app?.pwa) {
              setTimeout(() => {
                const iframe = document.querySelector('iframe');
                const parent = iframe?.parentElement;

                if (parent) {
                  parent.classList.add('in-app-popup');

                  window.addEventListener('message', (e) => {
                    if (e.data === 'success') {
                      this.inAppBrowserLoadStartHandler(
                        {
                          url: additionalAction.params.successUrl,
                        },
                        additionalAction,
                        InAppBrowserInstance,
                      );
                    } else if (e.data === 'error') {
                      this.inAppBrowserLoadStartHandler(
                        {
                          url: additionalAction.params.failUrl,
                        },
                        additionalAction,
                        InAppBrowserInstance,
                      );
                    }
                  });

                  const watchIframeInterval = setInterval(() => {
                    if (!parent) {
                      this.inAppBrowserExitHandler(
                        undefined,
                        additionalAction,
                        InAppBrowserInstance,
                      );

                      clearInterval(watchIframeInterval);
                    }
                  }, 500);
                }
              }, 100);
            } else {
              InAppBrowserInstance.addEventListener('loadstart', (e: any) => {
                this.inAppBrowserLoadStartHandler(
                  e,
                  additionalAction,
                  InAppBrowserInstance,
                );
              });

              InAppBrowserInstance.addEventListener('exit', (e: any) => {
                this.inAppBrowserExitHandler(e, additionalAction, InAppBrowserInstance);
              });
            }
          } else {
            const msgTitle =
              this.settings.finishMessageTitle ??
              i18n.t('modules.catalog.form.result.title').toString();
            const msgText =
              this.settings.finishMessageText ??
              i18n.t('modules.catalog.form.result.text').toString();
            const callback = () => {
              this.clearBasket();
              this.goToMainTab();

              this.setShowCatalogHistoryBar(false);

              setTimeout(() => {
                this.setShowCatalogHistoryBar(true);
              }, 100);
            };

            if (msgTitle || msgText) {
              f7.dialog.alert(msgText, msgTitle, callback);
            } else {
              callback();
            }
          }
        })
        .catch((error) => showErrorDialog.call(this, { error }))
        .finally(() => {
          f7.preloader.hide();
        });
    },
    goToMainTab() {
      const defaultMainModule = getModule(this.defaultTab);

      f7.views.current.router.back(f7.views.current.router.history[0], {
        force: true,
      });

      f7.tab.show(`#${defaultMainModule.tabName}`);
    },
    onSendClick() {
      this.buttonClicked = true;

      this.$nextTick(() => {
        this.beforeSendCheck();
      });
    },
    async checkInZone() {
      return new Promise((resolve) => {
        f7.preloader.show();
        this.checkInZoneResolve = resolve;
        this.checkDeliveryZone = true;
      });
    },
    async beforeSendCheck() {
      if (this.validationState.invalid) {
        this.touchAll();

        const invalidInput = this.$el.querySelector(
          '.item-input-invalid input, .item-input-invalid textarea',
        ) as HTMLInputElement;

        if (invalidInput) {
          invalidInput.focus();
        }

        return;
      }

      if (this.showPaymentTypes && !this.paymentTypeId) {
        const invalidBlock = this.$el.querySelector(
          // @ts-ignore
          '.' + this.$style.payment,
        ) as HTMLElement;

        if (invalidBlock) {
          // @ts-ignore
          invalidBlock.scrollIntoViewIfNeeded(false);
        }

        return;
      }

      if (this.checkZoneEnabled) {
        const inZone = await this.checkInZone();

        if (!inZone) {
          f7.dialog.alert(
            i18n.t('modules.catalog.form.checkZone.text').toString(),
            i18n.t('modules.catalog.form.checkZone.title').toString(),
          );

          return;
        }
      }

      f7.preloader.show();

      this.updateBasket()
        .then((updated) => {
          if (updated) {
            f7.dialog.alert(
              i18n.t('modules.catalog.basket.updated.alert.text').toString(),
              '',
              () => {
                f7.views.current.router.navigate({ name: 'Basket' }, { force: true });
              },
            );
            f7.preloader.hide();
          } else {
            this.doSendRequest();
          }
        })
        .catch((e) => {
          showErrorDialog.call(this, e);
          f7.preloader.hide();
        });
    },
    resetState() {
      this.maskedUsername = '';
      this.name = '';
      this.comment = '';

      this.buttonClicked = false;
    },
    onReceiveClick() {
      bus.$emit('delivery:open');
      bus.$once('delivery:done', () => {
        f7.preloader.show();

        this.updateBasket()
          .then(() => {
            this.checkActiveProducts();
          })
          .catch((e) => {
            showErrorDialog.call(this, e);
          })
          .finally(() => {
            f7.preloader.hide();
          });
      });
    },
    checkActiveProducts() {
      if (this.notActiveProductsList.length) {
        f7.dialog.alert(
          i18n.t('modules.catalog.basket.nonactive.alert.text').toString(),
          '',
          () => {
            f7.views.current.router.back();
          },
        );
      }
    },
    async onMapInit(map: any) {
      this.map = map;

      const sugg: ISuggestion = {
        data: {
          geo_lat: this.deliveryAddress!.data.lat,
          geo_lon: this.deliveryAddress!.data.lng,
        },
        unrestricted_value: '',
        value: '',
      };

      const appData = await API.getAppData();

      const { inZone } = checkAddressIsInDeliveryZone({
        googleMaps: window.google.maps,
        map: this.map,
        mapShapes: new Map(),
        suggestion: sugg,
        branches: appData.branches.filter((branch) => branch.isPickpoint),
        settings: appData.settings.modules.catalog!,
      });

      this.checkInZoneResolve(inZone);

      this.checkDeliveryZone = false;
      f7.preloader.hide();
    },
    ...appStoreMapper.mapActions(['getAppData']),
    ...catalogStoreMapper.mapActions(['clearBasket', 'sendRequest', 'updateBasket']),
    ...catalogStoreMapper.mapMutations(['setShowCatalogHistoryBar', 'setInForm']),
  },
  watch: {
    paymentTypeId(id: number) {
      bus.$emit('catalog:form-payment-method-id', id);
    },
  },
});
