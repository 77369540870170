


import { defineComponent, onUnmounted, onMounted } from 'vue';
import { md5 } from 'js-md5';
import {
  OSNotification,
  NotificationWillDisplayEvent,
  NotificationClickEvent,
} from 'onesignal-cordova-plugin';
import User from 'onesignal-cordova-plugin/dist/UserNamespace';
import { IPushMock, Tags } from '../types/onesignal';

import config from '@/app.config.js';
import * as OS from '../utils';
import * as API from '../api';
import { showMessageDialog } from 'src/misc';
import bus from 'src/bus';
import { useAppStore } from '@/store/appstore';
import { useNotificationsStore } from '../store';
import { useRelatedStore } from '@/modules/related/dist/store';

export default defineComponent({
  name: 'Notifications',
  setup() {
    const appStore = useAppStore();
    const notificationsStore = useNotificationsStore();
    const relatedStore = useRelatedStore();

    onMounted(() => {
      initOneSignal();

      bus.$on('authComplete', initOneSignal);
      bus.$on('authAfterLoginSuccess', initOneSignal);
      bus.$on('authAfterRegisterSuccess', initOneSignal);
      bus.$on('appDataDidLoadFromServer', initOneSignal);
      bus.$on('notifications-emulate-push', notificationClickCallback);

      window.push = ({ title, body, ...additionalData }: IPushMock) => {
        notificationClickCallback({
          title,
          body,
          additionalData,
        } as OSNotification);
      };
    });

    onUnmounted(() => {
      bus.$off('authComplete', initOneSignal);
      bus.$off('authAfterLoginSuccess', initOneSignal);
      bus.$off('authAfterRegisterSuccess', initOneSignal);
      bus.$off('appDataDidLoadFromServer', initOneSignal);
      bus.$off('notifications-emulate-push', notificationClickCallback);
    });

    async function setTags(OSUser: User) {
      if (notificationsStore.getters.settings.version === 3) {
        if (!appStore.getters.user?.pushToken) {
          const userTags = (await notificationsStore.dispatch('getTags', OSUser)) as Tags;
          const tagsToRemove: string[] = [];
          Object.keys(userTags).forEach((key) => {
            if (key !== 'idUser') {
              tagsToRemove.push(key);
            }
          });
          await notificationsStore.dispatch('removeTags', {
            tags: tagsToRemove,
            user: OSUser,
          });
        }

        const username = appStore.getters.user?.idUser && appStore.getters.user.username;
        if (username) {
          OSUser.getOnesignalId().then((token: string | null) => {
            if (appStore.getters.user!.pushToken !== token) {
              API.setUserPushToken(token || '').then(() => {
                appStore.commit('setPushToken', token);
              });
            }
          });

          notificationsStore.dispatch('addAlias', {
            label: 'idUser',
            id: md5(username),
            user: OSUser,
          });
          notificationsStore.dispatch('setTag', {
            key: 'idUser',
            value: md5(username),
            user: OSUser,
          });
        } else {
          notificationsStore.dispatch('removeAlias', { label: 'idUser', user: OSUser });
          notificationsStore.dispatch('removeTags', {
            tags: ['idUser'],
            user: OSUser,
          });
        }
      } else {
        const tags: Tags = {
          [`${appStore.state.domain}-idDevice`]: appStore.state.idDevice.toString(),
          currentDomain: appStore.state.domain,
        };

        if (appStore.getters.user?.idUser) {
          tags[`${appStore.state.domain}-idUser`] =
            appStore.getters.user.idUser.toString();
        } else {
          const related: any[] = relatedStore.getters.list;

          if (Array.isArray(related)) {
            related.forEach((item) => {
              if (item.domain && item.domain !== appStore.state.domain) {
                tags[`${item.domain}-idUser`] = '0';
              }
            });
          }
        }

        notificationsStore.dispatch('setTags', {
          tags,
          user: OSUser,
        });
      }
    }

    async function initOneSignal() {
      if (appStore.getters.isPWA) {
        // Почему так? Потому что способ, описанный в документации,
        // не работал, воркер не запускался при использовании модуля
        // для Vue. Поэтому сделан такой костыль.

        if (!window.OS) {
          window.OneSignalDeferred.push((OneSignal: any) => {
            OneSignal.init({
              appId: notificationsStore.getters.settings.onesignal,
            }).then(() => {
              window.OneSignal.Notifications.setDefaultTitle(config.app.name);

              window.OneSignal.Notifications.addEventListener('click', (event: any) => {
                notificationClickCallback(
                  (event as unknown as NotificationClickEvent).notification,
                );
              });
              window.OneSignal.Notifications.addEventListener(
                'foregroundWillDisplay',
                (event: any) => notificationReceivedCallback(event as any),
              );

              setTags(window.OneSignal.User as unknown as User);

              window.OS = OS;
            });
          });
        } else {
          setTags(window.OneSignal.User as unknown as User);
        }
      } else {
        if (!window.OS) {
          document.addEventListener('deviceready', () => {
            window.plugins.OneSignal.initialize(
              notificationsStore.getters.settings.onesignal,
            );

            window.plugins.OneSignal.Notifications.addEventListener('click', (event) => {
              notificationClickCallback(event.notification);
            });
            window.plugins.OneSignal.Notifications.addEventListener(
              'foregroundWillDisplay',
              (event) => notificationReceivedCallback(event as any),
            );

            window.plugins.OneSignal.Notifications.requestPermission(true).then(
              (accepted) => {
                console.log('User accepted notifications: ' + accepted);
              },
            );

            window.OS = OS;

            setTags(window.plugins.OneSignal.User);
          });
        } else {
          setTags(window.plugins.OneSignal.User);
        }
      }
    }

    function defaultNotificationHandler(notification: OSNotification) {
      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;
      const currentDomain = appStore.state.domain;
      const pushDomain = additionalData?.connectedDomain;
      const pushEntity = additionalData?.connectedEntity;

      if (
        relatedStore.getters.moduleEnabled &&
        pushDomain &&
        currentDomain !== pushDomain
      ) {
        if (pushEntity) {
          notificationsStore.dispatch('notificationOpened', notification);
        }

        bus.$emit('selectDomain', {
          domain: pushDomain,
          callback: () => {
            defaultNotificationHandler(notification);
          },
          confirm: false,
        });
      } else {
        if (pushDomain) {
          bus.$emit(`push-with-domain`);
        }

        if (pushEntity) {
          bus.$emit(`${pushEntity}-push`, notification);
        } else {
          showMessageDialog({
            title: notification.title,
            text: notification.body,
          }).then(() => {
            notificationsStore.dispatch('notificationOpened', notification);
          });
        }
      }
    }

    function notificationClickCallback(notification: OSNotification) {
      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;
      const currentDomain = appStore.state.domain;
      const pushDomain = additionalData?.connectedDomain;
      const ignoreIfNotInDomain = additionalData?.ignoreIfNotInDomain === 'true';

      if (ignoreIfNotInDomain && currentDomain !== pushDomain) {
        return;
      }

      if (appStore.state.splashVisible) {
        bus.$once('splashHide', () => {
          setTimeout(() => {
            defaultNotificationHandler(notification);
          }, 500);
        });
      } else {
        defaultNotificationHandler(notification);
      }
    }

    function notificationReceivedCallback(event: NotificationWillDisplayEvent) {
      // Web-push возвращает не NotificationWillDisplayEvent, а что-то похожее на NotificationClickEvent
      const notification =
        typeof event.getNotification === 'function'
          ? event.getNotification()
          : (event as unknown as NotificationClickEvent).notification;

      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;
      const currentDomain = appStore.state.domain;
      const pushDomain = additionalData?.connectedDomain;
      const ignoreIfNotInDomain = additionalData?.ignoreIfNotInDomain === 'true';

      if (ignoreIfNotInDomain && currentDomain !== pushDomain) {
        return;
      }

      //TODO: connectedEntity === 'bonus' is temporary workaround, need refactor
      const silent =
        additionalData?.connectedEntity === 'bonus' || !!additionalData?.silent;

      if (silent) {
        event.preventDefault();

        notificationsStore.dispatch('foregroundWillDisplay', notification);
        notificationClickCallback(notification);
      } else {
        if (!appStore.getters.isPWA) {
          notification.display();
        }
      }
    }

    return {};
  },
});
