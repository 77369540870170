import VueI18n from 'vue-i18n';

import { formatNumber } from '@/utils/formatNumber';

interface IOptions {
  delimiter?: string;
  separator?: string;
  format?: boolean;
}

function _getComputedDistance(
  distance: number,
  i18n: VueI18n,
  options?: IOptions,
): string {
  const distanceI18n = (i18n.messages[i18n.locale] as any).global
    .distance as IDistanceI18n;
  let resultDistance;

  const distanceInKilometers = Math.round(distance / 1000);

  if (distance < 1000) {
    resultDistance = Math.round(distance).toString();
    // } else if (distanceInKilometers >= 1 && distanceInKilometers < 5) {
    //   resultDistance = formatNumber(distance / 1000, 'round', 2, { i18n, ...options });
  } else if (distanceInKilometers < 10) {
    resultDistance = formatNumber(distance / 1000, 'round', 1, { ...options });
  } else {
    resultDistance = distanceInKilometers.toString();
  }

  return (
    resultDistance +
    distanceI18n.separator +
    (distance < 1000 ? distanceI18n.meters : distanceI18n.kilometers)
  );
}

export default _getComputedDistance;
