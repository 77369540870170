import { Getters } from 'vuex-smart-module';

import { CodeType, IModuleSettings } from '@/store/types';
import { IBranch, ISocialNetwork, IUser, SocialsSettings } from '@/types';

import config from 'src/app.config.js';
import getDevicePixelWidth from '@/utils/getDevicePixelWidth';

import AppState from '@/store/app/state';

export default class AppGetters extends Getters<AppState> {
  get defaultTab(): string {
    return this.getters.appSettings.defaultTab || config.modules.defaultTab;
  }

  get appName(): string {
    return this.getters.appSettings?.hideTitle
      ? ''
      : this.getters.appSettings.name ?? config.app.name;
  }

  get devMode(): boolean {
    return window.devMode;
  }

  get showTabbar(): boolean {
    return (
      this.state.appData.settings?.tabbar != null &&
      !!this.state.appData.settings.tabbar.length
    );
  }

  get socialNetworksSettings(): Nullable<SocialsSettings> {
    return this.state.appData?.settings?.modules?.socials;
  }

  get socialNetworks(): ISocialNetwork[] {
    return this.getters.socialNetworksSettings?.list || [];
  }

  get user(): IUser | undefined {
    return this.state.appData.user;
  }

  get userNameFull() {
    return getUserName(this.getters.user, { short: false });
  }

  get userNameShort() {
    return getUserName(this.getters.user, { short: true });
  }

  get appSettings(): IModuleSettings {
    const settings = this.state.appData?.settings?.modules?.app || {};

    settings.hideMenuMiniapp = settings.hideMenuMiniapp ?? true;
    settings.birthdayUseSystemDatePicker = settings.birthdayUseSystemDatePicker ?? false;
    settings.datesUseSystemDatePicker = settings.datesUseSystemDatePicker ?? true;

    return settings;
  }

  get decimalOptions(): IDecimalOptions {
    return {
      decimal: this.getters.appSettings.decimal ?? 0,
      decimalRoundingRule: this.getters.appSettings.decimalRoundingRule ?? 'default',
    };
  }

  get miniapp(): boolean {
    return config.app.miniapp;
  }

  get miniappHideElements(): boolean {
    return this.getters.miniapp && window.innerWidth < 600;
  }

  get codeType(): CodeType {
    return this.getters.appSettings.codeType || CodeType.qr;
  }

  get branches(): IBranch[] {
    const branchesWithWorkingTime: IBranch[] = [];

    this.state.appData.branches?.forEach((branch) => {
      if (branch.workingTime) {
        branchesWithWorkingTime.push({ ...branch });
      } else {
        const workingTimeByDays: WorkingTime[] = [];
        branch.days?.forEach((day) => {
          workingTimeByDays.push({
            startTime: branch.startTime,
            endTime: branch.endTime,
            day: Number(day),
          });
        });

        branchesWithWorkingTime.push({
          ...branch,
          workingTime: workingTimeByDays,
        });
      }
    });

    return branchesWithWorkingTime;
  }

  get pickupPoints() {
    return this.getters.branches.filter((branch) => branch.isPickpoint);
  }

  get clientName() {
    return window.qs?.client || this.state.domain || config.api.client;
  }

  fullImagePath(relativePath: string) {
    const client = window.devMode
      ? this.state.host === 'client'
        ? this.getters.clientName
        : this.state.host === 'gmb'
        ? 'gmb'
        : 'front'
      : this.getters.clientName;
    const host =
      !window.devMode || (window.devMode && this.state.host !== 'dev')
        ? config.api.host
        : 'gmb-dev.ru';

    return `${config.api.protocol}://${client}.${host}${relativePath}`;
  }

  get timeZoneOptions(): ITimeZoneOptions {
    return {
      sourceTimeZone: this.getters.appSettings.sourceTimeZone ?? 0,
      displayTimeZone: this.getters.appSettings.displayTimeZone ?? 0,
      useClientTimeZone: this.getters.appSettings.useClientTimeZone,
    };
  }

  get modulesSettings(): { [key: string]: any } {
    return {
      maps: this.state.appData.settings.modules.maps,
    };
  }

  get isPWA(): boolean {
    return process.env.VUE_APP_PWA === 'true';
  }

  get isStandalone(): boolean {
    return (
      window.navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches
    );
  }

  get activeModules(): string[] {
    return [
      'auth',
      'bonus',
      'panelmenu',
      ...Object.keys(this.state.appData.settings.modules),
    ];
  }

  get deviceWidth(): number {
    return getDevicePixelWidth(window.innerWidth, window.devicePixelRatio, {
      deviceWidthImageDivideAmount: this.getters.appSettings.deviceWidthImageDivideAmount,
      deviceWidthMaxPixelRatio: this.getters.appSettings.deviceWidthMaxPixelRatio,
      deviceWidthIgnorePixelRatio: this.getters.appSettings.deviceWidthIgnorePixelRatio,
    });
  }

  get extraLogo(): boolean {
    return !!config.app.extraLogo && !this.getters.appSettings.smallLogo;
  }

  get relatedModuleLogoAvailable(): boolean {
    return !!this.state.appData.settings.modules.related?.list?.find(
      (related) => related.domain === this.state.domain,
    );
  }

  get currency(): ICurrencyI18n {
    return this.getters.appSettings.currency;
  }
}

function getUserName(user?: IUser, { short = false }: { short?: boolean } = {}) {
  if (!user) {
    return '';
  }

  if (!user.fName && user.lName) {
    return user.lName;
  } else if (user.fName) {
    return (
      user.fName +
      ' ' +
      (user.lName
        ? short
          ? user.lName.length > 0
            ? `${user.lName[0]}.`
            : user.lName
          : user.lName
        : '')
    ).trim();
  }
}
