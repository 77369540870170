






























import Vue, { PropType } from 'vue';

import { IActionItem } from '../types';

import { appStoreMapper } from 'src/store/appstore';
import { actionsStoreMapper } from '../store';
import { getImageThumbnailPath } from '@/utils';

import ActionPopup from './action-popup.vue';

export default Vue.extend({
  components: {
    ActionPopup,
  },
  props: {
    item: Object as PropType<IActionItem>,
  },
  data: () => ({
    popupOpened: false,
    innerItem: null as Nullable<IActionItem>,
  }),
  created() {
    this.innerItem = this.item;
  },
  computed: {
    date(): string {
      return this.formattedDate(
        this.item.dateStart,
        this.item.dateEnd,
        this.locale,
        this.$i18n,
      );
    },
    image(): string {
      return this.fullImagePath(
        getImageThumbnailPath(this.item.icon, 'scale', {
          width: this.deviceWidth,
          height: 0,
        }),
      );
    },
    ...actionsStoreMapper.mapGetters(['settings', 'formattedDate']),
    ...appStoreMapper.mapGetters(['clientName', 'fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['locale']),
  },
  methods: {
    showPopup() {
      this.popupOpened = true;
    },
  },
});
