import {
  IGetHistoryRequest,
  IGetOperationResponse,
  IGetOperationsDetail,
  IHistoryItem,
  Operation,
  OperationId,
  OperationsDetails,
} from '../types';

import * as API from 'src/api';

type TOperations = {
  operationHistory: IHistoryItem[];
};

const API_ENDPOINT_HISTORY = 'operation-history';
const API_ENDPOINT_OPERATION = 'operation';
const API_ENDPOINT_OPERATIONS_DETAIL = 'operations-detail';

export async function getHistory({
  from,
  limit,
  offset,
}: IGetHistoryRequest): Promise<IHistoryItem[]> {
  return API.request<TOperations>({
    data: { limit, from, offset },
    method: 'post',
    url: API_ENDPOINT_HISTORY,
  }).then(({ operationHistory }) => operationHistory);
}

export async function getOperationsDetail(): Promise<OperationsDetails> {
  return API.request<IGetOperationsDetail>({
    method: 'get',
    url: API_ENDPOINT_OPERATIONS_DETAIL,
  }).then((response) => response.operationsDetail);
}

export async function getOperation(id: OperationId): Promise<Operation> {
  return API.request<IGetOperationResponse>({
    method: 'get',
    url: API_ENDPOINT_OPERATION + '/' + id,
  }).then((response) => response.operation);
}
