
import { defineComponent } from 'vue';
import { CreateElement } from 'vue';

import { IconType } from '../types';

import config from '../catalog.conf.json';
import bus from 'src/bus';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';

export default defineComponent({
  name: 'CatalogTabbarItem',
  render(this: any, h: CreateElement) {
    return h('f7-link', {
      class: [
        this.$style.link,
        {
          [this.$style.delivery]: this.settings?.icon === IconType.delivery,
          ['tab-link-' + config.tabName]: true,
        },
      ],
      props: {
        key: config.name,
        href: `#${config.tabName}`,
        text: this.settings?.tabbarTitle || this.$t(`modules.${config.name}.tabbarTitle`),
        tabLink: true,
        tabLinkActive:
          this.settings?.tab &&
          (this.defaultTab ? config.name === this.defaultTab : config.name === 'home'),
      },
      on: {
        click: () => {
          if (this.settings?.tab) {
            bus.$emit('tabWillOpen', config.name);
          } else {
            this.goToCatalog();
          }
        },
      },
    });
  },
  computed: {
    ...appStoreMapper.mapGetters(['defaultTab']),
    ...catalogStoreMapper.mapGetters(['settings']),
  },
  methods: {
    goToCatalog() {
      // @ts-ignore
      this.$f7?.views.current.router.navigate({ name: 'Catalog' });
    },
  },
});
