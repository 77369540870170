var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{class:_vm.$style.page,attrs:{"no-toolbar":""},on:{"page:beforein":_vm.onPageBeforeIn,"page:beforeout":_vm.onPageBeforeOut}},[_c('f7-navbar',{attrs:{"no-shadow":"","back-link":_vm.$t('global.navbar.back')}},[_c('f7-nav-title',[_vm._v(_vm._s(_vm.$t('modules.catalog.basket.title')))]),(!_vm.miniappHideElements)?_c('f7-nav-right',[(_vm.basket.length)?_c('f7-link',{attrs:{"icon":"far fa-trash-alt"},on:{"click":_vm.reset}}):_vm._e()],1):_vm._e()],1),(!_vm.loading)?_c('div',{class:_vm.$style.wrapper},[(_vm.basket.length)?[(_vm.settings.blockCheckout && _vm.receive === _vm.ReceiveType.delivery)?_c('Time',{attrs:{"check-day-and-time":""}}):_vm._e(),(_vm.notActiveProductsList.length)?_c('div',{class:_vm.$style.topInfo},[_c('p',[_vm._v(_vm._s(_vm.$t('modules.catalog.basket.notAvailable')))])]):_vm._e(),_c('div',{staticClass:"list",class:_vm.$style.list},[_c('ul',{key:_vm.basket.length},_vm._l((_vm.basket),function(entry){return _c('BasketEntry',{key:entry.product.product_id,attrs:{"entry":entry,"options":_vm.decimalOptions}})}),1)]),_c('div',{class:_vm.$style.checkout},[_c('div',{class:_vm.$style.row},[_c('span',{class:_vm.$style.left},[_vm._v(" "+_vm._s(_vm.$t('modules.catalog.basket.subtotal'))+" ")]),_c('span',{class:_vm.$style.right},[_vm._v(" "+_vm._s(_vm.localizePrice(_vm.totalProductsPrice))+" ")])]),(_vm.receive === _vm.ReceiveType.delivery)?_c('div',{class:_vm.$style.row},[_c('span',{class:_vm.$style.left},[_vm._v(" "+_vm._s(_vm.$t('modules.catalog.basket.delivery'))+" ")]),_c('span',{class:_vm.$style.right},[(
                !_vm.settings.delivery.deliveryPrice ||
                (_vm.settings.delivery.forFreeDelivery &&
                  _vm.totalProductsPrice >= _vm.settings.delivery.forFreeDelivery)
              )?[_vm._v(_vm._s(_vm.$t('modules.catalog.basket.free')))]:[_vm._v(_vm._s(_vm.localizePrice(_vm.settings.delivery.deliveryPrice)))]],2)]):_vm._e(),(
            _vm.receive === _vm.ReceiveType.pickup &&
            _vm.settings &&
            _vm.settings.pickup &&
            _vm.settings.pickup.discount
          )?_c('div',{class:_vm.$style.row},[_c('span',{class:_vm.$style.left},[_vm._v(" "+_vm._s(_vm.$t('modules.catalog.basket.pickupDiscount'))+" "+_vm._s(_vm.settings.pickup.discount)+"% ")]),_c('span',{class:_vm.$style.right},[_vm._v(" "+_vm._s(_vm.localizePrice(_vm.totalPricePickup - _vm.totalProductsPrice))+" ")])]):_vm._e(),_c('div',{class:_vm.$style.row},[_c('span',{class:_vm.$style.left},[_c('b',[_vm._v(_vm._s(_vm.$t('modules.catalog.basket.total')))])]),_c('span',{class:_vm.$style.right},[_c('b',[(_vm.receive === _vm.ReceiveType.pickup)?[_vm._v(" "+_vm._s(_vm.localizePrice(_vm.totalPricePickup)))]:[_vm._v(" "+_vm._s(_vm.localizePrice(_vm.totalPriceDelivery))+" ")]],2)])]),(
            _vm.receive === _vm.ReceiveType.delivery &&
            _vm.settings.delivery.forFreeDelivery &&
            _vm.totalProductsPrice < _vm.settings.delivery.forFreeDelivery
          )?_c('div',{class:_vm.$style.deliveryFree},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('modules.catalog.basket.freeDelivery'))+" "),_c('b',[_vm._v(_vm._s(_vm.localizePrice(_vm.settings.delivery.forFreeDelivery)))])])]):_vm._e()]),(_vm.receive === _vm.ReceiveType.delivery && _vm.showDeliveryMinimumPrice)?_c('div',{class:_vm.$style.bottomInfo},[_vm._v(" "+_vm._s(_vm.$t('modules.catalog.basket.minimumAmount'))+" "),_c('b',[_vm._v(_vm._s(_vm.localizePrice(_vm.minOrder)))])]):_vm._e(),_c('CatalogBarWrapper',{attrs:{"safe-area":""}})]:[_c('div',{class:_vm.$style.empty},[_vm._v(_vm._s(_vm.$t('modules.catalog.basket.list.empty')))])]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }