






















import { defineComponent } from 'vue';

import { appStoreMapper } from 'src/store/appstore';
import { bonusStoreMapper } from '../store';

export default defineComponent({
  name: 'BonusHomeSlotDelay',
  computed: {
    ...appStoreMapper.mapGetters(['miniappHideElements', 'user']),
    ...bonusStoreMapper.mapGetters(['moduleEnabled']),
  },
});
