






































































































import { ICategory, ReceiveType } from '../types';
import { defineComponent } from 'vue';
import { ContentLoader } from 'vue-content-loader';
import { f7 } from 'framework7-vue';

import config from '../catalog.conf.json';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';
import { showErrorDialog } from '@/misc';
import bus from '@/bus';
import appConfig from '@/app.config.js';

import CatalogItem from '@/components/CatalogItem.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';
import CatalogBasketBar from './CatalogBasketBar.vue';
import NavbarSlotLeft from 'src/components/navbar-slot-left.vue';
import DeliverySelect from './DeliverySelect.vue';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';

export default defineComponent({
  name: 'Catalog',
  components: {
    CatalogItem,
    CatalogBarWrapper,
    CatalogBasketBar,
    NavbarSlotLeft,
    DeliverySelect,
    ContentLoader,
  },
  data: () => ({
    config: appConfig,
    loading: false,
    ptrIgnore: false,
  }),
  beforeDestroy() {
    (this.$refs.scroll as HTMLElement).removeEventListener('scroll', this.scrollHandler);

    bus.$off('appDataDidLoad', () => this.onAppDataDidLoad);
  },
  mounted() {
    (this.$refs.scroll as HTMLElement).addEventListener('scroll', this.scrollHandler);

    bus.$on('appDataDidLoad', () => this.onAppDataDidLoad);

    bus.$once('home-ptr', this.onAppDataDidLoad);
  },
  computed: {
    showBar(): boolean {
      return !!this.settings.showBasket && !!this.totalProducts;
    },
    title(): string {
      return this.settings?.title || this.$t('modules.catalog.title').toString();
    },
    ...appStoreMapper.mapGetters(['fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['currentTab', 'appData', 'appDataLoading', 'domain']),
    ...catalogStoreMapper.mapGetters([
      'settings',
      'totalProducts',
      'receiveAvailable',
      'receiveBranchId',
    ]),
    ...catalogStoreMapper.mapState([
      'basket',
      'categories',
      'categoriesScrollTop',
      'pickupPoint',
      'receive',
      'deliveryAddress',
    ]),
  },
  methods: {
    onAppDataDidLoad() {
      this.loadCategories({ flush: true });
    },
    async loadCategories({ flush }: { flush?: boolean } = {}) {
      if (!this.appData || this.appDataLoading) {
        return false;
      }

      this.loading = true;

      return this.getCategories({ flush })
        .then(() => {
          this.$nextTick(() => {
            f7.lazy.create(this.$el as HTMLElement);
          });
        })
        .catch((e) => {
          showErrorDialog.call(this, e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scrollHandler() {
      const scrollTop = (this.$refs.scroll as HTMLElement).scrollTop;

      if (scrollTop > 0 && !this.ptrIgnore) {
        this.ptrIgnore = true;
      } else if (scrollTop === 0 && this.ptrIgnore) {
        this.ptrIgnore = false;
      }
    },
    goToCategory(category: ICategory) {
      if (
        (this.receive === ReceiveType.pickup && !this.pickupPoint) ||
        (this.receive === ReceiveType.delivery && !this.deliveryAddress)
      ) {
        bus.$emit('delivery:block');
      } else {
        f7.views.current.router.navigate(`/catalog/category/${category.id}`);
      }
    },
    onPTRRefresh(done: () => void) {
      this.loadCategories({ flush: true }).then(() => {
        this.resetProductsByCategoryId();

        done();
      });
    },
    // Встроенные механизм не работает
    restoreScrollTop() {
      const element = (this.$refs.page as unknown as Vue).$el.querySelector(
        '.page-content .wrapper',
      ) as HTMLElement;
      const savedScrollTop = this.categoriesScrollTop[0];

      setTimeout(() => {
        element.scrollTop = savedScrollTop;
      });
    },
    // Нужны оба события
    pageAfterIn() {
      this.restoreScrollTop();
    },
    // Нужны оба события
    pageInit() {
      this.restoreScrollTop();
    },
    pageBeforeOut() {
      const scrollTop = (
        (this.$refs.page as unknown as Vue).$el.querySelector(
          '.page-content .wrapper',
        ) as HTMLElement
      ).scrollTop;

      this.setCategoriesScrollTop({ id: 0, value: scrollTop });
    },
    pageBeforeIn() {
      if (!this.settings.tab) {
        f7.toolbar.hide('.views .tabbar');
      }

      this.setInBasket(false);
      this.setInForm(false);
      this.setInBonus(false);
    },
    back() {
      if (!this.settings.tab) {
        f7.toolbar.show('.views .tabbar');
      }

      f7.views.current.router.back();
    },
    getCategoryImage(photoId: Nullable<number>): string {
      if (!photoId) return '';

      return this.fullImagePath(
        getImageThumbnailPath(photoId, { deviceWidth: this.deviceWidth }),
      );
    },
    ...catalogStoreMapper.mapActions(['getCategories']),
    ...catalogStoreMapper.mapMutations([
      'resetProductsByCategoryId',
      'setCategories',
      'setCategoriesScrollTop',
      'setInBasket',
      'setInForm',
      'setInBonus',
    ]),
  },
  watch: {
    ptrIgnore() {
      if (this.ptrIgnore) {
        (this.$refs.wrapper as HTMLElement).classList.add('ptr-ignore');
      } else {
        (this.$refs.wrapper as HTMLElement).classList.remove('ptr-ignore');
      }
    },
    currentTab(value: string) {
      if (value === config.name) {
        if (this.categories.length) {
          f7.lazy.create(this.$el as HTMLElement);
        } else {
          this.loadCategories();
        }
      }
    },
    'categories.length'(length: number) {
      if (length) {
        f7.lazy.create(this.$el as HTMLElement);
      }
    },
    receiveBranchId(value: Nullable<number>, prev: Nullable<number>) {
      if (value != prev) {
        this.loadCategories({ flush: true });
      }
    },
  },
});
