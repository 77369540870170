import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IUser } from '@/types';
import { AuthType } from '@/types/enum';
import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import AuthState from './state';

export default class AuthGetters extends Getters<AuthState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get user(): IUser | undefined {
    return this.app.state.appData.user;
  }

  get isAuthorized() {
    return !!this.getters.user?.idUser;
  }

  get settings(): IModuleSettings {
    return (
      this.app.state.appData.settings?.modules?.auth || {
        tab: false,
        type: AuthType.base,
        useAgeDisclaimer: false,
        ageLimit: 0,
        email: false,
        emailRequired: false,
        receiptToEmailModuleEnabled: false,
      }
    );
  }
}
