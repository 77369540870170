import { createComposable, createMapper, Module } from 'vuex-smart-module';

import BonusActions from './actions';
import BonusGetters from './getters';
import BonusMutations from './mutations';
import BonusState from './state';

const module = new Module({
  actions: BonusActions,
  getters: BonusGetters,
  mutations: BonusMutations,
  namespaced: true,
  state: BonusState,
});

export default module;

export const bonusStoreMapper = createMapper(module);
export const useBonusStore = createComposable(module);
