import { CSSSelector } from 'framework7/components/app/app-class';

import { f7 } from 'framework7-vue';

import { switchDomain } from './api';
import { getModule, showErrorDialog } from '@/misc';
import config from 'src/app.config.js';
import bus from 'src/bus';
import i18n from '@/translate/lang';
import { useAppStore } from '@/store/appstore';
import { useRelatedStore } from '@/modules/related/dist/store';

export const mixin = () => {
  const appStore = useAppStore();
  const relatedStore = useRelatedStore();

  function selectDefaultTab() {
    f7.tab.show(`#${getModule(appStore.getters.defaultTab).tabName}` as CSSSelector);
    appStore.commit('setCurrentTab', appStore.getters.defaultTab);
  }

  function switchHandler(domain: relatedDomain): Promise<Promise<void>> {
    return new Promise((resolve) => {
      const userId = appStore.getters.user?.idUser;

      if (domain !== appStore.state.domain) {
        if (!userId) {
          f7.panel.close();

          appStore
            .dispatch('switchDomain', { domain })
            .then(() => {
              bus.$once('appDataDidLoadFromServer', () => {
                resolve(Promise.resolve());
              });
            })
            .finally(() => {
              selectDefaultTab();
            });
        } else {
          f7.preloader.show();

          switchDomain({ related: domain })
            .then((response) => {
              appStore.dispatch('switchDomain', {
                domain,
                authToken: response.authToken,
              });

              bus.$once('appDataDidLoadFromServer', () => {
                resolve(Promise.resolve());
              });
            })
            .catch((error) => {
              showErrorDialog({ error });
            })
            .finally(() => {
              f7.panel.close();
              selectDefaultTab();
            });
        }
      } else {
        resolve(Promise.resolve());
      }
    });
  }

  function selectDomain(domain: relatedDomain, callback?: () => void, confirm?: boolean) {
    const switchHandlerCallback = () => {
      if (callback) {
        callback();
      }

      if (confirm !== false && !config.app?.miniapp && !appStore.getters.user?.idUser) {
        bus.$emit('showWelcomeScreen');
      }

      bus.$emit('domain-switch');
    };

    if (confirm !== false && appStore.state.domainFirstSelect && !relatedStore.getters.settings.showSelection) {
      if (domain === appStore.state.domain) {
        return switchHandlerCallback();
      }

      const dialog = f7.dialog.confirm(
        relatedStore.getters.settings.dialogText || (i18n.t('modules.related.dialog.text') as string),
        relatedStore.getters.settings.dialogTitle || (i18n.t('modules.related.dialog.title') as string),
        () => {
          switchHandler(domain).then(switchHandlerCallback);
        },
      );

      dialog && dialog.open();
    } else {
      if (domain === appStore.state.domain) {
        return switchHandlerCallback();
      } else {
        switchHandler(domain).then(switchHandlerCallback);
      }
    }
  }

  return { selectDomain };
};
