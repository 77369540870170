import { idNews, INewsItem, INewsItems } from '../types';

import * as API from 'src/api';

const API_ENDPOINT_NEWS = 'news';

export async function getNews({
  offset,
  limit,
}: {
  offset?: number;
  limit?: number;
} = {}): Promise<INewsItem[]> {
  return API.request<INewsItems>({
    method: 'get',
    url: API_ENDPOINT_NEWS,
    params: { limit, offset },
  }).then((response) => response.news);
}

export async function getAnnounce(id: idNews): Promise<INewsItem> {
  return API.request<INewsItems>({
    method: 'get',
    url: API_ENDPOINT_NEWS,
    params: { id },
  }).then((response) => response.news[0]);
}
