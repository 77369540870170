
























































import Vue from 'vue';

import { appStoreMapper } from '@/store/appstore';
import { bonusStoreMapper } from '../store';
import { getLocalizedPrice } from '@/utils';

import BonusProgress from './BonusProgress.vue';

export default Vue.extend({
  name: 'BonusLevels',
  components: { BonusProgress },
  computed: {
    currentGroupIndex(): Nullable<number> {
      return this.settings.groups.findIndex((g) => g.id === this.user?.idGroup);
    },
    ...appStoreMapper.mapGetters(['user', 'decimalOptions', 'currency']),
    ...bonusStoreMapper.mapGetters(['settings']),
  },
  methods: {
    formattedOrderSum(value: number): string {
      return getLocalizedPrice(
        value,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
  },
});
