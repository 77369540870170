




































import { IHistoryItem } from '../types';

import Vue, { PropType } from 'vue';

import { appStoreMapper } from '@/store/appstore';
import { historyStoreMapper } from '../store';
import { timeZone, getLocalizedPrice, formatNumber } from '@/utils';

import BonusIcon from '@/components/bonus-icon.vue';

export default Vue.extend({
  components: {
    BonusIcon,
  },
  props: {
    item: Object as PropType<IHistoryItem>,
  },
  computed: {
    decimal(): number {
      return this.decimalOptions.decimal;
    },
    amount(): string {
      return getLocalizedPrice(
        this.orderSum,
        this.currency,
        this.decimalOptions.decimal ? this.decimalOptions.decimalRoundingRule : 'default',
        this.decimalOptions.decimal,
      );
    },
    bonus(): number {
      return Math.abs(this.item.bonus);
    },
    bonusSign(): string {
      return this.item.bonus ? (this.item.bonus > 0 ? 'plus' : 'minus') : 'none';
    },
    date(): string {
      if (Number.isNaN(new Date(this.item.date).getDate())) {
        return '';
      }

      return timeZone(this.item.date, {
        locale: this.locale,
        type: 'datetimeLong',
        timezone: this.timeZoneOptions,
      });
    },
    gift(): string {
      if (this.item.gift) {
        return (
          this.$t('modules.history.giftGiven') +
          ' ' +
          this.item.gift.charAt(0).toLowerCase() +
          this.item.gift.substring(1)
        );
      } else {
        return '';
      }
    },
    isCancelled(): boolean {
      return this.item.isCancelled;
    },
    isIn(): boolean {
      return this.item.type === 'in';
    },
    isLast(): boolean {
      return this.item.id === this.history[this.history.length - 1].id;
    },
    operationDescription(): string {
      if (this.isIn) {
        return this.orderSum
          ? this.$t('modules.history.billAmount', [this.amount]).toString()
          : '';
      } else {
        return this.gift;
      }
    },
    operationResult(): string {
      let operationText: string;

      if (this.isIn) {
        operationText = this.$tc('modules.history.bonus.earned.client', 0).toString();
      } else {
        operationText = this.$tc('modules.history.bonus.charged.client', 0).toString();
      }

      operationText +=
        ' ' +
        formatNumber(
          this.bonus,
          this.decimalOptions.decimal
            ? this.decimalOptions.decimalRoundingRule
            : 'default',
          this.decimalOptions.decimal,
        );

      return operationText;
    },
    orderSum(): number {
      return Number(this.item.orderSum);
    },
    status(): string {
      return 'done';
    },
    statusClasses(): string[] {
      let classes = [(this as any).$style.status];

      if (this.isCancelled) {
        classes.push('cancelled');
      }

      return classes;
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'timeZoneOptions', 'currency']),
    ...appStoreMapper.mapState(['locale']),
    ...historyStoreMapper.mapGetters(['settings']),
    ...historyStoreMapper.mapState(['history']),
  },
  methods: {
    goToOperation(): void {
      this.$f7.views.current.router.navigate(`/history/body/${this.item.id}`);
    },
    toFixed(value: number) {
      if (!value) return 0;

      return formatNumber(
        value,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
  },
});
