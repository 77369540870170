









import Vue from 'vue';

import { ICustomField } from 'src/types';

import { refStoreMapper } from '../store';

export default Vue.extend({
  name: 'ReferralRegistrationField',
  computed: {
    ...refStoreMapper.mapGetters(['settings']),
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', { key: 'promoCode', value } as ICustomField);
    },
  },
});
