import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { registerModuleConfig } from 'src/misc';
import config from './specialoffer.conf.json';

import store from 'src/store';
import SpecialOfferStore from './store';

import SpecialOfferHomeSlot from './components/SpecialOfferHomeSlot.vue';

export function init() {
  Vue.component('specialoffer', SpecialOfferHomeSlot);

  registerModule(store, ['specialoffer'], 'specialoffer/', SpecialOfferStore);

  registerModuleConfig(config as IModule);
}
