







import { Gift } from '../types';

import { defineComponent, PropType } from 'vue';

import { mixin } from '../composition';

export default defineComponent({
  props: {
    gift: {
      type: Object as PropType<Gift>,
    },
  },
  setup(props: any) {
    return { ...mixin(props) };
  },
  // computed: {
  //   styles(): { [key: string]: string } {
  //     return {
  //       backgroundImage: this.image ? `url(${this.image})` : '',
  //     };
  //   },
  // },
});
