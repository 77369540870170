import { f7 } from 'framework7-vue';

import { ISpecialOffer, SpecialOfferType } from './types';

import bus from '@/bus';
import store from '@/store';

export interface IOfferEntryProps {
  offer: ISpecialOffer;
}

export const mixin = (props: IOfferEntryProps) => {
  const offer: ISpecialOffer = props.offer;
  const domain = store.state.domain as string;

  function goToOffer() {
    if (offer.type === SpecialOfferType.gift) {
      const data: any = {
        notification: {
          title: '',
          body: '',
          additionalData: {
            connectedEntity: 'gift',
            idConnectedEntity: offer.id.toString(),
            connectedDomain: domain,
          },
        },
      };

      bus.$emit('gift-push', data);
    } else if (offer.type === SpecialOfferType.news) {
      const data: any = {
        notification: {
          title: '',
          body: '',
          additionalData: {
            connectedEntity: 'news',
            idConnectedEntity: offer.id.toString(),
            connectedDomain: domain,
          },
        },
      };

      bus.$emit('news-push', data);
    } else if (offer.type === SpecialOfferType.action) {
      const data: any = {
        notification: {
          title: '',
          body: '',
          additionalData: {
            connectedEntity: 'action',
            idConnectedEntity: offer.id.toString(),
            connectedDomain: domain,
          },
        },
      };

      bus.$emit('action-push', data);
    } else if (offer.type === SpecialOfferType.product) {
      f7.views.current.router.navigate(`/catalog/category/0/${offer.id}`);
    }
  }

  return { goToOffer };
};
