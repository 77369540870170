import {
  IGetGiftResponse,
  IGetCouponResponse,
  IGetCouponsResponse,
  Gift,
  Coupon,
} from '../types';

import * as API from 'src/api';

// const API_ENDPOINT_GIFTS = 'get-gifts';
const API_ENDPOINT_COUPONS = 'get-coupons';

export async function getGifts(): Promise<IGetCouponsResponse> {
  return API.request<IGetCouponsResponse>({
    method: 'get',
    url: API_ENDPOINT_COUPONS,
  });
}

export async function getGift(id?: number): Promise<Nullable<Gift>> {
  return API.request<IGetGiftResponse>({
    method: 'get',
    url: API_ENDPOINT_COUPONS,
    params: {
      id: `gift-${id}`,
    },
  }).then((response) => response.gift[0]);
}

export async function getCoupon(id?: number): Promise<Nullable<Coupon>> {
  return API.request<IGetCouponResponse>({
    method: 'get',
    url: API_ENDPOINT_COUPONS,
    params: {
      id: `coupon-${id}`,
    },
  }).then((response) => response.coupon[0]);
}
