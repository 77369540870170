








































































import Vue from 'vue';
import VueQRCode from '@chenfengyuan/vue-qrcode';

import config from 'src/app.config.js';
import { appStoreMapper } from '@/store/appstore';
import { getSafariScreenSizes } from '@/api';

export default Vue.extend({
  name: 'PWA',
  components: {
    VueQRCode,
  },
  data: () => ({
    forceShowDialog: false,
    isDesktop: false,
    options: {
      width: window.innerHeight / 3,
      margin: 2,
      errorCorrectionLevel: 'Q',
    },
    safariRatios: {
      '14': [
        {
          width: 320,
          height: 454,
        },
        {
          width: 375,
          height: 553,
        },
        {
          width: 375,
          height: 629,
        },
        {
          width: 375,
          height: 635,
        },
        {
          width: 390,
          height: 664,
        },
        {
          width: 414,
          height: 622,
        },
        {
          width: 414,
          height: 719,
        },
        {
          width: 428,
          height: 746,
        },
      ],
      '15': [
        {
          width: 320,
          height: 449,
        },
        {
          width: 375,
          height: 548,
        },
        {
          width: 375,
          height: 629,
        },
        {
          width: 375,
          height: 635,
        },
        {
          width: 390,
          height: 664,
        },
        {
          width: 414,
          height: 617,
        },
        {
          width: 414,
          height: 712,
        },
        {
          width: 414,
          height: 715,
        },
        {
          width: 414,
          height: 719,
        },
        {
          width: 428,
          height: 746,
        },
      ],
      '16': [
        {
          width: 375,
          height: 629,
        },
        {
          width: 375,
          height: 635,
        },
        {
          width: 375,
          height: 548,
        },
        {
          width: 390,
          height: 664,
        },
        {
          width: 393,
          height: 660,
        },
        {
          width: 414,
          height: 617,
        },
        {
          width: 414,
          height: 715,
        },
        {
          width: 414,
          height: 719,
        },
        {
          width: 428,
          height: 746,
        },
        {
          width: 430,
          height: 737,
        },
        {
          width: 430,
          height: 740,
        },
      ],
      '17': [
        {
          // SE
          width: 375,
          height: 547,
        },
        {
          // 12 mini, 13 mini
          width: 375,
          height: 628,
        },
        {
          // Xs, 11 Pro
          width: 375,
          height: 634,
        },
        {
          // 12, 12 Pro, 13, 13 Pro, 14
          width: 390,
          height: 663,
        },
        {
          // 15, 14 Pro, 15 Pro
          width: 393,
          height: 659,
        },
        {
          // Xs MAX, 11 Pro Max
          width: 414,
          height: 718,
        },
        {
          // Xr, 11
          width: 414,
          height: 714,
        },
        {
          // 12 Pro Max, 13 Pro Max, 14 Plus
          width: 428,
          height: 745,
        },
        {
          // REAL: 14 Pro Max
          width: 430,
          height: 736,
        },
        {
          // 15 Pro Max, 14 Pro Max, 15 Plus
          width: 430,
          height: 739,
        },
      ],
    } as ScreenRatio,
  }),
  mounted() {
    this.watchDesktop();

    getSafariScreenSizes()
      .then((ratios) => {
        this.safariRatios = ratios;
      })
      .catch((e) => console.log(e));
  },
  computed: {
    show(): boolean {
      return this.isPWA && !this.isStandalone && !this.debugPWA;
    },
    isSafari(): boolean {
      return /Safari/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent);
    },
    showDialog(): boolean {
      const hasSafariInUA = /Safari/.test(navigator.userAgent);

      let safariVersion = '';

      if (hasSafariInUA) {
        safariVersion = window.navigator.userAgent.match(/Version\/(\d+)/)?.[1] || '';
        if (!this.safariRatios[safariVersion]) {
          safariVersion = 'all';
        }
      }

      const hasValidDocumentElementRatio =
        this.safariRatiosComputed[safariVersion] &&
        this.safariRatiosComputed[safariVersion].some(
          (ratio) =>
            ratio.width / ratio.height ===
            document.documentElement.clientWidth / document.documentElement.clientHeight,
        );

      const isiOSSafari = this.isSafari && hasValidDocumentElementRatio;

      return this.forceShowDialog || this.$theme.md || (this.$theme.ios && isiOSSafari);
    },
    qrCodeValue(): string {
      return `https://${config.api.client}.pwa.getmeback.ru`;
    },
    screenSize(): string {
      return this.forceShowDialog
        ? `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`
        : '';
    },
    safariRatiosComputed(): ScreenRatio {
      const allRatios: ScreenRatio = { all: [] };

      Object.keys(this.safariRatios).forEach((key) => {
        this.safariRatios[key].forEach((ratio) => {
          allRatios.all.push(ratio);
        });
      });

      return { ...allRatios, ...this.safariRatios };
    },
    ...appStoreMapper.mapGetters(['appName', 'isPWA', 'isStandalone']),
    ...appStoreMapper.mapState(['debugPWA']),
  },
  methods: {
    onClick() {
      this.forceShowDialog = true;
    },
    watchDesktop() {
      let count = 0;

      const interval = setInterval(() => {
        this.isDesktop =
          !window.devMode &&
          document.documentElement.classList.contains('device-desktop') &&
          !/Mobile/.test(navigator.userAgent);

        if (this.isDesktop || count > 50) {
          clearInterval(interval);
        }
      }, 100);
    },
  },
});
