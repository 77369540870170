import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { IModule } from '@/types';

import { faCheck, faGift } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './stampcard.conf.json';

import store from 'src/store';
import StampCardStore from './store/index';

import StampCard from './components/StampCard.vue';

library.add(faCheck, faGift);

export function init() {
  Vue.component('stampcard', StampCard);

  registerModule(store, ['stampcard'], 'stampcard/', StampCardStore);

  Misc.registerModuleConfig(config as IModule);
}
