































import { defineComponent, PropType } from 'vue';

import { INewsItem } from '../types';

import { appStoreMapper } from 'src/store/appstore';
import { newsStoreMapper } from '../store';
import { getImageThumbnailPath } from '@/utils';

import NewsPopup from './news-popup.vue';

export default defineComponent({
  name: 'NewsItem',
  components: { NewsPopup },
  props: {
    item: {
      type: Object as PropType<INewsItem>,
      required: true,
    },
  },
  data: () => ({
    popupOpened: false,
  }),
  computed: {
    date(): string {
      return this.formattedDate(this.item.date, this.locale);
    },
    image(): string {
      return this.fullImagePath(
        getImageThumbnailPath(this.item.icon, 'scale', {
          width: this.deviceWidth,
          height: 0,
        }),
      );
    },
    ...appStoreMapper.mapGetters(['clientName', 'fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['locale']),
    ...newsStoreMapper.mapGetters(['settings', 'formattedDate']),
  },
  methods: {
    showPopup() {
      this.popupOpened = true;
    },
  },
});
