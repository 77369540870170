import { idService } from 'src/types';
import { TResponse } from 'src/api';

export interface ISendBookingRequest {
  clientName: string;
  idBranch: Nullable<number>;
  clientPhone: string;
  visitDate: string;
  visitTime: string;
  guests?: number;
  services?: idService[];
  comment: string;
}

export type SendBookingResponse = TResponse & {
  authToken: string;
  idBooking: number;
};

export interface IModuleSettings {
  title?: string;
  tabbarTitle?: string;
  formButtonTitle?: string;
  fields?: BookingFormFields[];
  homeShowButton?: boolean;
}

export enum BookingFormFields {
  branch = 'branch',
  guests = 'guests',
  service = 'service',
}
