










































































import Vue, { PropType } from 'vue';

import { ReceiveType } from '../types';
import { IBranch } from '@/types';

import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '@/modules/catalog/dist/store';
import getWorkingTimeFormatted from '@/utils/getWorkingTimeFormatted';
// import getBranchScheduleFormatted from '@/utils/getBranchScheduleFormatted';

import BranchList from 'src/components/branch-list.vue';

export default Vue.extend({
  name: 'DeliveryMapSheetSelected',
  components: { BranchList },
  props: {
    mapCenter: Object as PropType<IMapPosition>,
  },
  data: () => ({
    ReceiveType,
  }),
  created() {
    bus.$on('catalog:delivery:selected:sheet-open', () => {
      (this.$refs.selected as any)?.f7Sheet.open();
    });

    bus.$on('catalog:delivery:selected:sheet-close', () => {
      (this.$refs.selected as any)?.f7Sheet.close();
    });
  },
  computed: {
    deliveryAddressAsPickupPoint(): Partial<IBranch> {
      return {
        idBranch: Number(this.deliveryAddress?.id || '0'),
        name: this.deliveryAddress?.value,
      };
    },
    mapsSettings(): Nullable<any> {
      return this.modulesSettings.maps;
    },
    ...appStoreMapper.mapGetters(['pickupPoints', 'modulesSettings', 'timeZoneOptions']),
    ...catalogStoreMapper.mapState([
      'receive',
      'pickupPoint',
      'deliveryAddress',
      'deliveryAddressDetails',
    ]),
  },
  methods: {
    onBranchCloseClick() {
      this.setPickupPoint(null);

      this.$emit('update:map-center', {
        lat: parseFloat(this.mapsSettings?.center.lat),
        lng: parseFloat(this.mapsSettings?.center.lng),
      });
    },
    onConfirmClick() {
      bus.$emit('catalog:delivery:map:close');
      bus.$emit('catalog:delivery:selected:sheet-close');
      bus.$emit('catalog:delivery:branches:sheet-close');
      bus.$emit('catalog:delivery:address:sheet-close');
    },
    onInputFocus() {
      this.$emit('update:step-opened', true);
      bus.$emit('catalog:delivery:address:step-open');
    },
    onSelectedAddressClick() {
      bus.$emit('catalog:delivery:selected:sheet-close');
      bus.$emit('catalog:delivery:address:sheet-open');
      bus.$emit('catalog:delivery:address:sheet:update');

      this.onInputFocus();
    },
    workingTimeFormatted(schedule: WorkingSchedule, branch: IBranch): string {
      return getWorkingTimeFormatted(schedule, branch, this.timeZoneOptions, this.$i18n);
    },
    onScheduleClick(branch: IBranch) {
      //this.$f7.dialog.alert(getBranchScheduleFormatted(branch, this.$i18n), 'Время работы');
    },
    ...catalogStoreMapper.mapActions(['setPickupPoint']),
  },
});
