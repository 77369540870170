













import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import { authStoreMapper } from '../store';

import { useAuth } from '../composition';

export default defineComponent({
  name: 'AuthNavbarButton',
  setup() {
    const { showNavbarIcon } = useAuth();

    return { showNavbarIcon };
  },
  computed: {
    ...authStoreMapper.mapGetters(['isAuthorized']),
  },
  methods: {
    goToAuth() {
      f7.views.current.router.navigate('/auth');
    },
    goToProfile() {
      f7.views.current.router.navigate('/profile');
    },
  },
});
