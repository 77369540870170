var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-sheet',{ref:"selected",staticClass:"catalog-delivery-selected",class:_vm.$style.selected,staticStyle:{"height":"auto"},attrs:{"swipe-to-close":"","backdrop":false}},[(_vm.receive === _vm.ReceiveType.pickup)?[(_vm.pickupPoints.length > 1)?_c('f7-link',{class:_vm.$style.close,attrs:{"icon":"fal fa-times"},on:{"click":_vm.onBranchCloseClick}}):_vm._e(),_c('BranchList',{key:_vm.pickupPoint ? _vm.pickupPoint.idBranch : 0,attrs:{"no-title":"","no-desc":"","custom-branches":_vm.pickupPoint ? [_vm.pickupPoint] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var branch = ref.branch;
var workingTime = ref.workingTime;
var distance = ref.distance;
var formattedDistance = ref.formattedDistance;return [_c('div',{class:_vm.$style.branch},[_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(branch.name))]),_c('div',{class:[_vm.$style.workingTime, ( _obj = {}, _obj[_vm.$style.closed] = !workingTime.opened, _obj )],on:{"click":function($event){return _vm.onScheduleClick(branch)}}},[_vm._v(" "+_vm._s(_vm.workingTimeFormatted(workingTime, branch))+" "),(distance)?_c('span',{class:_vm.$style.distance},[_vm._v("   "+_vm._s(formattedDistance))]):_vm._e()])])]),_c('f7-button',{class:_vm.$style.button,attrs:{"fill":"","large":""},on:{"click":_vm.onConfirmClick}},[_vm._v("Забрать отсюда")])]}}],null,false,950909417)})]:[_c('BranchList',{key:_vm.deliveryAddress ? _vm.deliveryAddressAsPickupPoint.idBranch : 1,attrs:{"no-title":"","no-desc":"","custom-branches":_vm.deliveryAddress ? [_vm.deliveryAddressAsPickupPoint] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var branch = ref.branch;
return [_c('f7-list',{class:_vm.$style.address},[_c('div',{class:_vm.$style.wrap,attrs:{"slot":"list"},slot:"list"},[_c('div',{class:_vm.$style.blocker,on:{"click":_vm.onSelectedAddressClick}}),_c('div',{class:_vm.$style.text},[_c('p',[_vm._v(_vm._s(branch.name))]),_c('i',{staticClass:"icon far fa-chevron-right"})]),(_vm.deliveryAddressDetails.room)?[_c('p',{class:_vm.$style.room},[_vm._v("Кв/офис "+_vm._s(_vm.deliveryAddressDetails.room))])]:_vm._e()],2)]),_c('f7-button',{class:_vm.$style.button,attrs:{"fill":"","large":""},on:{"click":_vm.onConfirmClick}},[_vm._v("Доставить сюда")])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }