interface IOptions {
  deviceWidthImageDivideAmount?: number;
  deviceWidthMaxPixelRatio?: number;
  deviceWidthIgnorePixelRatio?: boolean;
}

function _getDevicePixelWidth(
  deviceWidth: number,
  devicePixelRatio: number,
  options: IOptions = {},
): number {
  options.deviceWidthImageDivideAmount = options.deviceWidthImageDivideAmount || 50;
  devicePixelRatio = options.deviceWidthIgnorePixelRatio
    ? 1
    : options.deviceWidthMaxPixelRatio &&
      devicePixelRatio > options.deviceWidthMaxPixelRatio
    ? options.deviceWidthMaxPixelRatio
    : devicePixelRatio;

  let result =
    Math.round((deviceWidth * devicePixelRatio) / options.deviceWidthImageDivideAmount) *
    options.deviceWidthImageDivideAmount;

  const divisionRemainder =
    ((deviceWidth * devicePixelRatio) / options.deviceWidthImageDivideAmount) % 10;

  if (divisionRemainder && divisionRemainder < 0.5) {
    result += options.deviceWidthImageDivideAmount;
  }

  return result;
}

export default _getDevicePixelWidth;
