






import Vue, { PropType } from 'vue';

import BonusIcon from 'src/components/bonus-icon.vue';

export default Vue.extend({
  name: 'BonusAmount',
  components: { BonusIcon },
  props: {
    bonus: String as PropType<string>,
    fix: Boolean as PropType<boolean>,
    flex: Boolean as PropType<boolean>,
  },
});
