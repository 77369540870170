































import Vue, { PropType } from 'vue';

import i18n from '@/translate/lang';

export default Vue.extend({
  name: 'ItemBadge',
  props: {
    badge: {
      type: Object as PropType<ItemBadge>,
      required: true,
    },
    onlySymbol: Boolean as PropType<boolean>,
  },
  computed: {
    icon(): string {
      return this.badge.icon || '';
    },
    label(): string {
      if (this.badge.label) {
        return this.badge.label;
      }

      let string = '';

      switch (this.badge.type) {
        case 'isNew':
          string = i18n.t('global.catalog.item.badge.new').toString();
          break;
        case 'isPopular':
          string = i18n.t('global.catalog.item.badge.popular').toString();
          break;
        case 'isSpicy':
          string = i18n.t('global.catalog.item.badge.spicy').toString();
          string = this.onlySymbol ? '' : string;
          break;
        case 'isVegan':
          string = i18n.t('global.catalog.item.badge.vegan').toString();
          string = this.onlySymbol ? '' : string;
          break;
        default:
          string = '';
      }

      return string;
    },
  },
});
