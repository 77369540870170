import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import ActionsState from './state';
import { formatDate } from '@/utils';
import VueI18n from 'vue-i18n';

export default class ActionsGetters extends Getters<ActionsState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData.settings?.modules?.actions || {};

    settings.cardShowDate = settings.cardShowDate ?? true;
    settings.cardShowTitle = settings.cardShowTitle ?? true;
    settings.cardCutTitle = settings.cardCutTitle ?? false;
    settings.cardTitleOverImage = settings.cardTitleOverImage ?? false;
    settings.limit = settings.limit ?? 10;

    return settings;
  }

  formattedDate(
    dateStart: string | undefined,
    dateEnd: string | undefined,
    locale: string,
    i18n: VueI18n,
  ): string {
    const startDate: Date | null = dateStart ? new Date(dateStart) : null;
    const endDate: Date | null = dateEnd ? new Date(dateEnd) : null;
    const nowDate = new Date();

    if (
      (!startDate && !endDate) ||
      (startDate &&
        Number.isNaN(startDate.getDate()) &&
        endDate &&
        Number.isNaN(endDate.getDate()))
    ) {
      return '';
    }

    if (
      (startDate && Number.isNaN(startDate.getTime())) ||
      (endDate && Number.isNaN(endDate.getTime()))
    ) {
      return '';
    }

    let startText = '';
    let endText = '';

    if (startDate && dateStart) {
      const startDateThisYear = nowDate.getFullYear() === startDate.getFullYear();
      const endDateSameMonth =
        !!endDate &&
        !!startDate &&
        startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth();

      startText =
        i18n.t('modules.actions.startPrefix') +
        ' ' +
        formatDate(new Date(dateStart), {
          locale,
          type: endDateSameMonth
            ? 'dateLongNoMonth'
            : startDateThisYear
            ? 'dateLongNoYear'
            : 'dateLong',
        });
    }

    if (endDate && dateEnd) {
      const endDateThisYear = nowDate.getFullYear() === endDate.getFullYear();
      const startDateSameYear =
        !!endDate && !!startDate && startDate.getFullYear() === endDate.getFullYear();

      endText =
        (startDate
          ? i18n.t('modules.actions.endPrefix')
          : i18n.t('modules.actions.endPrefixAlt')) +
        ' ' +
        formatDate(new Date(dateEnd), {
          locale,
          type: startDate
            ? endDateThisYear && startDateSameYear
              ? 'dateLongNoYear'
              : 'dateLong'
            : endDateThisYear
            ? 'dateLongNoYear'
            : 'dateLong',
        });
    }

    return [startText, endText].join(' ').replace(/\s+/, ' ');
  }
}
