
























import Vue from 'vue';

import { IActionItem } from '../types';

import config from '../actions.conf.json';
import { appStoreMapper } from '@/store/appstore';
import { actionsStoreMapper } from '../store';
import { showErrorDialog } from '@/misc';
import * as API from '../api';

import NavbarSlotLeft from '@/components/navbar-slot-left.vue';
import ActionsItem from './actions-item.vue';

export default Vue.extend({
  name: 'Actions',
  components: {
    ActionsItem,
    NavbarSlotLeft,
  },
  data: () => ({
    actions: [] as IActionItem[],
    loading: false,
  }),
  computed: {
    asTab(): boolean {
      return this.settings?.tab ?? true;
    },
    ...appStoreMapper.mapState(['currentTab']),
    ...actionsStoreMapper.mapGetters(['settings']),
  },
  methods: {
    onPTRRefresh(done: () => void) {
      this.loadActions().finally(() => {
        done();
      });
    },
    loadActions() {
      this.$f7.preloader.show();
      this.loading = true;

      return API.getActions()
        .then((actions) => {
          this.actions = actions;
        })
        .catch((error) => {
          showErrorDialog({ error });
        })
        .finally(() => {
          this.$f7.preloader.hide();
          this.loading = false;
        });
    },
  },
  watch: {
    currentTab(value: string) {
      if (value === config.name) {
        this.loadActions();
      }
    },
  },
});
