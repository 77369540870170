import { createMapper, Module } from 'vuex-smart-module';

import ActionsGetters from './getters';
import ActionsState from './state';

const module = new Module({
  getters: ActionsGetters,
  namespaced: true,
  state: ActionsState,
});

export default module;

export const actionsStoreMapper = createMapper(module);
