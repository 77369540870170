





























import Vue, { PropType } from 'vue';

import ItemBadgeComponent from './item-badge.vue';

export default Vue.extend({
  name: 'ItemBadges',
  components: { ItemBadgeComponent },
  props: {
    badges: {
      type: Array as PropType<ItemBadge[]>,
      default: () => [],
    },
    withIcons: Boolean as PropType<boolean>,
  },
  computed: {
    textBadges(): ItemBadge[] {
      return this.badges.filter((b) => b.type !== 'isSpicy' && b.type !== 'isVegan');
    },
    iconBadges(): ItemBadge[] {
      return this.badges.filter((b) => b.type === 'isSpicy' || b.type === 'isVegan');
    },
  },
});
