






































import { defineComponent } from 'vue';
import animatedScrollTo from 'animated-scroll-to';

import { appStoreMapper } from 'src/store/appstore';
import bus from '@/bus';
import config from '../home.conf.json';
import appConfig from '@/app.config.js';

import trLogo from '@/assets/images/logo_fullscreen_tr.gif';
import trLogoExtra from '@/assets/images/logo_fullscreen_extra_tr.gif';

import HomeNavbarSlotRight from './home-navbar-slot-right.vue';
import HomeSlotTop from './home-slot-top.vue';
import NavbarSlotLeft from 'src/components/navbar-slot-left.vue';

export default defineComponent({
  name: 'Home',
  components: {
    HomeNavbarSlotRight,
    HomeSlotTop,
    NavbarSlotLeft,
  },
  data: () => ({
    opacityRange: 0,
    parallaxRange: 0,
    parallaxRatio: 0.2,
    pageContentElement: null as unknown as HTMLElement,
    navbarElement: null as unknown as HTMLElement,
    navbarBgElement: null as unknown as HTMLElement,
    navbarTitleElement: null as unknown as HTMLElement,
    opened: false,
    allowInfinite: false,
    allowAutoScrollTop: true,
    trLogo,
    trLogoExtra,
    appConfig
  }),
  created() {
    this.opacityRange = this.appSettings.smallLogo ? 20 : 50;
    this.parallaxRange = this.appSettings.smallLogo ? 50 : 100;

    bus.$on('home-infinite-switch', this.switchInfinite);
  },
  mounted() {
    this.pageContentElement = this.$el.querySelector('.page-content') as HTMLElement;
    this.navbarElement = (this.$refs.navbar as unknown as Vue).$el as HTMLElement;
    this.navbarBgElement = this.navbarElement.querySelector('.navbar-bg') as HTMLElement;
    this.navbarTitleElement = this.navbarElement.querySelector('.title') as HTMLElement;

    document.documentElement.classList.add('fullscreen-logo');

    if (this.appSettings.hideTitleWhenFullscreenLogo && this.navbarTitleElement) {
      document.documentElement.classList.add('fullscreen-logo-hide-title');
      this.navbarTitleElement.style.opacity = '0';
    }

    setTimeout(() => {
      const pageScrollDiff =
        this.pageContentElement.scrollHeight - this.pageContentElement.offsetHeight || 1;

      if (pageScrollDiff < this.opacityRange) {
        this.opacityRange = pageScrollDiff;
        this.parallaxRange = pageScrollDiff;
      }
    }, 100);

    this.pageContentElement.addEventListener('scroll', this.scrollHandler);

    bus.$on('tabWillOpen', (module: string) => {
      if (
        this.allowAutoScrollTop &&
        module === config.name &&
        module === this.currentTab
      ) {
        this.animateScrollToTop();
      }
    });

    bus.$on('home:lazy:update', this.updateLazy);
  },
  destroyed() {
    this.pageContentElement.removeEventListener('scroll', this.scrollHandler);

    bus.$off('home-infinite-switch', this.switchInfinite);
  },
  computed: {
    ...appStoreMapper.mapGetters(['appName', 'appSettings', 'extraLogo']),
    ...appStoreMapper.mapState(['appData', 'appDataDidLoad', 'currentTab']),
  },
  methods: {
    animateScrollToTop(
      { minDuration, maxDuration } = { minDuration: 250, maxDuration: 500 },
    ): void {
      const pageContent = this.$el.querySelector('.page-content') as HTMLElement;

      if (pageContent) {
        if (minDuration || maxDuration) {
          animatedScrollTo(0, {
            elementToScroll: pageContent,
            minDuration,
            maxDuration,
          });
        } else {
          pageContent.scrollTop = 0;
        }
      }
    },
    switchInfinite(state: boolean) {
      this.allowInfinite = state;
    },
    onPTRRefresh(done: () => void) {
      this.$f7.preloader.show();

      this.getAppData(bus).finally(() => {
        this.$f7.preloader.hide();

        bus.$emit('home-ptr');
      });

      // This is not a mistake
      done();
    },
    updateLazy(): void {
      if (this.$el) {
        setTimeout(() => {
          this.$f7?.lazy.create(this.$el as HTMLElement);
        }, 100);
      }
    },
    onInfinite(): void {
      if (this.allowInfinite) {
        bus.$emit('home-infinite');

        this.updateLazy();
      }
    },
    onPageBeforeIn() {
      if (this.appSettings.hideTitleWhenFullscreenLogo) {
        if (this.pageContentElement.scrollTop === 0) {
          this.opened = true;

          this.scrollHandler();

          if (this.$theme?.md) {
            this.setParallax();
          }
        }
      }
    },
    onPageAfterIn() {
      if (this.appSettings.hideTitleWhenFullscreenLogo) {
        this.opened = true;

        this.scrollHandler();

        if (this.$theme?.md) {
          this.setParallax();
        }
      }
    },
    onPageBeforeOut() {
      if (
        this.appSettings.hideTitleWhenFullscreenLogo &&
        this.pageContentElement.scrollTop > 0
      ) {
        this.navbarTitleElement.style.removeProperty('opacity');
      }
    },
    scrollHandler() {
      const opacity =
        (this.opacityRange - (this.opacityRange - this.pageContentElement.scrollTop)) /
        this.opacityRange;
      const parallax =
        (this.parallaxRange - (this.parallaxRange - this.pageContentElement.scrollTop)) /
        this.parallaxRange;

      this.navbarBgElement.style.opacity = opacity > 1 ? '1' : opacity.toString();

      if (this.$theme?.md && this.appSettings.fullscreenLogoParallax) {
        this.pageContentElement.style.backgroundPositionY =
          parallax > 1
            ? `-${this.parallaxRange * this.parallaxRatio}px`
            : `-${this.parallaxRange * parallax * this.parallaxRatio}px`;
      }

      if (this.appSettings.hideTitleWhenFullscreenLogo && this.opened) {
        if (parallax >= 1) {
          this.navbarTitleElement.style.opacity = 'initial';
        } else {
          this.navbarTitleElement.style.setProperty(
            'opacity',
            parallax > 1 ? '1' : parallax.toString(),
            'important',
          );
        }
      }
    },
    setParallax() {
      if (this.appSettings.hideTitleWhenFullscreenLogo) {
        const parallax =
          (this.parallaxRange -
            (this.parallaxRange - this.pageContentElement.scrollTop)) /
          this.parallaxRange;

        if (parallax >= 1) {
          this.navbarTitleElement.style.removeProperty('opacity');
        } else {
          this.navbarTitleElement.style.setProperty(
            'opacity',
            parallax > 1 ? '1' : parallax.toString(),
            'important',
          );
        }
      }
    },
    ...appStoreMapper.mapActions(['getAppData']),
  },
  watch: {
    appData() {
      this.updateLazy();

      this.allowInfinite = false;
    },
    appDataDidLoad: {
      handler() {
        this.updateLazy();
      },
      immediate: true,
    },
    // hack
    currentTab(state: string) {
      if (state === config.name) {
        this.allowAutoScrollTop = false;

        setTimeout(() => {
          this.allowAutoScrollTop = true;
        });
      }
    },
  },
});
