import { Actions } from 'vuex-smart-module';

import StampCardGetters from './getters';
import StampCardMutations from './mutations';
import StampCardState from './state';

export default class StampCardActions extends Actions<
  StampCardState,
  StampCardGetters,
  StampCardMutations,
  StampCardActions
> {
  //
}
