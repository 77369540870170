import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';

import { RefState } from './state';

export class RefGetters extends Getters<RefState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get referral(): IModuleSettings | null {
    return this.app.state.appData?.settings?.modules?.referral || null;
  }

  get settings(): IModuleSettings {
    return this.app.state.appData?.settings?.modules?.referral || ({} as IModuleSettings);
  }
}
