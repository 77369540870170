import { render, staticRenderFns } from "./home-slot-button-icon-phone.vue?vue&type=template&id=5f06ebd0&"
var script = {}
import style0 from "./home-slot-button-icon-phone.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.8_wzvh6gi4tchptuexh7cjhim74e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports