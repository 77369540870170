const packageJSON = require('../package.json');
const appDevName = (
  typeof packageJSON.name === 'object' ? packageJSON.name.android : packageJSON.name
)
  .split('.')
  .slice(-1);

let deployConfig = {};

try {
  deployConfig = require('../deploy.json');
} catch (e) {
  // console.log('Deploy config not found, using default settings.');
}

const config = {
  type: 'client',
  lang: {
    default: 'ru',
    list: ['ru', 'en'],
  },
  api: {
    protocol: 'https',
    client: 'gmb',
    host: 'getmeback.ru',
    apiPath: '/rest/mobile/',
    apiVersion: 'v52',
    images: '/rest/image/',
    core: 'https://core.getmeback.ru/api/v2',
  },
  app: {
    name: 'App',
    pwa: false,
    miniapp: false,
  },
  storage: {},
  plugins: {
    logrocketId: 'rrjerg',
    sentry: 'https://74c94b3db71246148bc4c8307679e546@o486392.ingest.sentry.io/5589038',
    sentryProject: 'app-default',
    sentrySourceMapsIntegrationToken:
      '8add204d532a4a558ceb5caf8aa70026b53429a26a4c4bcb9ad0a5942d56f20f',
    onesignalLocalhost: 'ac52c4ed-51b4-44fd-a3d3-f819dc88508e',
  },
  theme: {
    iosTextColor: 'white',
    androidTextColor: 'white',
    iosBackgroundColor: '#000',
    androidBackgroundColor: '#000',
  },
  modules: {
    settings: {
      github: 'getmeback',
    },
    list: [
      'app-home',
      'app-maps',
      'app-panelmenu',
      'app-pages',
      'client-actions',
      'client-auth',
      'client-bonus',
      'client-booking',
      'client-catalog',
      'client-feedback',
      'client-getwallet',
      'client-gifts',
      'client-history',
      'client-news',
      'client-notifications',
      'client-referral',
      'client-related',
      'client-specialoffer',
      'client-stampcard',
    ],
    tabs: [
      'catalog',
      'home',
      'history',
      'feedback',
      'gifts',
      'maps',
      'booking',
      'actions',
      'news',
      'auth',
    ],
    defaultTab: 'home',
  },
  webview: {
    androidWebView:
      'https://play.google.com/store/apps/details?id=com.google.android.webview',
    androidMinimumWebViewVersion: 49,
  },
  datetime: {
    dateFormat: 'd mmmm yyyy',
    dateFormatShort: 'dd.mm.yyyy',
    timeFormat: 'HH:MM',
    timeFormatShort: 'H:MM',
    dateTimeFormatFull: 'dd mmmm yyyy HH:MM',
    dateFormatI18n: {
      dayNames: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб',
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
      ],
      dayNamesFuture: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб',
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среду',
        'Четверг',
        'Пятницу',
        'Субботу',
      ],
      monthNames: [
        'Янв',
        'Фев',
        'Марта',
        'Апр',
        'Мая',
        'Июня',
        'Июля',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
      ],
      timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
    },
  },
  framework7: {
    theme: 'auto',
    routes: [],
    autoDarkTheme: false,
    navbar: {
      mdCenterTitle: true,
    },
    iosTranslucentBars: false,
    touch: {
      iosTouchRipple: true,
      mdTouchRipple: true,
      touchRippleElements: '.ripple',
    },
  },
};

if (deployConfig.lang) {
  if (deployConfig.lang.default) {
    config.lang.default = deployConfig.lang.default;
  }

  if (deployConfig.lang.list) {
    config.lang.list = deployConfig.lang.list;
  }
}

if (deployConfig.app) {
  config.app = { ...config.app, ...deployConfig.app };
}

if (deployConfig.api) {
  config.api = { ...config.api, ...deployConfig.api };
}

if (deployConfig.plugins) {
  config.plugins = { ...config.plugins, ...deployConfig.plugins };
}

if (deployConfig.theme) {
  config.theme = { ...config.theme, ...deployConfig.theme };
}

if (deployConfig.storage) {
  config.storage = { ...config.storage, ...deployConfig.storage };
}

if (Object.keys(config.storage).length === 0) {
  const devName = config.app.devName || appDevName;

  config.storage = {
    name: `_${devName}storage`,
    storeName: `_${devName}kv`,
    dbKey: `_${devName}key`,
    driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage'],
  };
}

if (deployConfig.SplashScreenBackgroundColor) {
  config.SplashScreenBackgroundColor = deployConfig.SplashScreenBackgroundColor;
}

Object.freeze(config);

module.exports = config;
