export interface IIntervalSettings {
  step: number;
  afterStart?: number;
  beforeEnd?: number;
}

function getNextInterval(hours: number, minutes: number, step: number): [number, number] {
  const date = new Date();

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(date.getSeconds() + step);

  return [date.getHours(), date.getMinutes()];
}

export function getPickupIntervals(
  startTime: string,
  endTime: string,
  interval: IIntervalSettings,
): string[] {
  let intervals: number[][] = [];
  const currentInterval = new Date();

  const startTimeParts = startTime.split(':');
  let startTimeHours = parseInt(startTimeParts[0]);
  let startTimeMinutes = parseInt(startTimeParts[1]);

  if (interval.afterStart) {
    const tempStartTime = new Date();
    tempStartTime.setHours(startTimeHours);
    tempStartTime.setMinutes(startTimeMinutes);
    tempStartTime.setSeconds(tempStartTime.getSeconds() + interval.afterStart);

    startTimeHours = tempStartTime.getHours();
    startTimeMinutes = tempStartTime.getMinutes();
  }

  const startTimeUnion = Number(
    startTimeHours.toString().padStart(2, '0') +
      startTimeMinutes.toString().padStart(2, '0'),
  );

  const endTimeParts = endTime.split(':');
  let endTimeHours = parseInt(endTimeParts[0]);
  let endTimeMinutes = parseInt(endTimeParts[1]);

  if (interval.beforeEnd) {
    const tempEndTime = new Date();
    tempEndTime.setHours(endTimeHours);
    tempEndTime.setMinutes(endTimeMinutes);
    tempEndTime.setSeconds(tempEndTime.getSeconds() - interval.beforeEnd);

    endTimeHours = tempEndTime.getHours();
    endTimeMinutes = tempEndTime.getMinutes();
  }

  const endTimeUnion = Number(
    endTimeHours.toString().padStart(2, '0') + endTimeMinutes.toString().padStart(2, '0'),
  );

  currentInterval.setHours(startTimeHours);
  currentInterval.setMinutes(startTimeMinutes);

  const isEndTimeAfterMidnight = endTimeHours >= 0 && endTimeHours < startTimeHours;

  for (let i = 0, k = (24 * 60 * 60) / interval.step; i < k; i++) {
    const currentIntervalHours = currentInterval.getHours();
    const currentIntervalMinutes = currentInterval.getMinutes();

    intervals.push([currentIntervalHours, currentIntervalMinutes]);

    const nextInterval = getNextInterval(
      currentIntervalHours,
      currentIntervalMinutes,
      interval.step,
    );
    currentInterval.setHours(nextInterval[0], nextInterval[1]);
  }

  let startIndex = 0;
  for (const interval of intervals) {
    const intervalUnion = Number(
      interval[0].toString().padStart(2, '0') + interval[1].toString().padStart(2, '0'),
    );

    if (intervalUnion < startTimeUnion) {
      startIndex++;
    } else {
      break;
    }
  }

  intervals = intervals.slice(startIndex);

  const allIntervalsTemp = isEndTimeAfterMidnight ? intervals.reverse() : intervals;
  let endIndex = 0;
  for (const interval of allIntervalsTemp) {
    const intervalUnion = Number(
      interval[0].toString().padStart(2, '0') + interval[1].toString().padStart(2, '0'),
    );

    if (isEndTimeAfterMidnight) {
      if (intervalUnion <= endTimeUnion) {
        break;
      } else {
        endIndex++;
      }
    } else {
      if (intervalUnion > endTimeUnion) {
        break;
      } else {
        endIndex++;
      }
    }
  }

  intervals = isEndTimeAfterMidnight
    ? allIntervalsTemp.slice(endIndex).reverse()
    : allIntervalsTemp.slice(0, endIndex);

  return intervals.map(
    (interval) => `${interval[0]}:${interval[1].toString().padStart(2, '0')}`,
  );
}
