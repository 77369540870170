
import { CreateElement, VNode } from 'vue';
import { f7 } from 'framework7-vue';

import i18n from '@/translate/lang';
import { showCodeDialog } from '../misc';
import { useAppStore } from '@/store/appstore';
import { useRefStore } from '../store';
import bus from '@/bus';

export default {
  functional: true,
  render(h: CreateElement) {
    const appStore = useAppStore();
    const refStore = useRefStore();

    const items: VNode[] = [];

    if (refStore.getters.referral) {
      if (refStore.getters.referral.menuShowShare) {
        items.push(
          h(
            'f7-list-item',
            {
              slot: 'list',
              props: {
                title: i18n.t(`modules.referral.title`),
              },
              on: {
                click: () => {
                  f7.panel.close();

                  if (!appStore.getters.user?.code) {
                    bus.$emit('needRegister:popup', {
                      text: i18n.t('modules.referral.dialog.needRegister.text'),
                      title: i18n.t(`modules.referral.title`),
                    });
                    return false;
                  }

                  f7.views.current.router.navigate('/referral/');
                },
              },
            },
            [
              h('f7-icon', {
                props: {
                  icon: 'far fa-user-friends',
                },
                slot: 'media',
              }),
            ],
          ),
        );
      }

      if (refStore.getters.referral.canEnterCode) {
        items.push(
          h(
            'f7-list-item',
            {
              slot: 'list',
              props: {
                title: i18n.t(`modules.referral.code.title`),
              },
              on: {
                click: () => {
                  showCodeDialog(appStore, refStore);
                },
              },
            },
            [
              h('f7-icon', {
                props: {
                  icon: 'far fa-percentage',
                },
                slot: 'media',
              }),
            ],
          ),
        );
      }
    }

    return items;
  },
};
