import { computed } from 'vue';
import { f7 } from 'framework7-vue';

import bus from '@/bus';
import i18n from '@/translate/lang';
import * as API from '@/modules/auth/dist/api';
import { showErrorDialog } from '@/misc';
import { useAppStore } from '@/store/appstore';
import { useAuthStore } from './store';

export const useAuth = () => {
  const appStore = useAppStore();
  const authStore = useAuthStore();

  const showNavbarIcon = computed<boolean>(
    () =>
      appStore.getters.appSettings.hideMenu ||
      (appStore.getters.miniapp && !!appStore.getters.appSettings.hideMenuMiniapp),
  );

  function _logout(): Promise<void> {
    return new Promise((resolve) => {
      f7.views.current.router.back(f7.views.current.router.history[0], {
        force: true,
      });

      setTimeout(() => {
        authStore.dispatch('logout').then(() => {
          bus.$emit('authAfterLogout');
        });

        resolve();
      }, 100);
    });
  }

  function logout(dialog = true): Promise<void> {
    return new Promise((resolve) => {
      if (dialog) {
        f7.dialog.confirm(
          i18n.t('modules.auth.signout.dialog.text') as string,
          i18n.t('modules.auth.signout.dialog.title') as string,
          () => {
            _logout().then(() => {
              resolve();
            });
          },
        );
      } else {
        _logout().then(() => {
          resolve();
        });
      }
    });
  }

  function deleteUser() {
    const dialog = f7.dialog.create({
      buttons: [
        {
          text: i18n.t('global.dialog.cancel').toString(),
          close: true,
        },
        {
          text: i18n.t('global.dialog.delete').toString(),
          onClick: () => {
            f7.preloader.show();

            API.deleteUser()
              .then(async () => {
                await logout(false);
              })
              .catch((error) => {
                showErrorDialog({ error });
              })
              .finally(() => {
                f7.preloader.hide();
              });
          },
          bold: true,
          close: true,
        },
      ],
      title: i18n.t('modules.auth.delete.dialog.title') as string,
      text: i18n.t('modules.auth.delete.dialog.text') as string,
    });

    dialog && dialog.open();
  }

  return { logout, deleteUser, showNavbarIcon };
};
