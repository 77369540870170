import { IModule } from '@/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from '@/misc';
import config from './related.conf.json';

import store from '@/store';
import RefStore from './store';

import RelatedAppRoot from './components/related-app-root.vue';
import RelatedMenuItem from './components/related-menu-item.vue';
import RelatedHomeSlotButton from './components/related-home-slot-button.vue';
import RelatedLogo from './components/related-logo.vue';

library.add(faGlobe);

export function init() {
  Vue.component('related-app-root', RelatedAppRoot);
  Vue.component('related-menu-item', RelatedMenuItem);
  Vue.component('related-home-slot-button', RelatedHomeSlotButton);
  Vue.component('related-logo', RelatedLogo);

  registerModule(store, ['related'], 'related/', RefStore);

  Misc.registerModuleConfig(config as IModule);
}
