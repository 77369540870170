































































import { ContentLoader } from 'vue-content-loader';
import { defineComponent } from 'vue';

import { IBranch } from '@/types';
import { Gift, Coupon } from '../types';

import appConfig from '@/app.config.js';
import { appStoreMapper } from '@/store/appstore';
import { giftsStoreMapper } from '../store';
import config from '../gifts.conf.json';

import GiftsListEntryItem from './gifts-list-entry-item.vue';
import NavbarSlotLeft from 'src/components/navbar-slot-left.vue';
import NavbarSlotRight from 'src/components/navbar-slot-right.vue';

export default defineComponent({
  name: 'Gifts',
  components: { GiftsListEntryItem, NavbarSlotLeft, NavbarSlotRight, ContentLoader },
  data: () => ({
    config: appConfig,
  }),
  mounted() {
    if (this.currentTab === config.name) {
      this.getGifts();
    }
  },
  destroyed() {
    this.setGifts([]);
    this.setCoupons([]);
    this.setItems([]);
  },
  computed: {
    branches(): IBranch[] {
      return this.appData.branches;
    },
    ...appStoreMapper.mapState([
      'appData',
      'appDataDidLoad',
      'domain',
      'currentTab',
      'authToken',
    ]),
    ...giftsStoreMapper.mapState(['gifts', 'coupons', 'items']),
    ...giftsStoreMapper.mapGetters(['itemsComputed']),
  },
  methods: {
    getItemsByBranchId(id: number | string): Array<Gift | Coupon> {
      return this.itemsComputed.filter(
        (item) => item.idBranch.toString() === id.toString(),
      );
    },
    onPTRRefresh(done: () => void) {
      this.getGifts({ flush: true }).then(() => {
        done();
      });
    },
    ...giftsStoreMapper.mapActions(['getGifts']),
    ...giftsStoreMapper.mapMutations(['setGifts', 'setCoupons', 'setItems']),
  },
  watch: {
    items() {
      if (this.$el) {
        setTimeout(() => {
          this.$f7?.lazy.create(this.$el as HTMLElement);
        }, 100);
      }
    },
    currentTab(value: string) {
      if (value === config.name && !this.gifts.length && !this.coupons.length) {
        this.getGifts();
      }
    },
  },
});
