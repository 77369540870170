declare interface IMessages {
  [key: string]: any;
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import merge from 'lodash/merge';

import { getLanguage } from '@/misc';

Vue.use(VueI18n);

VueI18n.prototype.getChoiceIndex = function (
  choice: number,
  choicesLength: number,
): number {
  // eslint-disable-next-line no-empty
  if (this.locale !== 'ru') {
  }
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};

import global from '@/translate/global';
import actions from '@/modules/actions/dist/translate';
import auth from '@/modules/auth/dist/translate';
import bonus from '@/modules/bonus/dist/translate';
import booking from '@/modules/booking/dist/translate';
import catalog from '@/modules/catalog/dist/translate';
import feedback from '@/modules/feedback/dist/translate';
import getwallet from '@/modules/getwallet/dist/translate';
import gifts from '@/modules/gifts/dist/translate';
import history from '@/modules/history/dist/translate';
import home from '@/modules/home/dist/translate';
import maps from '@/modules/maps/dist/translate';
import news from '@/modules/news/dist/translate';
import notifications from '@/modules/notifications/dist/translate';
import pages from '@/modules/pages/dist/translate';
import panelmenu from '@/modules/panelmenu/dist/translate';
import referral from '@/modules/referral/dist/translate';
import related from '@/modules/related/dist/translate';
import specialoffer from '@/modules/specialoffer/dist/translate';
import stampcard from '@/modules/stampcard/dist/translate';
import custom from '@/translate/custom.yaml';

const messages: IMessages = localizely2i18n(
  merge(
    global,
    actions,
    auth,
    bonus,
    booking,
    catalog,
    feedback,
    getwallet,
    gifts,
    history,
    home,
    maps,
    news,
    notifications,
    pages,
    panelmenu,
    referral,
    related,
    specialoffer,
    stampcard,
    custom, // Обязательно должен быть в конце!
  ),
);

function localizely2i18n(messages: IMessages) {
  Object.keys(messages).forEach((key) => {
    if (messages[key] != null && typeof messages[key] !== 'string' && key !== 'plural') {
      localizely2i18n(messages[key]);
    } else if (typeof messages[key] === 'object' && key === 'plural') {
      messages[key] = Object.values(messages[key]).join(' | ');
    }
  });

  return messages;
}

export default new VueI18n({
  locale: getLanguage(),
  messages,
});
