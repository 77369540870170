













import { ISpecialOffer } from '../types';

import Vue, { PropType } from 'vue';

import { appStoreMapper } from '@/store/appstore';
import { specialOfferStoreMapper } from '../store';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';

import { mixin } from '../composition';

import CatalogItem from 'src/components/CatalogItem.vue';

export default Vue.extend({
  name: 'SpecialOffersListEntryItem',
  components: { CatalogItem },
  props: {
    offer: Object as PropType<ISpecialOffer>,
  },
  setup(props) {
    return { ...mixin(props as any) };
  },
  computed: {
    image(): string {
      return this.offer.image
        ? this.fullImagePath(
            getImageThumbnailPath(this.offer.image, { deviceWidth: this.deviceWidth }),
          )
        : '';
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...specialOfferStoreMapper.mapGetters(['settings']),
  },
});
