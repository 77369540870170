


















































import Vue, { PropType } from 'vue';
import { ContentLoader } from 'vue-content-loader';

import { Coupon, Gift, GiftType } from '../types';

import { giftsStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';
import { getCoupon, getGift } from '../api';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';
import { useBrightness } from '@/composition';
import config from '@/app.config.js';
import bus from '@/bus';
import { sendStat } from '@/misc';

import ContentBlock from '@/components/content.vue';
import ThePopup from '@/components/ThePopup.vue';
import TheBarWrapper from '@/components/TheBarWrapper.vue';
import GiftButtonAdd from './gift-button-add.vue';
import GiftCodeContent from './gift-code-content.vue';

let brightnessTurnOn: () => void;
let brightnessTurnOff: () => void;

export default Vue.extend({
  name: 'GiftPopup',
  inheritAttrs: false,
  components: {
    ContentBlock,
    ThePopup,
    TheBarWrapper,
    GiftButtonAdd,
    ContentLoader,
    GiftCodeContent,
  },
  props: {
    item: Object as PropType<Nullable<Gift>>,
    id: {
      type: Number as PropType<number>,
      required: true,
    },
    type: {
      type: String as PropType<GiftType>,
      default: 'gift',
    },
  },
  setup() {
    const { brightnessTurnOn: turnOn, brightnessTurnOff: turnOff } = useBrightness();

    brightnessTurnOn = turnOn;
    brightnessTurnOff = turnOff;

    return {
      config,
    };
  },
  data: () => ({
    gift: null as Nullable<Gift>,
    coupon: null as Nullable<Coupon>,
    showCode: false,
    brightnessHigh: false,
    loading: false,
    error: false,
  }),
  mounted() {
    if (this.item) {
      if (this.type === 'gift') {
        this.gift = this.item;
      } else {
        this.coupon = this.item;
      }
    } else {
      this.getGift();
    }

    if (this.type === 'gift') {
      sendStat({ action: 'show-gift', id: this.id }, this.$store as any);
    } else {
      sendStat({ action: 'show-coupon', id: this.id }, this.$store as any);
    }
  },
  computed: {
    current(): Nullable<Gift | Coupon> {
      return this.gift || this.coupon;
    },
    images(): string[] {
      return this.current?.icon && !this.showCode
        ? [
            this.fullImagePath(
              getImageThumbnailPath(this.current.icon, 'scale', {
                width: this.deviceWidth,
                height: 0,
              }),
            ),
          ]
        : [];
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...giftsStoreMapper.mapState(['gifts', 'coupons']),
  },
  methods: {
    async getGift() {
      this.error = false;

      if (this.type === 'gift') {
        this.gift = this.getGiftById(this.id);
      } else {
        this.coupon = this.getCouponById(this.id);
      }

      if (!this.current) {
        this.loading = true;

        try {
          if (this.type === 'gift') {
            this.gift = await getGift(this.id);
          } else {
            this.coupon = await getCoupon(this.id);
          }

          if (!this.current) {
            this.error = true;
          }
        } catch (e) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    },
    onReload() {
      this.getGift();
    },
    getGiftById(id: number): Nullable<Gift> {
      return this.gifts.find((gift) => gift.id === id);
    },
    getCouponById(id: number): Nullable<Gift> {
      return this.coupons.find((coupon) => coupon.id === id);
    },
    onConfirm() {
      this.showCode = true;

      this.brightnessHigh = true;
      brightnessTurnOn();

      bus.$emit('bonus:spent:add', this.current?.price || 0);
    },
    onDone() {
      this.$f7.popup.close('.popup-universal');
    },
    onPopupClose() {
      if (this.brightnessHigh) {
        this.brightnessHigh = false;

        brightnessTurnOff();
      }
    },
  },
});
