import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import NotificationsState from '@/modules/notifications/dist/store/state';

export default class NotificationsGetters extends Getters<NotificationsState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get tagsArray(): string[] {
    return Object.keys(this.state.tags);
  }

  get settings(): IModuleSettings {
    return this.app.state.appData?.settings?.modules?.notifications || { onesignal: '' };
  }
}
