import { idAction, IActionItem, IActionItems, IActionActivateResponse } from '../types';

import * as API from 'src/api';

const API_ENDPOINT_ACTIONS = 'actions';
const API_ENDPOINT_ACTIVATE = 'action-activate-bonus';

export async function getActions({
  offset,
  limit,
}: {
  offset?: number;
  limit?: number;
} = {}): Promise<IActionItem[]> {
  return API.request<IActionItems>({
    method: 'get',
    url: API_ENDPOINT_ACTIONS,
    params: { limit, offset },
  }).then((response) => response.actions);
}

export async function getAction(id: idAction): Promise<Nullable<IActionItem>> {
  return API.request<IActionItems>({
    method: 'get',
    url: API_ENDPOINT_ACTIONS,
    params: { id },
  }).then((response) => response.actions[0]);
}

export async function activateAction(id: idAction): Promise<IActionActivateResponse> {
  return API.request<IActionActivateResponse>({
    method: 'post',
    url: API_ENDPOINT_ACTIVATE,
    data: { id },
  });
}
