import VueI18n from 'vue-i18n';

import { IBranch } from '@/types';

import config from '@/app.config.js';

function _getBranchScheduleFormatted(branch: IBranch, i18n: VueI18n): string {
  const result = [];

  // TODO: Hardcoded exception, remove after Working Days implementation
  if (branch.startTime === branch.endTime && (!branch.days || branch.days.length === 7)) {
    return i18n.t('global.branch.workingTime.allDay').toString();
  }

  for (let day = 1; day <= 7; day++) {
    let line = config.datetime.dateFormatI18n.dayNames[day < 7 ? day : 0] + ': ';

    const workingTimeDay = branch.workingTime.find((time) => time.day === day);
    if (workingTimeDay) {
      line += workingTimeDay.startTime + ' - ' + workingTimeDay.endTime;
    } else {
      line += i18n.t('global.branch.workingTime.dayOff').toString();
    }

    result.push(line);
  }

  return result.join('<br/>');
}

export default _getBranchScheduleFormatted;
