











import Vue from 'vue';

import { getWalletStoreMapper } from '../store';
import { openUrl } from 'src/misc';

export default Vue.extend({
  name: 'GetWalletHomeSlot',
  computed: {
    ...getWalletStoreMapper.mapGetters(['settings']),
  },
  methods: {
    onClick(e: MouseEvent) {
      let url: Nullable<string> = this.$theme.ios
        ? this.settings?.ios
        : this.settings?.android;

      if (!url) {
        url = this.settings?.url;
      }

      if (url) {
        openUrl(url, '_system', e);
      }
    },
  },
});
