
import { CreateElement, RenderContext, VNode } from 'vue';
import { f7 } from 'framework7-vue';

import { IUser } from '@/types';
import { IModuleSettings } from '../types';

import config from '../notifications.conf.json';
import store from 'src/store';

export default {
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode[] {
    const settings = store.getters['notifications/settings'] as IModuleSettings;
    const user = store.getters['user'] as IUser;
    const items = [
      h(
        'f7-list-item',
        {
          slot: 'list',
          key: 'notifications-menu-item',
          props: {
            title: settings?.title || context.parent.$t(`modules.notifications.title`),
          },
          on: {
            click: () => {
              f7.panel.close();
              f7.views.current.router.navigate({ path: config.url });
            },
          },
        },
        [
          h('div', {
            class: 'icon',
            slot: 'media',
          }),
        ],
      ),
    ];

    if (!user.idUser) {
      return [];
    }

    return items;
  },
};
