































































import Vue from 'vue';

import config from '@/app.config.js';
import { appStoreMapper } from '@/store/appstore';
import * as API from '@/api';
import bus from '@/bus';
import { formatDate } from '@/utils';

export default Vue.extend({
  name: 'DebugControl',
  computed: {
    show(): boolean {
      return window.devMode;
    },
    cardToggledByDefault(): boolean {
      return this.appData.settings.modules.bonus?.homeBonusButtonType === 'card';
    },
    ...appStoreMapper.mapState(['debugControl', 'appData', 'locale', 'host']),
  },
  methods: {
    formatDate,
    onClick() {
      this.setDebugControl({ ...this.debugControl, active: !this.debugControl.active });
    },
    toggleCardView() {
      this.setDebugControl({
        ...this.debugControl,
        cardView: !this.debugControl.cardView,
      });

      const cardView = this.debugControl.cardView;
      const appData = JSON.parse(JSON.stringify(this.appData));

      appData.settings.modules.app.smallLogo = cardView;
      appData.settings.modules.app.hideLogo = cardView;

      if (appData.settings.modules.bonus) {
        appData.settings.modules.bonus.homeBonusButtonType = cardView ? 'card' : 'button';
      }

      this.setAppData(appData);
    },
    onSelectHost(value: 'client' | 'gmb' | 'dev') {
      const domain =
        value === 'client'
          ? config.api.client
          : value === 'gmb'
          ? 'gmb'
          : 'front';

      this.setHost(value);
      this.setDomain(domain);

      API.setBaseUrl(domain, this.host);
      this.getAppData(bus).finally(() => {
        bus.$emit('home-ptr');
      });
    },
    onLocaleClick(value: string) {
      this.changeLocale(value);
    },
    ...appStoreMapper.mapMutations([
      'setDebugControl',
      'setAppData',
      'setHost',
      'setDomain',
    ]),
    ...appStoreMapper.mapActions(['getAppData', 'changeLocale']),
  },
});
