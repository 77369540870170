import { IBranch } from '@/types';

type ScheduleItem = {
  start: string;
  end: string;
  workingTime: Nullable<WorkingTime>;
};

function _checkWorkingTime({
  branchOrSchedule,
  timezoneOptions,
  detailed,
}: {
  branchOrSchedule: Nullable<IBranch | WorkingTime[]>;
  timezoneOptions: ITimeZoneOptions;
  detailed?: boolean;
}): boolean | WorkingSchedule {
  const now = new Date();
  let schedule: WorkingTime[];

  if (branchOrSchedule && !Array.isArray(branchOrSchedule)) {
    schedule = branchOrSchedule.workingTime || [];
  } else {
    schedule = branchOrSchedule || [];
  }

  const scheduleMatrix: Nullable<ScheduleItem>[][] = [];
  let extraWorkingHoursCount = 0;
  let extraWorkingHoursEndMinutes = 0;
  let extraWorkingHoursWorkingTime: Nullable<WorkingTime> = null;

  for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
    const dayMatrix: Nullable<ScheduleItem>[] = [];
    const currentDaySchedule = schedule.find((sched) => sched.day === dayOfWeek);

    if (currentDaySchedule) {
      const startTime = currentDaySchedule.startTime.split(':');
      const endTime = currentDaySchedule.endTime.split(':');
      const timeStartHours = parseInt(startTime?.[0] || '0');
      const timeStartMinutes = parseInt(startTime?.[1] || '0');
      const timeEndHours = parseInt(endTime?.[0] || '0');
      const timeEndMinutes = parseInt(endTime?.[1] || '0');
      const isEndTimeAfterMidnight = timeEndHours >= 0 && timeEndHours < timeStartHours;
      let thisDayWorkingHoursTotal =
        (isEndTimeAfterMidnight
          ? 24 - timeStartHours + timeEndHours
          : timeEndHours - timeStartHours) + (timeEndMinutes ? 1 : 0);

      for (let hour = 0; hour <= 23; hour++) {
        if (hour >= timeStartHours && thisDayWorkingHoursTotal) {
          dayMatrix.push({
            start:
              hour.toString().padStart(2, '0') +
              ':' +
              (hour === timeStartHours
                ? timeStartMinutes.toString().padStart(2, '0')
                : '00'),
            end:
              hour.toString().padStart(2, '0') +
              ':' +
              (hour === timeEndHours ? timeEndMinutes.toString().padStart(2, '0') : '59'),
            workingTime: currentDaySchedule,
          });

          thisDayWorkingHoursTotal--;
        } else {
          if (extraWorkingHoursCount) {
            dayMatrix.push({
              start: hour.toString().padStart(2, '0') + ':' + '00',
              end:
                hour.toString().padStart(2, '0') +
                ':' +
                (extraWorkingHoursCount
                  ? extraWorkingHoursEndMinutes.toString().padStart(2, '0')
                  : '59'),
              workingTime: extraWorkingHoursWorkingTime,
            });

            extraWorkingHoursCount--;
          } else {
            dayMatrix.push(null);
          }
        }
      }

      if (thisDayWorkingHoursTotal) {
        extraWorkingHoursCount = thisDayWorkingHoursTotal;
        extraWorkingHoursEndMinutes = timeEndMinutes;
        extraWorkingHoursWorkingTime = currentDaySchedule;
      }
    } else {
      for (let hour = 0; hour <= 23; hour++) {
        dayMatrix.push(null);
      }
    }

    scheduleMatrix.push(dayMatrix);
  }

  // Добиваем оставшиеся часы в начало расписания (понедельник после полуночи)
  if (extraWorkingHoursCount) {
    for (let hour = 0; hour < extraWorkingHoursCount; hour++) {
      scheduleMatrix[0][hour] = {
        start: hour.toString().padStart(2, '0') + ':' + '00',
        end:
          hour.toString().padStart(2, '0') +
          ':' +
          (hour === extraWorkingHoursCount - 1
            ? extraWorkingHoursEndMinutes.toString().padStart(2, '0')
            : '59'),
        workingTime: schedule.find((sched) => sched.day === 7),
      };
    }
  }

  const timezoneOffsetInSeconds =-1 * now.getTimezoneOffset() * 60;
  const timezoneOffsetDiff = timezoneOptions.sourceTimeZone - timezoneOffsetInSeconds;

  now.setSeconds(now.getSeconds() + timezoneOffsetDiff);

  const currentDay = [6, 0, 1, 2, 3, 4, 5][now.getDay()];
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();
  const todaySchedule = scheduleMatrix[currentDay];
  let isOpened = false;

  if (todaySchedule[currentHour]) {
    const hourStartMinutes = parseInt(
      todaySchedule[currentHour]!.start.split(':')[1] ?? '00',
      10,
    );
    const hourEndMinutes = parseInt(
      todaySchedule[currentHour]!.end.split(':')[1] ?? '59',
      10,
    );

    isOpened = currentMinutes >= hourStartMinutes && currentMinutes <= hourEndMinutes;
  }

  if (detailed) {
    const todayWorkingTime: Nullable<WorkingTime> =
      todaySchedule[currentHour]?.workingTime || null;
    let willBeOpened: Nullable<WorkingTime> = null;

    if (!isOpened) {
      // Будет ли открыто уже сегодня?
      for (let hour = currentHour + 1; hour <= 23; hour++) {
        if (todaySchedule[hour]) {
          willBeOpened = schedule.find((sched) => sched.day === currentDay + 1);
        }
      }

      if (!willBeOpened) {
        for (let dayOfWeek = currentDay + 2; dayOfWeek <= 7; dayOfWeek++) {
          const dayOfWeekSchedule = schedule.find((sched) => sched.day === dayOfWeek);
          if (!willBeOpened && dayOfWeekSchedule) {
            willBeOpened = dayOfWeekSchedule;
          }
        }

        if (!willBeOpened) {
          for (let dayOfWeek = 1; dayOfWeek < currentDay + 1; dayOfWeek++) {
            const dayOfWeekSchedule = schedule.find((sched) => sched.day === dayOfWeek);
            if (!willBeOpened && dayOfWeekSchedule) {
              willBeOpened = dayOfWeekSchedule;
            }
          }
        }
      }
    }

    return {
      opened: isOpened,
      willBeOpened,
      todayWorkingTime,
    };
  }

  return isOpened;
}

export default _checkWorkingTime;
