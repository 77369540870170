



















import Vue, { PropType } from 'vue';

import { idNews, INewsItem } from '../types';

import { newsStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';
import { getAnnounce } from '../api';
import { sendStat } from '@/misc';
import i18n from '@/translate/lang';
import { getImageThumbnailPath } from '@/utils';

import ContentBlock from '@/components/content.vue';
import ThePopup from '@/components/ThePopup.vue';

export default Vue.extend({
  name: 'ActionsPopup',
  inheritAttrs: false,
  components: { ContentBlock, ThePopup },
  props: {
    item: Object as PropType<Nullable<INewsItem>>,
    id: {
      type: [Number, String] as PropType<number>,
      required: true,
    },
  },
  data: () => ({
    announce: null as Nullable<INewsItem>,
    loading: false,
    errorText: '',
  }),
  mounted() {
    if (this.item) {
      this.announce = this.item;
    } else {
      this.getNews();
    }

    sendStat({ action: 'show-news', id: Number(this.id) }, this.$store as any);
  },
  computed: {
    images(): string[] {
      return this.announce?.icon
        ? [
            this.fullImagePath(
              getImageThumbnailPath(this.announce.icon, 'scale', {
                width: this.deviceWidth,
                height: 0,
              }),
            ),
          ]
        : [];
    },
    date(): string {
      return this.formattedDate(this.announce?.date, this.locale);
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['locale']),
    ...newsStoreMapper.mapState(['newsList']),
    ...newsStoreMapper.mapGetters(['formattedDate']),
  },
  methods: {
    async getNews() {
      this.errorText = '';
      this.announce = this.getAnnounceById(this.id);

      if (!this.announce) {
        this.loading = true;

        try {
          this.announce = await getAnnounce(this.id);

          if (!this.announce) {
            this.errorText = i18n.t('modules.news.body.notFound').toString();
          }
        } catch (e) {
          this.errorText =
            (e as unknown as any).error ||
            i18n.t('modules.news.body.notFound').toString();
        } finally {
          this.loading = false;
        }
      }
    },
    onReload() {
      this.getNews();
    },
    getAnnounceById(id: idNews): Nullable<INewsItem> {
      return this.newsList.find((announce) => announce.idNews == id);
    },
  },
});
