interface IBuildGeocoderValueOptions {
  format: GeocoderBuildFormat;
  templates?: Nullable<GeocoderBuildTemplates>;
}

function processTemplate(template: string, suggestion: Suggest): string {
  const templateBlocks = template.split(',');
  const resultBlocks: string[] = [];

  templateBlocks.forEach((block) => {
    const blocks = block.match(/\[.+?]/g);
    if (blocks) {
      const parts: string[] = [];
      const resultParts: string[] = [];
      blocks.forEach((block) => {
        parts.push(block.replace(/[\[\]]/g, ''));
      });
      parts.forEach((part) => {
        const key = part.replace(/\W/g, '') as keyof AddressParts;
        if (key && suggestion[key]) {
          resultParts.push(part.replace(`{{${key}}}`, suggestion[key]!));
        }
      });
      if (resultParts.length) resultBlocks.push(resultParts.join(' ').trim());
    }
  });

  return resultBlocks.join(', ');
}

export function buildGeocoderValue(
  suggestion: Suggest,
  options?: IBuildGeocoderValueOptions,
): GeocoderValues {
  const templates: { [key: string]: GeocoderBuildTemplates } = {
    ru: {
      address: `[{{streetType}}.] [{{street}}], [{{houseType}}.] [{{house}}], [{{blockType}}.] [{{block}}]`,
      city: `[{{city}}] [{{settlement}}]`,
    },
  };
  let templateAddress: Nullable<string>;
  let templateCity: Nullable<string>;
  let templateValue: Nullable<string>;

  if (options?.templates) {
    templateAddress = options.templates.address;
    templateCity = options.templates.city;
    templateValue = options.templates.value;
  } else if (options?.format && templates[options?.format]) {
    templateAddress = templates[options.format].address;
    templateCity = templates[options.format].city;
  }

  let builtAddress: string;
  if (typeof templateAddress === 'undefined') {
    // Fallback
    builtAddress = suggestion.fallbackAddress || '';
  } else if (templateAddress === null) {
    // Ignore
    builtAddress = '';
  } else {
    // Process or fallback if bad template
    builtAddress =
      processTemplate(templateAddress, suggestion) || suggestion.fallbackAddress || '';
  }

  let builtCity: string;
  if (typeof templateCity === 'undefined') {
    // Fallback
    builtCity = suggestion.fallbackCity || '';
  } else if (templateCity === null) {
    // Ignore
    builtCity = '';
  } else {
    // Process or fallback if bad template
    builtCity =
      processTemplate(templateCity, suggestion) || suggestion.fallbackCity || '';
  }

  const valueParts = [];
  if (builtCity) valueParts.push(builtCity);
  if (builtAddress) valueParts.push(builtAddress);

  let builtValue: string;
  if (templateValue) {
    // Process or fallback if bad template
    builtValue = processTemplate(templateValue, suggestion);
  } else {
    builtValue = valueParts.join(', ');
  }

  return {
    fallbackAddress: builtAddress,
    fallbackCity: builtCity,
    value: builtValue || suggestion.value || '',
  };
}
