
import { IPage } from '../types';

import { CreateElement, RenderContext, VNode } from 'vue';

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import store from 'src/store';

library.add(faFileAlt);

export default {
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode[] {
    const items: VNode[] = [];

    store.state.pages.pages?.forEach((page: IPage) => {
      items.push(
        h(
          'f7-list-item',
          {
            slot: 'list',
            props: {
              title: page.title,
            },
            on: {
              click: () => {
                context.parent.$f7?.panel.close();

                store.dispatch('pages/openPage', { id: page.idContent });
              },
            },
          },
          [
            h('f7-icon', {
              props: {
                icon: 'far fa-file-alt',
              },
              slot: 'media',
            }),
          ],
        ),
      );
    });

    return items;
  },
};
