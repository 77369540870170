










import Vue from 'vue';

import { OSNotification } from 'onesignal-cordova-plugin';
import { GiftType } from '../types';

import bus from 'src/bus';

import GiftPopup from './gift-popup.vue';

export default Vue.extend({
  components: {
    GiftPopup,
  },
  data: () => ({
    id: null as Nullable<number>,
    type: null as Nullable<GiftType>,
    popupOpened: false,
  }),
  destroyed() {
    bus.$off('gift-push', this.handlePush);
  },
  mounted() {
    bus.$on('gift-push', this.handlePush);
  },
  methods: {
    async handlePush(notification: OSNotification) {
      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;
      const [type, id] = additionalData?.idConnectedEntity?.split('-') as [
        GiftType,
        string,
      ];
      this.id = Number(id);
      this.type = type;
      this.popupOpened = !!this.id;
    },
  },
});
