import { IUser } from '@/types';

export default class AuthState {
  authPending = false;
  registeringDevice = false;
  resetPasswordDelay: Date | null = null;
  ageCheck = false;
  afterRegisterCallback: Nullable<(user: IUser) => void> = null;
  afterLoginCallback: Nullable<(user: IUser) => void> = null;
}
