




import { defineComponent } from 'vue';

import { catalogStoreMapper } from '../store';

import CatalogBarWrapper from './CatalogBarWrapper.vue';

export default defineComponent({
  name: 'CatalogHomeSlotPadding',
  components: { CatalogBarWrapper },
  computed: {
    isVisible(): boolean {
      return (
        !!this.settings.showBarOnHomeScreen &&
        !!this.settings.showBasket &&
        !!this.totalProducts
      );
    },
    ...catalogStoreMapper.mapGetters(['settings', 'totalProducts']),
  },
});
