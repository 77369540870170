import { Actions } from 'vuex-smart-module';

import { Gift, IGetCouponsResponse } from '../types';

import * as API from '../api';

import GiftsState from './state';
import GiftsGetters from './getters';
import GiftsMutations from './mutations';

let getGiftsPromise: any = null;

export default class GiftsActions extends Actions<
  GiftsState,
  GiftsGetters,
  GiftsMutations,
  GiftsActions
> {
  getGiftById(id: number): Promise<Nullable<Gift>> {
    const gift = this.state.gifts.find((gift) => gift.id === id);

    if (gift) {
      return Promise.resolve(gift);
    }

    return API.getGift(id);
  }

  getCouponById(id: number): Promise<Nullable<Gift>> {
    const coupon = this.state.coupons.find((coupon) => coupon.id === id);

    if (coupon) {
      return Promise.resolve(coupon);
    }

    return API.getCoupon(id);
  }

  getGifts({ flush }: { flush?: boolean } = {}): Promise<IGetCouponsResponse> {
    if (getGiftsPromise) {
      return getGiftsPromise;
    }

    if (flush) {
      this.commit('setGifts', []);
      this.commit('setCoupons', []);
      this.commit('setItems', []);
    }

    return (getGiftsPromise = API.getGifts().then((response) => {
      this.commit('setGifts', response.gift);
      this.commit('setCoupons', response.coupon);
      this.commit('setItems', response.items);

      return response;
    })).finally(() => {
      getGiftsPromise = null;
    });
  }
}
