
import { defineComponent, h, useCssModule } from 'vue';
import { f7 } from 'framework7-vue';

import config from '../maps.conf.json';
import { appStoreMapper } from 'src/store/appstore';
import { mapsStoreMapper } from '../store';
import i18n from '@/translate/lang';

export default defineComponent({
  name: 'MapsTabbarItem',
  render() {
    const style = useCssModule();

    return h('f7-link', {
      class: [style.link],
      props: {
        key: config.name,
        href: config.url,
        text: this.settings?.tabbarTitle || i18n.t(`modules.${config.name}.tabbarTitle`),
        'tab-link': true,
      },
      on: {
        click: () => {
          f7.views.current.router.navigate(config.url);
        },
      },
    });
  },
  computed: {
    ...appStoreMapper.mapGetters(['defaultTab']),
    ...mapsStoreMapper.mapGetters(['settings']),
  },
});
