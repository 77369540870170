import { Module, createMapper, createComposable } from 'vuex-smart-module';

import HomeGetters from './getters';
import HomeState from './state';

const module = new Module({
  getters: HomeGetters,
  namespaced: true,
  state: HomeState,
});

export default module;

export const homeStoreMapper = createMapper(module);
export const useHomeStore = createComposable(module);
