import { createMapper, Module } from 'vuex-smart-module';

import HistoryActions from './actions';
import HistoryGetters from './getters';
import HistoryMutations from './mutations';
import HistoryState from './state';

const module = new Module({
  actions: HistoryActions,
  getters: HistoryGetters,
  mutations: HistoryMutations,
  namespaced: true,
  state: HistoryState,
});

export default module;

export const historyStoreMapper = createMapper(module);
