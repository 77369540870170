import { ISwitchRelatedRequest, SwitchRelatedResponse } from '../types';

import * as API from '@/api';

const API_ENDPOINT_SWITCH = 'send-client-to-related';

export async function switchDomain(
  data: ISwitchRelatedRequest,
): Promise<SwitchRelatedResponse> {
  return API.request<SwitchRelatedResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_SWITCH,
  });
}
