import { formatNumber } from '@/utils/formatNumber';

interface IOptions {
  delimiter?: string;
  separator?: string;
  format?: boolean;
  useMils?: boolean;
  onlyNumber?: boolean;
}

function _getLocalizedPrice(
  price: number,
  currency: ICurrencyI18n,
  decimalRoundingRuleOrOptions?: decimalRoundingRule | IOptions,
  decimal: number = 0,
  options?: IOptions,
) {
  const prefix =
    currency.position === 'prefix'
      ? currency.sign
      : currency.prefix
      ? currency.prefix
      : '';
  const postfix =
    currency.position === 'postfix'
      ? currency.sign
      : currency.postfix
      ? currency.postfix
      : '';
  const spaceBeforePostfix = postfix ? ' ' : '';

  let decimalRoundingRule: decimalRoundingRule = 'default';

  if (typeof decimalRoundingRuleOrOptions === 'string') {
    decimalRoundingRule = decimalRoundingRuleOrOptions;
  } else {
    options = decimalRoundingRuleOrOptions;
  }

  if (!options) options = {};
  options.format = options.format ?? true;

  const resultPrice = options.format
    ? formatNumber(price, decimalRoundingRule, decimal, {
        delimiter: options.delimiter,
        separator: options.separator,
      })
    : price;

  return !!options.onlyNumber
    ? resultPrice.toString()
    : prefix + resultPrice + spaceBeforePostfix + postfix;
}

export default _getLocalizedPrice;
