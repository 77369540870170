
import { defineComponent, h, nextTick, useCssModule } from 'vue';
import { f7 } from 'framework7-vue';

import { getModule } from 'src/misc';
import { useAppStore } from 'src/store/appstore';
import i18n from '@/translate/lang';

const defaultModules = ['home'];
const ignoreModules = ['panelmenu'];

function getItems() {
  let items: any = [];

  const appStore = useAppStore();

  appStore.state.appData.settings?.menu.forEach((module: string) => {
    const m = getModule(module);

    if (m) {
      // @ts-ignore
      const appDataModuleSettings = appStore.state.appData.settings.modules[module] as Nullable<{
        tab: Nullable<boolean>;
        title: string;
      }>;
      const moduleAsTab = m.tabName && (appDataModuleSettings?.tab ?? true);

      if (
        !ignoreModules.includes(module) &&
        (appDataModuleSettings || defaultModules.includes(module))
      ) {
        if (m.menuItem) {
          items.push(h(m.menuItem, { slot: 'list', key: module }));
        } else {
          const title = appDataModuleSettings?.title || i18n.t(`modules.${module}.title`);

          items.push(
            h(
              'f7-list-item',
              {
                slot: 'list',
                key: module,
                props: {
                  title,
                  link: m.panelMenu && m.url,
                },
                class: `panelmenu-${m.name}`,
                on: !m.panelMenu
                  ? {
                      click: () => {
                        if (moduleAsTab) {
                          f7.tab.show(`#${m.tabName}`);
                        } else {
                          nextTick(() => {
                            m.url && f7.views.current.router.navigate(m.url);
                          });
                        }

                        f7.panel.close();
                      },
                    }
                  : undefined,
              },
              [
                h('f7-icon', {
                  props: {
                    icon: m.menuIcon,
                  },
                  slot: 'media',
                }),
              ],
            ),
          );
        }
      }
    }
  });

  return items;
}

export default defineComponent({
  name: 'PanelmenuList',
  render() {
    const $style = useCssModule();

    return h(
      'f7-list',
      {
        class: $style.menu,
      },
      getItems(),
    );
  },
});
