import * as LocalForage from 'localforage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import appConfig from 'src/app.config.js';

class Storage {
  readonly _dbPromise: Promise<LocalForage>;
  private LFDriver: any = null;

  constructor(config: StorageConfig) {
    this._dbPromise = new Promise((resolve) => {
      const defaultConfig = getDefaultConfig();
      const actualConfig = Object.assign(defaultConfig, config || {});
      const dbsAlreadyCopied = !!localStorage.getItem('dbs-copied');

      const initDbs: Promise<LocalForage>[] = [this._createDB(actualConfig)];

      if (!dbsAlreadyCopied) {
        initDbs.push(
          this._createDB(
            Object.assign({}, actualConfig, {
              driverOrder: ['indexeddb', 'localstorage'],
            }),
          ),
        );

        initDbs.push(
          this._createDB(
            Object.assign({}, actualConfig, {
              driverOrder: ['websql', 'localstorage'],
            }),
          ),
        );

        initDbs.push(
          this._createDB(
            Object.assign({}, actualConfig, {
              driverOrder: ['localstorage'],
            }),
          ),
        );
      }

      Promise.all(initDbs).then(async (dbs) => {
        const preferableDB = dbs[0];
        const existingData = await this.getAllDataFromDBs(
          preferableDB,
          dbs,
          dbsAlreadyCopied,
        );

        if (!window.storage) {
          window.storage = {};
        }

        Object.keys(existingData).forEach((key) => {
          window.storage[key] = existingData[key];
        });

        if (!localStorage.getItem('dbs-copied')) {
          localStorage.setItem('dbs-copied', '1');
          localStorage.setItem(
            `${appConfig.api.client}-storage`,
            JSON.stringify(window.storage),
          );
        }

        resolve(preferableDB);
      });
    });
  }

  async getAllDataFromDBs(
    preferableDb: LocalForage,
    dbs: LocalForage[],
    clearLS?: Boolean,
  ): Promise<{ [key: string]: any }> {
    let data: any = {};

    for (const db of dbs) {
      const driver = db.driver();

      const thisDBData: { [key: string]: any } = {};

      if (typeof db.iterate === 'function') {
        await db.iterate((value, key) => {
          thisDBData[key] = value;
        });
      }

      data = Object.assign(data, thisDBData);

      // Чистим БД чтобы в будущем случайно не скопировать устаревшие данные и
      // чтобы при удалении authToken (logout) приложение не стало его искать в LS.
      if (
        driver !== preferableDb.driver() &&
        (clearLS || driver !== 'localStorageWrapper')
      ) {
        db.clear();
      }
    }

    return data;
  }

  private _createDB(cfg: StorageConfig): Promise<LocalForage> {
    let db: LocalForage;
    let LF = this.LFDriver;

    if (!LF) {
      LF = LocalForage.defineDriver?.(CordovaSQLiteDriver);
      this.LFDriver = LF;
    }

    return new Promise((resolve) => {
      LF?.then(() => {
        db = LocalForage.createInstance(cfg);
      })
        .then(() => db.setDriver(this._getDriverOrder(cfg.driverOrder)))
        .then(() => {
          resolve(db);
        })
        .catch((e: any) => resolve(e));
    });
  }

  ready(): Promise<LocalForage> {
    return this._dbPromise;
  }

  private _getDriverOrder(driverOrder: string[]): string[] {
    return driverOrder.map((driver) => {
      switch (driver) {
        case 'sqlite':
          return CordovaSQLiteDriver._driver;
        case 'indexeddb':
          return LocalForage.INDEXEDDB;
        case 'websql':
          return LocalForage.WEBSQL;
        case 'localstorage':
          return LocalForage.LOCALSTORAGE;
        default:
          return LocalForage.LOCALSTORAGE;
      }
    });
  }

  get(key: string): Promise<any> {
    return this._dbPromise.then((db) => db.getItem(key));
  }

  getSync<T>(key: string): T {
    return window.storage?.[key];
  }

  set(key: string, value: any): Promise<any> {
    return this._dbPromise.then((db) => {
      return db.setItem(key, value);
    });
  }

  setSync(key: string, value: any) {
    if (!window.storage) {
      window.storage = {};
    }

    window.storage[key] = value;

    localStorage.setItem(
      `${appConfig.api.client}-storage`,
      JSON.stringify(window.storage),
    );

    this._dbPromise.then((db) => db.setItem(key, value));
  }

  remove(key: string): Promise<any> {
    return this._dbPromise.then((db) => db.removeItem(key));
  }

  clear(): Promise<void> {
    return this._dbPromise.then((db) => db.clear());
  }

  length(): Promise<number> {
    return this._dbPromise.then((db) => db.length());
  }

  keys(): Promise<string[]> {
    return this._dbPromise.then((db) => db.keys());
  }

  forEach(
    iteratorCallback: (value: any, key: string, iterationNumber: Number) => any,
  ): Promise<void> {
    return this._dbPromise.then((db) => db.iterate(iteratorCallback));
  }
}

function getDefaultConfig() {
  return appConfig.storage;
}

interface StorageConfig {
  name?: string;
  version?: number;
  size?: number;
  storeName?: string;
  description?: string;
  driverOrder: string[];
  dbKey?: string;
}

export default new Storage(getDefaultConfig());
