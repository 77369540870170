import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import RelatedState from './state';

export default class RelatedGetters extends Getters<RelatedState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get domain(): relatedDomain {
    return this.app.state.domain;
  }

  get list(): IRelated[] {
    return this.getters.settings.list;
  }

  get moduleEnabled(): boolean {
    return !!this.app.state.appData?.settings?.modules?.related;
  }

  get settings(): IModuleSettings {
    return this.app.state.appData?.settings?.modules?.related || { list: [] };
  }
}
