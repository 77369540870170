import { Module, createMapper, createComposable } from 'vuex-smart-module';

import MapsGetters from './getters';
import MapsState from './state';

const module = new Module({
  getters: MapsGetters,
  namespaced: true,
  state: MapsState,
});

export default module;

export const mapsStoreMapper = createMapper(module);
export const useMapsStore = createComposable(module);
