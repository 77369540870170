import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IPage } from '../types';

import * as API from '@/modules/pages/dist/api';

import AppStore from '@/store/appstore';
import PagesState from '@/modules/pages/dist/store/state';
import PagesMutations from '@/modules/pages/dist/store/mutations';
import PagesGetters from '@/modules/pages/dist/store/getters';

export default class PagesActions extends Actions<
  PagesState,
  PagesGetters,
  PagesMutations,
  PagesActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  getPages(): Promise<IPage[]> {
    return API.getPages().then((pages) => {
      this.commit('setPages', pages);

      return pages;
    });
  }

  openPage({ id, url }: { id?: number; url?: string }) {
    if (id) {
      const page = this.state.pages.find((page) => page.idContent === id);

      if (page) {
        this.commit('setCurrentPage', page);
      }
    } else if (url) {
    }
  }
}
