import { onDeactivated, onMounted } from 'vue';
import { f7 } from 'framework7-vue';

import bus from 'src/bus';
import i18n from 'src/translate/lang';
import { useObjections } from '@/composition';
import { useAppStore } from '@/store/appstore';
import { useBonusStore } from './store';

export const usePopupOpener = () => {
  const { checkObjection } = useObjections();

  const appStore = useAppStore();
  const bonusStore = useBonusStore();

  onMounted(() => {
    bus.$on('bonus:open-popup', openBonus);
  });

  onDeactivated(() => {
    bus.$off('bonus:open-popup', openBonus);
  });

  function openBonus({ customTitle }: { customTitle?: string } = {}) {
    f7.panel.close();

    bonusStore.commit('setCustomTitle', customTitle || '');

    if (appStore.getters.appSettings.needRegister) {
      if (!appStore.getters.user?.idUser) {
        bus.$emit('needRegister', {
          text: i18n.t(`modules.bonus.needRegister.text`),
          title: i18n.t(`modules.bonus.title`),
        });
      } else {
        checkObjection('bonus:objections:open-popup').then((result) => {
          if (!result) {
            f7.popup.open('.bonus-popup');
          }
        });
      }
    } else {
      f7.popup.open('.bonus-popup');
    }
  }

  return {
    openBonus,
  };
};
