import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { faTimes, faGifts as faGiftsLight } from '@fortawesome/pro-light-svg-icons';
import { faGifts } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './gifts.conf.json';

import store from 'src/store';
import GiftsStore from './store';

import Gifts from './components/gifts.vue';
import GiftsHomeSlot from './components/gifts-home-slot.vue';
import GiftPush from './components/gift-push.vue';
import GiftPopup from './components/gift-popup.vue';
import CouponPopup from './components/coupon-popup.vue';
import AuthWelcomeSlot from "./components/auth-welcome-slot.vue";

library.add(faTimes, faGifts, faGiftsLight);

export function init() {
  Vue.component('gifts-home-slot', GiftsHomeSlot);
  Vue.component('gift-push', GiftPush);
  Vue.component('gift-uni-popup', GiftPopup);
  Vue.component('coupon-uni-popup', CouponPopup);
  Vue.component('auth-welcome-slot', AuthWelcomeSlot);

  const routes = [
    {
      component: Gifts,
      path: config.url,
    },
  ];

  registerModule(store, ['gifts'], 'gifts/', GiftsStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
