import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { faWallet } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './getwallet.conf.json';

import store from 'src/store';
import GetWalletStore from './store';

import GetWalletHomeSlot from './components/getwallet-home-slot.vue';
import GetWalletMenuItem from './components/getwallet-menu-item.vue';

library.add(faWallet);

export function init() {
  Vue.component('getwallet-home-slot', GetWalletHomeSlot);
  Vue.component('getwallet-menu-item', GetWalletMenuItem);

  registerModule(store, ['getwallet'], 'getwallet/', GetWalletStore);

  Misc.registerModuleConfig(config as IModule);
}
