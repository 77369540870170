import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IActivateCodeRequest } from '../types';

import * as API from '../api';
import AppStore from '@/store/appstore';

import { RefState } from './state';
import { RefGetters } from './getters';
import { RefMutations } from './mutations';

export class RefActions extends Actions<RefState, RefGetters, RefMutations, RefActions> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  async activateCode(data: IActivateCodeRequest) {
    return API.activateCode(data);
  }
}
