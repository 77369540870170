















































import Vue from 'vue';

import { OSNotification } from 'onesignal-cordova-plugin';
import { BonusMessage } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { formatNumber } from '@/utils/formatNumber';
import bus from '@/bus';

import BonusIcon from '@/components/bonus-icon.vue';

export default Vue.extend({
  name: 'BonusMessage',
  components: { BonusIcon },
  data: () => ({
    messages: [] as BonusMessage[],
    message: null as Nullable<BonusMessage>,
    visible: false,
    touchStart: null as Nullable<number>,
    touchDiffX: 0,
  }),
  mounted() {
    bus.$on('bonus-push', this.onNewMessage);

    bus.$on('hideMessage', () => {
      this.nextMessage();
    });
  },
  computed: {
    messageAmountFormatted(): string {
      const amount =
        typeof this.message?.bonusAmount === 'string'
          ? parseFloat(this.message?.bonusAmount)
          : this.message?.bonusAmount;

      if (!amount) {
        return '0';
      }

      return formatNumber(
        amount,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    ...appStoreMapper.mapGetters(['decimalOptions']),
  },
  methods: {
    onNewMessage(notification: OSNotification) {
      const message = notification.additionalData as Nullable<BonusMessage>;

      if (message) {
        this.messages.push(message);
      }
    },
    nextMessage() {
      const container = this.$refs.container as HTMLElement;

      if (container && this.messages.length > 1) {
        (this.$refs.container as HTMLElement).classList.add('quick');
      }

      this.visible = false;

      bus.$emit('messageClosed');
    },
    skipAllMessages() {
      this.visible = false;

      this.message = null;
      this.messages = [];

      bus.$emit('messageClosed');
    },
    afterLeave() {
      this.message = null;
      this.messages.shift();
    },
    startHandler(e: TouchEvent) {
      const startX = e.touches?.[0]?.clientX;

      if (startX != null) {
        this.touchStart = e.touches[0].clientX;
      }
    },
    endHandler() {
      const container = this.$refs.container as HTMLElement;

      if (this.touchStart && this.touchDiffX) {
        this.touchStart = null;

        if (Math.abs(this.touchDiffX) > 75) {
          container.classList.add('quick');

          this.$nextTick(() => {
            this.nextMessage();
          });
        } else {
          container.classList.add('animated');

          this.$nextTick(() => {
            container.style.transform = `translateX(0)`;
            container.style.opacity = `1`;

            setTimeout(() => {
              container.classList.remove('animated');
            }, 500);
          });
        }

        this.touchStart = null;
        this.touchDiffX = 0;
      }
    },
    movingHandler(e: TouchEvent) {
      if (this.touchStart != null) {
        this.touchDiffX = e.touches[0].clientX - this.touchStart;

        const opacity = (75 - Math.abs(this.touchDiffX)) / 75;

        (
          this.$refs.container as HTMLElement
        ).style.transform = `translateX(${this.touchDiffX}px)`;
        (this.$refs.container as HTMLElement).style.opacity = opacity.toFixed(2);
      }
    },
  },
  watch: {
    messages() {
      const messageToShow = this.messages[0];

      if (messageToShow) {
        this.visible = true;
        this.message = messageToShow;

        if (messageToShow.bonusNeedFeedback) {
          bus.$emit('feedback:sheet:show', messageToShow.idConnectedEntity);
        }
      }
    },
  },
});
