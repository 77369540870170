export enum MessageType {
  bonus = 'bonus',
  gift = 'gift',
  register = 'register',
}

export enum SocialNetwork {
  tg,
  insta,
  vk,
  fb,
  ok,
  tt,
}

export enum MultipleSelectType {
  radio = 'radio',
  checkbox = 'checkbox',
}

export enum AuthType {
  base = 'base',
  sms = 'sms',
  call = 'call',
}
