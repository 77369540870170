


























































































import Vue from 'vue';

import { IBranch } from '@/types';
import { ICatalogDeliveryAddressCheck, ReceiveType } from '../types';

import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '../store';
import * as API from '@/api';
import { buildGeocoderValue } from '@/utils';

import DeliveryMapSheetAddress from './DeliveryMapSheetAddress.vue';
import DeliveryMapSheetBranches from './DeliveryMapSheetBranches.vue';
import DeliveryMapSheetSelected from './DeliveryMapSheetSelected.vue';
import MapBlock from 'src/components/MapBlock.vue';

export default Vue.extend({
  name: 'CatalogDeliveryMap',
  components: {
    DeliveryMapSheetSelected,
    DeliveryMapSheetBranches,
    DeliveryMapSheetAddress,
    MapBlock,
  },
  data: () => ({
    popupShownOnce: false,
    stepOpened: false,
    map: null as Nullable<any>,
    markersList: [] as Array<[any, IMapPoint]>,
    showPopup: false,
    ReceiveType,
    address: '',
    mapCenter: {
      lat: 0,
      lng: 0,
    } as IMapPosition,
    zoomToCurrentPosition: false,
    waitingForCoordinates: false,
    addressFormOpened: false,
    addressSheetHeight: '200px',
  }),
  created() {
    bus.$on('catalog:delivery:map:sheets-initialize', this.initSheets);
    bus.$on('catalog:delivery:map:open', this.openDeliveryMap);
    bus.$on('catalog:delivery:map:close', this.closeDeliveryMap);

    bus.$on('catalog:delivery:address:step-open', () => {
      setTimeout(() => {
        const sheet = document.querySelector('.catalog-delivery-address');
        if (sheet) {
          this.addressSheetHeight = getComputedStyle(sheet).height;
        } else {
          this.addressSheetHeight = '200px';
        }

        this.addressFormOpened = true;
      }, 300);
    });

    bus.$on('catalog:delivery:address:sheet-close', () => {
      this.addressFormOpened = false;
    });

    bus.$on('catalog:delivery:address:step-close', () => {
      this.addressFormOpened = false;
    });
  },
  mounted() {
    this.mapCenter = {
      lat: parseFloat(this.mapsSettings?.center?.lat),
      lng: parseFloat(this.mapsSettings?.center?.lng),
    };

    bus.$on('delivery:open', () => {
      this.showPopup = true;
      this.popupShownOnce = true;
      this.openDeliveryMap();
    });
  },
  computed: {
    mapPoints(): IMapPoint[] {
      if (this.receive === ReceiveType.delivery) {
        return [];
      } else {
        return this.pickupPoints.map((branch) => ({
          id: branch.idBranch as unknown as number,
          position: { lat: parseFloat(branch.lat), lng: parseFloat(branch.lng) },
        }));
      }
    },
    selectedMapPoint(): Nullable<IMapPoint> {
      if (this.zoomToCurrentPosition) {
        return {
          id: 0,
          position: {
            lat: this.currentCoords.lat,
            lng: this.currentCoords.lng,
          },
        };
      } else if (this.receive === ReceiveType.pickup && this.pickupPoint) {
        return {
          id: this.pickupPoint.idBranch,
          position: {
            lat: parseFloat(this.pickupPoint.lat),
            lng: parseFloat(this.pickupPoint.lng),
          },
        };
      }

      return null;
    },
    mapBlockClassList(): any {
      const $style = (this as any).$style;

      return [
        $style.map,
        {
          [$style.noSubnavbar]: this.singleReceiveTypeAvailable,
          [$style.delivery]: this.receive === ReceiveType.delivery,
          [$style.pickup]: this.receive === ReceiveType.pickup,
          [$style.addressEmpty]: this.deliveryAddress == null,
        },
      ];
    },
    mapsSettings(): Nullable<any> {
      let settings: any = {};

      try {
        settings = JSON.parse(JSON.stringify(this.modulesSettings.maps));
      } catch {
        settings = {
          zoom: 12,
        };
      }

      if (this.receive === ReceiveType.delivery && this.deliveryAddress) {
        settings.zoom = 16;
      }

      if (!settings.zoom) {
        settings.zoom = 12;
      }

      return settings;
    },
    pointsNumber(): any {
      const $style = (this as any).$style;

      return {
        [$style.more]: this.mapPoints.length > 3,
        [$style.three]: this.mapPoints.length === 3,
        [$style.two]: this.mapPoints.length === 2,
        [$style.one]: this.mapPoints.length === 1,
        [$style.zero]: this.mapPoints.length === 0,
      };
    },
    showCenterMarker(): boolean {
      return this.receive === ReceiveType.delivery;
    },
    mapBlockStyle(): { [key: string]: string } {
      if (this.addressFormOpened) {
        return {
          height: `calc(100% - var(--f7-navbar-height) - var(--f7-subnavbar-height) - var(--f7-safe-area-top) - ${this.addressSheetHeight})`,
        };
      }

      return {};
    },
    ...appStoreMapper.mapGetters(['pickupPoints', 'modulesSettings']),
    ...appStoreMapper.mapState(['appData', 'currentCoords']),
    ...catalogStoreMapper.mapGetters([
      'settings',
      'singleReceiveTypeAvailable',
      'receiveAvailable',
    ]),
    ...catalogStoreMapper.mapState(['receive', 'pickupPoint', 'deliveryAddress']),
  },
  methods: {
    openDeliveryMap() {
      this.$nextTick(() => {
        this.$f7.popup.open('.catalog-delivery-popup');
      });
    },
    closeDeliveryMap() {
      this.$f7.popup.close('.catalog-delivery-popup');
    },
    onPopupCloseClick() {
      if (this.stepOpened && this.receive === ReceiveType.delivery) {
        this.stepOpened = false;
        bus.$emit('catalog:delivery:address:step-close');

        this.initSheets();

        bus.$emit('catalog:delivery:map:set-center', {
          lat: this.mapCenter.lat,
          lng: this.mapCenter.lng,
          zoom: 16,
        });
      } else {
        this.$f7.popup.close();
      }
    },
    onMarkerClick(point: IMapPoint) {
      const branch = this.pickupPoints.find(
        (b) => (b.idBranch as unknown as number) === point.id,
      );
      this.setPickupPoint(branch);
    },
    onCloseClick() {
      bus.$emit('catalog:delivery:branches:step-close');
      this.stepOpened = false;
    },
    onPickupClick() {
      this.setReceive(ReceiveType.pickup);
    },
    onDeliveryClick() {
      this.setReceive(ReceiveType.delivery);
    },
    onMapInit(map: any) {
      this.map = map;

      setTimeout(() => {
        this.initSheets();

        this.$watch(
          () => this.pickupPoint,
          (current, prev) => {
            if (this.receive === ReceiveType.pickup) {
              if (current?.idBranch !== prev?.idBranch) {
                this.initSheets();

                if (current) {
                  this.mapCenter = {
                    lat: parseFloat(current.lat),
                    lng: parseFloat(current.lng),
                  };
                }
              }
            }
          },
          {
            immediate: true,
          },
        );

        this.$watch(
          () => this.deliveryAddress,
          (current, prev) => {
            if (this.receive === ReceiveType.delivery) {
              if (current?.id !== prev?.id) {
                this.initSheets();

                if (current) {
                  this.mapCenter = {
                    lat: parseFloat(current.data.lat),
                    lng: parseFloat(current.data.lng),
                  };
                }
              }
            }
          },
          {
            immediate: true,
          },
        );
      }, 100);
    },
    onMarkerList(list: any[]) {
      this.markersList = list;
    },
    initSheets() {
      if (!this.showPopup) {
        return false;
      }

      if (this.receive === ReceiveType.delivery) {
        bus.$emit('catalog:delivery:branches:sheet-close');

        if (this.deliveryAddress) {
          bus.$emit('catalog:delivery:selected:sheet-open');
          bus.$emit('catalog:delivery:address:sheet-close');
        } else {
          bus.$emit('catalog:delivery:address:sheet-open');
          bus.$emit('catalog:delivery:selected:sheet-close');
        }
      } else {
        bus.$emit('catalog:delivery:address:sheet-close');

        if (this.pickupPoint) {
          bus.$emit('catalog:delivery:branches:sheet-close');
          bus.$emit('catalog:delivery:selected:sheet-open');

          const marker = this.markersList.find(
            (entry) => entry[1].id === (this.pickupPoint!.idBranch as unknown as number),
          );
          if (marker) {
            this.map.panTo(marker[0].getPosition());
            this.map.panBy(
              0,
              Math.floor(
                ((this.$refs.selected as unknown as Vue).$el as HTMLElement)
                  .offsetHeight / 2,
              ),
            );
          }
        } else {
          bus.$emit('catalog:delivery:selected:sheet-close');
          bus.$emit('catalog:delivery:branches:sheet-open');
        }
      }

      bus.$emit('catalog:delivery:map:sheets-initialized');
    },
    onPopupClose() {
      this.showPopup = false;

      bus.$emit('catalog:delivery:selected:sheet-close');
      bus.$emit('catalog:delivery:branches:sheet-close');
      bus.$emit('catalog:delivery:address:sheet-close');

      bus.$emit('delivery:done');
    },
    getCoords({ silent }: { silent?: boolean } = {}) {
      this.waitingForCoordinates = true;

      this.getCurrentCoordinates({ silent })
        .then((coords) => {
          const _coords = coords as Nullable<Coords>;

          if (_coords?.lat || _coords?.lng) {
            this.zoomToCurrentPosition = true;

            this.mapCenter = {
              lat: parseFloat(_coords.lat as unknown as string),
              lng: parseFloat(_coords.lng as unknown as string),
            };

            if (this.showCenterMarker) {
              this.onCenterCoords({
                lat: _coords.lat,
                lng: _coords.lng,
              });
            }
          } else {
            this.$f7.dialog.alert(
              this.$t('global.location.error.text').toString(),
              this.$t('global.location.error.title').toString(),
            );
          }
        })
        .finally(() => {
          this.waitingForCoordinates = false;
        });
    },
    onBranchClick(branch: IBranch) {
      this.zoomToCurrentPosition = false;

      this.mapCenter = {
        lat: parseFloat(branch.lat),
        lng: parseFloat(branch.lng),
      };
    },
    onCenterCoords(data: IMapPosition) {
      API.getGeocoderAddress({
        lat: data.lat.toString(),
        lng: data.lng.toString(),
        settings: this.settings.geocoder,
      }).then(({ suggest, buildFormat }) => {
        bus.$emit('catalog:delivery:address:check', {
          coords: {
            lat: data.lat.toString(),
            lng: data.lng.toString(),
          },
          suggest: this.transformGeocoderSuggestsValues(suggest, buildFormat),
        } as ICatalogDeliveryAddressCheck);
      });
    },
    transformGeocoderSuggestsValues(
      suggest: Suggest[],
      buildFormat: GeocoderBuildFormat,
    ): Suggest[] {
      const transformedSuggests: Suggest[] = [];
      suggest.forEach((suggest) => {
        const templates: Nullable<GeocoderBuildTemplates> =
          this.settings.geocoderAddressTemplates || suggest.templates;
        transformedSuggests.push({
          ...suggest,
          ...buildGeocoderValue(suggest, {
            format: buildFormat,
            templates,
          }),
        });
      });
      return transformedSuggests;
    },
    ...appStoreMapper.mapActions(['getCurrentCoordinates']),
    ...catalogStoreMapper.mapActions(['setPickupPoint']),
    ...catalogStoreMapper.mapMutations(['setReceive']),
  },
  watch: {
    showPopup() {
      this.$nextTick(() => {
        if (this.showPopup) {
          this.$f7.popup.open('.catalog-delivery-popup');
        } else {
          this.$f7.popup.close('.catalog-delivery-popup');
        }
      });
    },
    receive() {
      if (this.showPopup) {
        this.initSheets();
        this.stepOpened = false;

        this.zoomToCurrentPosition = false;

        if (this.receive === ReceiveType.pickup) {
          if (this.pickupPoint) {
            this.mapCenter = {
              lat: parseFloat(this.pickupPoint.lat),
              lng: parseFloat(this.pickupPoint.lng),
            };
          } else {
            this.mapCenter = {
              lat: parseFloat(this.mapsSettings?.center.lat),
              lng: parseFloat(this.mapsSettings?.center.lng),
            };
          }
        } else if (this.receive === ReceiveType.delivery && this.deliveryAddress) {
          this.mapCenter = {
            lat: parseFloat(this.deliveryAddress.data.lat),
            lng: parseFloat(this.deliveryAddress.data.lng),
          };
        }
      }
    },
    appData() {
      if (this.receive === ReceiveType.pickup) {
        if (this.pickupPoint) {
          this.mapCenter = {
            lat: parseFloat(this.pickupPoint.lat),
            lng: parseFloat(this.pickupPoint.lng),
          };
        } else {
          this.mapCenter = {
            lat: parseFloat(this.mapsSettings?.center.lat),
            lng: parseFloat(this.mapsSettings?.center.lng),
          };
        }
      } else if (this.receive === ReceiveType.delivery) {
        if (this.deliveryAddress) {
          this.mapCenter = {
            lat: parseFloat(this.deliveryAddress.data.lat),
            lng: parseFloat(this.deliveryAddress.data.lng),
          };
        } else {
          this.mapCenter = {
            lat: parseFloat(this.mapsSettings?.center.lat),
            lng: parseFloat(this.mapsSettings?.center.lng),
          };
        }
      }
    },
  },
});
