import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import { ISendFeedbackRequest, ISendFeedbackResponse } from '../types';

import bus from '@/bus';
import * as API from '../api';

import AppStore from '@/store/appstore';
import FeedbackMutations from './mutations';
import FeedbackState from './state';
import FeedbackGetters from './getters';

let getFeedbackPromise: any = null;

export default class FeedbackActions extends Actions<
  FeedbackState,
  FeedbackGetters,
  FeedbackMutations,
  FeedbackActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  getFeedback() {
    if (getFeedbackPromise) {
      return getFeedbackPromise;
    }

    return (getFeedbackPromise = API.getFeedback()
      .then((list) => {
        this.commit('setFeedback', list);
      })
      .finally(() => {
        getFeedbackPromise = null;
      }));
  }

  sendFeedback(data: ISendFeedbackRequest): Promise<ISendFeedbackResponse> {
    return API.sendFeedback(data).then(async (response) => {
      await this.app.dispatch('getAppData', bus);

      return response;
    });
  }
}
