import { createI18nMessage } from '@vuelidate/validators';

import i18n from '@/translate/lang';

const validator = {
  $validator: (value: string) => {
    if (!value) return true;

    if (value.startsWith('+7')) {
      return /\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}/.test(value);
    } else if (value.startsWith('+375')) {
      return /\+\d{3} \d{2} \d{3}-\d{2}-\d{2}/.test(value);
    }

    return value.replace(/\D/g, '').length >= 11;
  },
};

function translationFunction(validator: string) {
  return i18n.t('global.validators.phone.error').toString();
}

const withI18nMessage = createI18nMessage({ t: translationFunction });

export default withI18nMessage(validator);
