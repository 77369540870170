import User from 'onesignal-cordova-plugin/dist/UserNamespace';

import { Tags } from '../types/onesignal';

export function setTag(key: string, value: string, OSUser: User): Promise<Tags> {
  return new Promise((resolve) => {
    if (window.devMode) {
      console.log('setTag', { [key]: value });
    }

    OSUser.addTag(key, value);

    resolve({ [key]: value });
  });
}

export function setTags(tags: Tags, OSUser: User): Promise<Tags> {
  return new Promise((resolve) => {
    if (window.devMode) {
      console.log('setTags', tags);
    }

    OSUser.addTags(tags);

    resolve(tags);
  });
}

export async function getTags(OSUser: User): Promise<Tags> {
  return await OSUser.getTags();
}

export async function removeTags(tags: string[], OSUser: User): Promise<void> {
  OSUser.removeTags(tags);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 500);
  });
}

export function addAlias(label: string, id: string, OSUser: User): void {
  OSUser.addAlias(label, id);
}

export function removeAlias(label: string, OSUser: User): void {
  OSUser.removeAlias(label);
}
