
























import { defineComponent, onMounted, ref } from 'vue';

import config from '@/app.config.js';
import { useAppStore } from '@/store/appstore';

import SettingsSlot from './SettingsSlot.vue';

export default defineComponent({
  name: 'Settings',
  components: { SettingsSlot },
  setup() {
    const languages = ref({
      ru: 'Русский',
      en: 'English',
    });
    const currentLang = ref('');

    const store = useAppStore();

    onMounted(() => {
      currentLang.value = store.state.locale;
    });

    function onSelect(event: InputEvent) {
      store.dispatch('changeLocale', (event?.target as HTMLInputElement)?.value);
    }

    return {
      languages,
      currentLang,
      config,
      onSelect,
    };
  },
});
