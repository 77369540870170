
import { defineComponent } from 'vue';
import { CreateElement } from 'vue';
import { f7, theme } from 'framework7-vue';

import config from '../getwallet.conf.json';
import { getWalletStoreMapper } from '../store';
import { openUrl } from '@/misc';
import i18n from '@/translate/lang';

export default defineComponent({
  name: 'GetWalletMenuItem',
  render(this: any, h: CreateElement) {
    return h(
      'f7-list-item',
      {
        slot: 'list',
        props: {
          title: this.settings.title || i18n.t(`modules.${config.name}.title`),
        },
        on: {
          click: (e: Event) => {
            f7.panel.close();

            let url = theme.ios ? this.settings.ios : this.settings.android;

            if (!url) {
              url = this.settings.url;
            }

            openUrl(url, '_system', e);
          },
        },
      },
      [
        h('f7-icon', {
          props: {
            icon: 'far fa-wallet',
          },
          slot: 'media',
        }),
      ],
    );
  },
  computed: {
    ...getWalletStoreMapper.mapGetters(['settings']),
  },
});
