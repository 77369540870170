import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import { ISendBookingRequest, SendBookingResponse } from '../types';

import * as API from '../api';

import AppStore from '@/store/appstore';
import BookingState from './state';
import BookingGetters from './getters';
import BookingMutations from './mutations';

export default class BookingActions extends Actions<
  BookingState,
  BookingGetters,
  BookingMutations,
  BookingActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  sendBooking(data: ISendBookingRequest): Promise<SendBookingResponse> {
    return API.sendBooking(data);
  }
}
