import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import * as Misc from 'src/misc';
import config from './pages.conf.json';

import store from 'src/store';
import PagesStore from './store';

import Page from './components/page.vue';
import PagesMenuItems from './components/pages-menu-items.vue';

export function init() {
  Vue.component('page-body', Page);
  Vue.component('pages-menu-items', PagesMenuItems);

  registerModule(store, ['pages'], 'pages/', PagesStore);

  Misc.registerModuleConfig(config as IModule);
}
