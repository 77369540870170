







































import Vue, { PropType } from 'vue';

import { IBranch } from '@/types';
import { openUrl } from '@/misc';
import { getFormattedPhone } from '@/utils';

import ContentBlock from '@/components/content.vue';

export default Vue.extend({
  name: 'MapsSheetBranchInfo',
  components: { ContentBlock },
  props: {
    branch: Object as PropType<IBranch>,
    branchOpened: Boolean as PropType<boolean>,
  },
  methods: {
    onSheetClose() {
      this.$emit('update:branch-opened', false);
    },
    onBranchCloseClick() {
      (this.$refs.branch as any)?.f7Sheet.close();
    },
    call(link: string, e: Event) {
      openUrl(link, '_system', e);
    },
  },
  computed: {
    contactPhoneFormatted(): string {
      return getFormattedPhone(this.branch.contactPhone);
    },
  },
  watch: {
    branchOpened() {
      if (this.branchOpened) {
        (this.$refs.branch as any)?.f7Sheet.open();
      } else {
        (this.$refs.branch as any)?.f7Sheet.close();
      }
    },
  },
});
