








import uuidv4 from 'uuid/v4';
import { f7ready, f7 } from 'framework7-vue';
import { onMounted, defineComponent } from 'vue';

import { appStoreMapper } from 'src/store/appstore';
import { useAuthStore, authStoreMapper } from '../store';
import i18n from '@/translate/lang';
import bus from 'src/bus';
import config from 'src/app.config.js';
import { showErrorDialog } from '@/misc';
import { useObjections } from '@/composition';

import Auth from './auth.vue';

export default defineComponent({
  components: {
    Auth,
  },
  data: () => ({
    config,
    authPopupShow: false,
  }),
  setup() {
    const { addObjection } = useObjections();

    const authStore = useAuthStore();

    onMounted(() => {
      addObjection('bonus:objections:open-popup', () => {
        const objectionResult =
          authStore.getters.settings.emailRequired && !authStore.getters.user?.email;
        bus.$emit('objection', objectionResult);
        if (objectionResult) {
          f7.dialog.confirm(
            i18n.t('modules.auth.needEmail.text').toString(),
            i18n.t('modules.auth.needEmail.title').toString(),
            () => {
              f7.views.current.router.navigate('/profile/edit', {
                props: {
                  callback: () => {
                    bus.$emit('bonus:open-popup');
                  },
                },
              });
            },
          );
        }
      });
    });
  },
  created() {
    this.init();
  },
  mounted() {
    bus.$on('needRegister', this.needRegister);
    bus.$on('needRegister:popup', this.needRegisterPopup);
    bus.$on('auth:init', this.regDevice);

    window.addEventListener('online', this.onDeviceOnline);
  },
  destroyed() {
    window.removeEventListener('online', this.onDeviceOnline);
  },
  computed: {
    ...appStoreMapper.mapGetters(['devMode', 'user', 'isPWA']),
    ...appStoreMapper.mapState([
      'appData',
      'authToken',
      'idDevice',
      'regDeviceDidFail',
      'uuid',
    ]),
    ...authStoreMapper.mapState(['registeringDevice']),
    ...authStoreMapper.mapGetters(['settings']),
  },
  methods: {
    async init() {
      if (config.app.miniapp) {
        this.regDevice();
      } else {
        document.addEventListener('deviceready', this.regDevice);
      }
    },
    needRegister(
      {
        text = i18n.t(`modules.auth.needRegister.text`).toString(),
        title = i18n.t(`modules.auth.needRegister.title`).toString(),
        ok = i18n.t(`modules.auth.needRegister.ok`).toString(),
        cancel = i18n.t(`modules.auth.needRegister.cancel`).toString(),
        showDialog = true,
        callback,
      }: {
        text?: string;
        title?: string;
        ok?: string;
        cancel?: string;
        showDialog?: boolean;
        callback?: () => void;
      } = {},
      popup?: boolean,
    ) {
      if (callback) {
        this.setAfterLoginCallback(callback);
        this.setAfterRegisterCallback(callback);
      }

      if (!showDialog) {
        if (popup) {
          this.authPopupShow = true;

          this.$nextTick(() => {
            f7.popup.open('.auth-popup');
          });
        } else {
          f7.views.current.router.navigate('/auth/');
        }

        return;
      }

      const dialog = f7.dialog.confirm(
        text,
        title,
        () => {
          if (popup) {
            this.authPopupShow = true;

            this.$nextTick(() => {
              f7.popup.open('.auth-popup');
            });
          } else {
            f7.views.current.router.navigate('/auth/');
          }
        },
        () => {
          this.setAfterLoginCallback(null);
          this.setAfterRegisterCallback(null);
        },
      );

      const okButton = dialog?.$el[0].querySelector(
        '.dialog-button:last-child',
      ) as HTMLInputElement;
      const cancelButton = dialog?.$el[0].querySelector(
        '.dialog-button:first-child',
      ) as HTMLInputElement;

      if (okButton) {
        okButton.innerHTML = ok as string;
      }

      if (cancelButton) {
        cancelButton.innerHTML = cancel as string;
      }

      dialog && dialog.open();
    },
    needRegisterPopup(params: any) {
      this.needRegister(params, true);
    },
    onPopupCloseClick() {
      f7.popup.close('.auth-popup');

      setTimeout(() => {
        this.authPopupShow = false;
      }, 500);
    },
    onPopupClosed() {
      this.authPopupShow = false;

      this.setAfterLoginCallback(null);
      this.setAfterRegisterCallback(null);
    },
    onDeviceOnline() {
      if (!this.registeringDevice && !this.idDevice) {
        this.regDevice();
      }
    },
    async regDevice() {
      let afterRegDeviceFailed = this.regDeviceDidFail;

      this.setRegisteringDevice(true);

      if (afterRegDeviceFailed) {
        f7ready((f7) => {
          f7.preloader.show();
        });
      }

      try {
        if (!this.idDevice || !this.authToken) {
          await this.registerDevice({
            key: this.uuid || window?.device?.uuid || uuidv4(),
            os: window?.device?.platform,
            name: window?.device?.manufacturer,
            model: window?.device?.model,
            version: window?.device?.version,
            pwa: this.isPWA,
          });

          bus.$emit('auth:reset-timeout');
        }

        return await this.getAppData(bus);
      } catch (error) {
        if (afterRegDeviceFailed) {
          showErrorDialog.call(this, { error });
        }

        bus.$emit('auth:init-error');
      } finally {
        this.setRegisteringDevice(false);

        if (afterRegDeviceFailed) {
          f7ready((f7) => {
            f7.preloader.hide();
          });
        }
      }
    },
    ...appStoreMapper.mapActions(['getAppData']),
    ...authStoreMapper.mapActions(['registerDevice']),
    ...authStoreMapper.mapMutations([
      'setRegisteringDevice',
      'setAgeCheck',
      'setAfterLoginCallback',
      'setAfterRegisterCallback',
    ]),
  },
  watch: {
    authToken(value: string, previousValue: string) {
      if (!this.idDevice || (!value && !!previousValue)) {
        f7.preloader.show();

        this.regDevice().then(() => {
          bus.$emit('authComplete');

          f7.preloader.hide();
        });
      }
    },
  },
});
